import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, Dimensions, Animated } from 'react-native';
import React from "react"
import { useState, useEffect, useRef } from "react"
import { FontAwesome } from '@expo/vector-icons';
// import { GestureHandler } from 'expo';
// const { Swipeable } = GestureHandler;
  // import { observer } from "mobx-react-lite"


import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from '../../componentsStyledText';
import { Text, View } from '../../componentsThemed';
  // import { useStores } from "../../models";
  import { roundObjStore } from "../models/roundObj/roundObj"

  import {firebase} from '../../components/firebase';
import { json11 } from '../json1'

// const storage = require('@google-cloud/storage');

export async function pickem(userId: string,roundObj: map): Promise<string | null> {

  const db = firebase.firestore();
  // const { userStore } = useStores()

  console.log(roundObj)
  	
  // console.log(await json11())

  const json1 = await json11()

function shuffle(array) {
	  var currentIndex = array.length, temporaryValue, randomIndex;

	  // While there remain elements to shuffle...
	  while (0 !== currentIndex) {

	    // Pick a remaining element...
	    randomIndex = Math.floor(Math.random() * currentIndex);
	    currentIndex -= 1;

	    // And swap it with the current element.
	    temporaryValue = array[currentIndex];
	    array[currentIndex] = array[randomIndex];
	    array[randomIndex] = temporaryValue;
	  }

	  return array;
	}

	// console.log(shuffle(json1))

function weightedWorkout(chances) {
    var sum = chances.reduce((acc, el) => acc + el, 0);
    var acc = 0;
    chances = chances.map(el => (acc = el + acc));
    var rand2 = Math.random() * sum;
    return chances.filter(el => el <= rand2).length;
}


	
function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min);
}


// // exports.getWorkout = functions.https.onCall((roundObj) => {
var pickedObj = {};
var promises = [];

var pickedDoc = db
		.collection("users").doc(userId)
		.collection("picked").doc("pickedObj");



const json1Obj = {}

	json1.forEach((item,index)=>{
		item['index']=index;
		json1Obj[item['x']] = item;
	})

var pickedThings = async function(){
	var picked = {};
	return await pickedDoc.get().then(async function(doc) {
	    // console.log('doc data');
	    console.log(doc.data());
	    picked = doc.data();
	    picked = picked.picked;
	    delete picked["lastUsed"];
	  Object.keys(picked).forEach(function(key) {
	    if(picked[key]["notPicked"]==undefined){
	    	var pickTemp = picked[key];
	    	picked[key] = {};
	    	picked[key]["odds"] = pickTemp;
	    	picked[key]["notPicked"] = 0;
	    }

	    if(picked[key]["notPicked"]>=3&&picked[key]["odds"]<=31||picked[key]["notPicked"]>=10){
	    	picked[key]["odds"] = randomIntFromInterval(250,500);
	    }

	    if(picked[key]["odds"]<=7){
	    	picked[key]["odds"] = randomIntFromInterval(250,500);
	    }
	    
	  });
	  pickedObj = picked;

	  // try{
		//   await pickedDoc.set({
		//     'picked':{'yup':'doin things'}
		//   })
	  // }
	  // catch(err){
	  // 		alert(err)
	  // }
    // console.log(pickedObj);
		return pickedObj;
		}).catch(function(error) {
		    console.error("Error getting document: ", error);
				return "picked error"
		});
}	

	await pickedThings()

var setPickedThings = function(){

  Object.keys(pickedObj).forEach(function(key) {
    if(pickedObj[key]["notPicked"]!=undefined){
    	pickedObj[key]["notPicked"] = pickedObj[key]["notPicked"] + 1;
    }

  })

	pickedObj["lastUsed"] = new Date();


	return pickedDoc.set({
		picked: pickedObj
	}).then(function(pick) {
		  console.log("pickedObj saved");
		  return pick;
		}).catch(function(error) {
		  console.error("Error getting document: ", error);
			
		});
}	




let json = JSON.parse(JSON.stringify(json1));

	var blocked;	

	var blockedDoc = db
		.collection("users").doc(userId)
		.collection("blockedXs").doc("blockedX");


	var blockedThings = async function(){

		return await blockedDoc.get().then(function(doc) {
		    // console.log('doc data');
		    console.log(doc.data());
		    blocked = doc.data();
		    blocked = blocked.blocked;

			blocked.forEach(function(item, index){
				json1Obj[item]['blocked']=true
			})

			for(let x=json.length-1;x>=0;x--){
				if(json1Obj[json[x]['x']]['blocked']){
					delete json1Obj[json[x]['x']]
					json.splice(x,1)
				}
			}

			json.forEach((item,index)=>{
				item['index']=index;
				json1Obj[item['x']] = item;
			})

			// var mainThing = main();
		 //    return(mainThing);
		 console.log("blocked things done")
			return "blocked things"
			
		})
		.catch(function(error) {
		    console.error("Error getting document: ", error);
			// var mainThing1 = main();
		 //    return(mainThing1);	
			return "blocked things error"
		});
			// var mainThing1 = main();
		    // return(mainThing1);	
	}

await blockedThings()
	
	// console.log(json)


const subSwitch =(item)=>{
	switch(item){
		case 'other':return 3;
			break;
		case 'push':return 1;
			break;
		case 'pull':return 2;
			break;
		case 'abs':return 1;
			break;
		case 'twist':return 2;
			break;
		case 'booty':return 1;
			break;
		case 'power':return 2;
			break;
		case 'biceps':return 1;
			break;
		case 'triceps':return 2;
			break;
		case 'forearms':return 3;
			break;
		case 'lunges':return 1;
			break;
		default: return 3;
		
	}
}
const subSwitch1 =(item)=>{
	switch(item){
		case 'lunges':return 1;
			break;
		default: return 2;
		
	}
}

var main = function(){

	const workObj=[]
	// const equipment = ['Body Weight','Trx Straps','Bosu Ball','Sandbag','Pull Up Bar','Medicine Ball','Slammable Floor','Throwable Wall','Resistance Bands Short','Resistance Bands Long']
	const subTargets = ['push','pull','other','other1','other2','other3','abs','twist','booty','power','biceps','triceps','forearms']
	const parts = ['upper','lower','core','arms','booty']

	for(let x=1;x<=roundObj['roundNum'];x++){
		let xNum = roundObj[x]['type']=='Randomness'?(roundObj[x]['roundTime']*60) / (parseInt(roundObj[x]['roundSetTime']) + parseInt(roundObj[x]['roundRestTime'])) : 3;
		
		// console.log(roundObj[x],xNum)
		const jsonn = JSON.parse(JSON.stringify(json));
		const json2Obj = JSON.parse(JSON.stringify(json1Obj));
		const objByTarget={}
		const objByTarget1={}
		const objByTarget2={}

		parts.forEach((item)=>{
			objByTarget[item]={}
			objByTarget[item]['1']=[]
			objByTarget[item]['2']=[]
			objByTarget[item]['3']=[]
			objByTarget1[item]={}
			objByTarget1[item]['1']=[]
			objByTarget1[item]['2']=[]
			objByTarget1[item]['3']=[]
			objByTarget2[item]={}
			objByTarget2[item]['1']=[]
			objByTarget2[item]['2']=[]
			objByTarget2[item]['3']=[]
			
		})
		for(let x1=jsonn.length-1;x1>=0;x1--){
		// if(jsonn[x1]['legs']!=undefined&&jsonn[x1]['equipment']=='Trx Straps'){
		// 	console.log(jsonn[x1])
		// }
			// need to acount for arms and booty
			if(!roundObj[String(x)][jsonn[x1]['equipment']]||(!roundObj[String(x)]['Throwable Wall']&&jsonn[x1]['wall'])||(!roundObj[String(x)]['Slammable Floor']&&jsonn[x1]['floor'])||(!jsonn[x1]['level'].includes(roundObj[String(x)]['level']))){
				delete json2Obj[jsonn[x1]['x']]
				jsonn.splice(x1,1)
			}else if((jsonn[x1]['arms']==undefined&&roundObj[String(x)]['target']=='Arms')){
				delete json2Obj[jsonn[x1]['x']]
				jsonn.splice(x1,1)
			}else if(jsonn[x1]['booty']==undefined&&roundObj[String(x)]['target']=='Booty'){
				delete json2Obj[jsonn[x1]['x']]
				jsonn.splice(x1,1)
			}else if(jsonn[x1]['bodypart']!=roundObj[String(x)]['target']&&roundObj[String(x)]['target']!='Full Body'&&roundObj[String(x)]['target']!='Arms'&&roundObj[String(x)]['target']!='Booty'){
				delete json2Obj[jsonn[x1]['x']]
				jsonn.splice(x1,1)
			}
		
		// if(jsonn[x1]!=undefined&&jsonn[x1]['legs']=='other'){
		// 	console.log(jsonn[x1])
		// }
		}
	// console.log(jsonn)



		jsonn.forEach((item,index)=>{
			switch(roundObj[x]['target']){
				case 'Full Body': item['bodypart']=='Upper Body'? objByTarget['upper'][subSwitch(item['pushPull'])].push(item):item['bodypart']=='Lower Body'?objByTarget['lower'][subSwitch(item['legs'])].push(item):objByTarget['core'][subSwitch(item['abs'])].push(item)
					break;
				case 'Upper Body': objByTarget['upper'][subSwitch(item['pushPull'])].push(item)
					break;
				case 'Lower Body': objByTarget['lower'][subSwitch(item['legs'])].push(item)
					break;
				case 'Core': objByTarget['core'][subSwitch(item['abs'])].push(item)
					break;
				case 'Arms': objByTarget['arms'][subSwitch(item['arms'])].push(item)
					break;
				case 'Booty': objByTarget['booty'][subSwitch1(item['booty'])].push(item)
					break;
			}
		})

		Object.keys(objByTarget).forEach((item,index)=>{
			Object.keys(objByTarget[item]).forEach((item1,index1)=>{
				// console.log(objByTarget[item][item1])
				if(objByTarget[item][item1].length>=1){

					let w=0;

					while(objByTarget2[item][item1].length<(xNum*3)&&w<200){
						// console.log(objByTarget2[item][item1].length,objByTarget2[item][item1])
						objByTarget1[item][item1] = []
						
						let reset=true
						objByTarget[item][item1].forEach((item2,index2)=>{
							if(pickedObj[item2['x']]!=0){
								reset=false
							}
						})
						if(reset){
							// console.log('reset',pickedObj)
							objByTarget[item][item1].forEach((item2,index2)=>{
								const pickedNum = randomIntFromInterval(250,500);
								pickedObj[item2['x']]=pickedNum
							})
							// console.log('reset1',pickedObj)

						}

						objByTarget[item][item1].forEach((item2,index2)=>{
							if(pickedObj[item2['x']]!=undefined){
								for(let x=0;x<pickedObj[item2['x']];x++){
									objByTarget1[item][item1].push(item2)
								}	

							}else{
								const pickedNum = randomIntFromInterval(250,500);
								pickedObj[item2['x']]=pickedNum
								for(let x=0;x<pickedNum;x++){
									objByTarget1[item][item1].push(item2)
								}

							}

						})

						for(let x=0;x<randomIntFromInterval(2,10);x++){
							objByTarget1[item][item1] = shuffle(objByTarget1[item][item1])
						}

						let push = randomIntFromInterval(0,objByTarget1[item][item1].length-1)
						let w1=0;
						if(objByTarget2[item][item1][objByTarget2[item][item1].length-1]!=undefined){

						let leftRight = objByTarget1[item][item1][push]['leftRight']? objByTarget1[item][item1][push]['x1']:''
						// if(objByTarget2[item][item1][objByTarget2[item][item1].length-1]['x']==leftRight){
							// console.log(objByTarget2[item][item1][objByTarget2[item][item1].length-1]['x'], leftRight)
							// console.log(objByTarget1[item][item1][push])
							// console.log(objByTarget2[item][item1][objByTarget2[item][item1].length-1]['x'])
						// }
						while((objByTarget1[item][item1][push]['x']==objByTarget2[item][item1][objByTarget2[item][item1].length-1]['x'])&&w1<200){
							// console.log(objByTarget1[item][item1][push]['x'],leftRight)
							push = randomIntFromInterval(0,objByTarget1[item][item1].length-1)
							leftRight = objByTarget1[item][item1][push]['leftRight']? objByTarget1[item][item1][push]['x1']:''
							w1++;
							// console.log('caught a repeat')
						}
						}

						const newObj = {}
						newObj['x'] = objByTarget1[item][item1][push]['x']
						newObj['gif'] = objByTarget1[item][item1][push]['gif']
						newObj['leftRight']=objByTarget1[item][item1][push]['leftRight'];
						newObj['audio']=objByTarget1[item][item1][push]['audio'];

						objByTarget2[item][item1].push(newObj)

						if(objByTarget1[item][item1][push]['leftRight']){
							const newObj1 = {}
							newObj1['x'] = objByTarget1[item][item1][push]['x1']
							newObj1['gif'] = objByTarget1[item][item1][push]['gif1']
							newObj1['leftRight']=false;
							newObj1['audio']=objByTarget1[item][item1][push]['audio1'];
								objByTarget2[item][item1].push(newObj1)
						}

						if(objByTarget2[item][item1].length<xNum){
							// console.log(objByTarget1[item][item1])
							// console.log('@@@1 : ',objByTarget1[item][item1][push]['x'],pickedObj[objByTarget1[item][item1][push]['x']])
							pickedObj[objByTarget1[item][item1][push]['x']] = 0;
							// pickedObj[objByTarget1[item][item1][push]['x']] = pickedObj[objByTarget1[item][item1][push]['x']]!=undefined?pickedObj[objByTarget1[item][item1][push]['x']]>3?pickedObj[objByTarget1[item][item1][push]['x']]/2:3:250;
							// console.log('@@@2 : ',objByTarget1[item][item1][push]['x'],pickedObj[objByTarget1[item][item1][push]['x']])
						}
						w++;

					}
				}

			})
		})

	// console.log(jsonn)
	// console.log(objByTarget)
	// console.log(objByTarget2)
	workObj.push(objByTarget2)
	}

	return workObj
}



// return Promise.all(promises).then((response1) => {
// 		var mainThing = main();
// 		console.log("Promises done.....on to main");
// 		  return(mainThing);
// 	}).catch((e) => {
// 		var mainThing1 = main();
// 		console.error(e);
// 	    return(mainThing1);
// 	});

return await main()
};

