// import * as fire from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';
// // import 'firebase/messaging';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { Platform } from 'react-native';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {initializeAuth} from 'firebase/auth';
import {getReactNativePersistence} from 'firebase/auth/react-native';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA3nlwfKvY0A4IW_TYIYB4TRVWLH2j09S8",
  authDomain: "randomworkoutz.firebaseapp.com",
  databaseURL: "https://randomworkoutz.firebaseio.com",
  projectId: "randomworkoutz",
  storageBucket: "randomworkoutz.appspot.com",
  messagingSenderId: "284947227744",
  appId: "1:284947227744:web:6656d7f8cfe3fcf62e4f05",
  measurementId: "G-QLCVZV0CZ0"
};

// const Firebase = initializeApp(firebaseConfig);
// let firebase;

// if (fire.apps.length === 0) {
//   firebase = fire.initializeApp(firebaseConfig, {
//   // @ts-ignore
//   useFetchStreams: false,
//   cacheSizeBytes: 100000000,
// });
//   firebase.firestore().settings({experimentalForceLongPolling: true}); // add this

// }

// // if (!firebase.apps.length) {
// //  initializeApp(firebaseConfig);
// // }else {
// //    firebase.app(); // if already initialized, use that one
// // }

if (!firebase.apps.length) {
    const defaultApp = firebase.initializeApp(firebaseConfig)
    if (Platform.OS !== 'web') {
      initializeAuth(defaultApp, {
        persistence: getReactNativePersistence(AsyncStorage),
      });
    }
}
firebase.firestore().settings({experimentalForceLongPolling: true,merge:false});


export {firebase}