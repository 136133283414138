import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Dimensions,Modal,Platform } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { useState, useEffect, useRef,useIsMounted } from "react"
import { FontAwesome,Ionicons } from '@expo/vector-icons';
import { Video, AVPlaybackStatus, ResizeMode } from 'expo-av';
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"
import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models"
import { observer } from "mobx-react-lite"
import {firebase} from '../components/firebase';
import * as FileSystem from 'expo-file-system';


const Videos = ({ timerTiming,ready })=> {

  const [os,setOs] = useState(Platform.OS)
  const downloadsDir = FileSystem.cacheDirectory + 'downloads/';
  const navigation = useNavigation()
  const { roundObjStore, workoutObjStore } = useStores()
  const storage = firebase.storage();
  const storageRef = storage.ref();

  const [status,setStatus] = useState(null)
  const [url,setUrl] = useState(null)
  const [path,setPath] = useState(null)
  const video = useRef(null)
  const [show,setShow] = useState(true)
  const [show1,setShow1] = useState(false)
  const [needLogin,setNeedLogin] = useState(false)

  const [status1,setStatus1] = useState(null)
  const [url1,setUrl1] = useState(null)
  const [path1,setPath1] = useState(null)
  const video1 = useRef(null)
  const vidContainerRef = useRef(null)

  const [whichVid,setWhichVid] = useState(0)
  const [height1,setHeight1] = useState(400)
  
   
  var showHideVid = function(setShow, setShow1){
    setShow(true)
    setShow1(false)
  }

  var getVid = async function(video,status, setUrl, path){
    if(path!='g'){
    
      let fileInfo = {exists:false};
      console.log('platform : ',os)
      if(os!='web'){
        fileInfo = await FileSystem.getInfoAsync(downloadsDir+path);
      }
      console.log(fileInfo)
      
      if(fileInfo.exists && fileInfo.uri!=undefined){
          console.log('downloaded video exists')
          setUrl(fileInfo.uri)
          if(video.current!=null){
            console.log('downloaded video exists')
            video.current.props.source.uri = fileInfo.uri
            setTimeout(function () {      
              video.current.playAsync()
              console.log('downloaded video should be playing')
            }, 150);
          }
      }else{

        var ref = storageRef.child('vids/'+path);
        console.log(path)
        try{

        await ref.getDownloadURL().then(function(url1) {
          setUrl(url1)
          if(video.current!=null){
            video.current.props.source.uri = url1
            setTimeout(function () {      
              video.current.playAsync()
            }, 150);
          }
          console.log(url1)
          // console.log(video.current)

          // Insert url into an <img> tag to "download"
        }).catch(function(error) {
          // $("#details0").html(error)
          console.log(error);
          // alert(error)
            console.log(error)
          if(String(error).includes('permission')){
            console.log('includes')
            navigation.navigate('Login')
          }
          // setNeedLogin(true)

        });
        if(os!='web'){
            await FileSystem.downloadAsync(url1, (downloadsDir+path))
          }
        }
        catch(err){
          console.log(err)
        }
      }
    }
  }

  // useEffect(()=>{
  // },[url])

  const changeVideo = async ()=>{
    console.log(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].gif)
      let target,type1, currXInType;
      if(workoutObjStore[workoutObjStore.currentRound].full){
        target = workoutObjStore[workoutObjStore.currentRound].currentTarget!=2? workoutObjStore[workoutObjStore.currentRound].currentTarget+1 : 0;
        const targetName = workoutObjStore[workoutObjStore.currentRound].targets[target];
        const targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
        const typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length
        currXInType = targetObj[targetObj.type]
        // type1 = targetObj.type+1>3?1:targetObj.type+1;
        type1 = targetObj.type;
      }else {
        target = 0;
        const targetName = workoutObjStore[workoutObjStore.currentRound].targets[target];
        const targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
        const typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length
        
        currXInType = targetObj[targetObj.type]
        type1 = targetName=='booty'? targetObj.type+1>2?1:targetObj.type+1 :targetObj.type+1>3?1:targetObj.type+1;
      }

    if(whichVid==1){
      console.log('whichVid == 1')
      console.log(path1,workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].gif)
      // check if path = gif
      if(path1!=workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].gif){
        await getVid(video1, status1,  setUrl1,workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].gif)
        setWhichVid(whichVid=>whichVid==0?1:0)
        showHideVid(setShow1,setShow)
      } else {
        setWhichVid(whichVid=>whichVid==0?1:0)
        showHideVid(setShow1,setShow)
      }
      // get next vid.....
      // // leftRight
      // if(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].leftRight){
      //   setPath(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][targetObj.type][(targetObj[targetObj.type])+1].gif)
      //   getVid(video, status, setUrl,workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][targetObj.type][(targetObj[targetObj.type])+1].gif)

      // }else 
      if(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType]!=undefined){
        setPath(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType].gif)
        getVid(video, status, setUrl,workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType].gif)
      }
    }else{
      console.log('whichVid == 0')
      // check if path = gif
      console.log(path!=workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].gif)
      if(path!=workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].gif){
        await getVid(video, status, setUrl,workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].gif)
          setWhichVid(whichVid=>whichVid==0?1:0)
          showHideVid(setShow,setShow1)
      }else{
        setWhichVid(whichVid=>whichVid==0?1:0)
        showHideVid(setShow,setShow1)
      }
      // get next vid.....
      if(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType]!=undefined){
        setPath1(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType].gif)
        getVid(video1, status1 ,setUrl1,workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType].gif)
      }
    }         
    }

  useEffect(()=>{
    if(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]]!=undefined){
      setTimeout(function () {      
        changeVideo()
      }, 150);
    }
  },[workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]]])
  
  // useEffect(()=>{
  //   let target,type1, currXInType;
  //     if(workoutObjStore[workoutObjStore.currentRound].full){
  //       target = workoutObjStore[workoutObjStore.currentRound].currentTarget!=2? workoutObjStore[workoutObjStore.currentRound].currentTarget+1 : 0;
  //       const targetName = workoutObjStore[workoutObjStore.currentRound].targets[target];
  //       const targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
  //       const typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length
  //       currXInType = targetObj[targetObj.type]
  //       type1 = targetObj.type;
  //     }else {
  //       target = 0;
  //       const targetName = workoutObjStore[workoutObjStore.currentRound].targets[target];
  //       const targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
  //       const typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length
        
  //       currXInType = targetObj[targetObj.type]
  //       type1 = targetName=='booty'? targetObj.type+1>2?1:targetObj.type+1 :targetObj.type+1>3?1:targetObj.type+1;
  //     }
  //     getVid(video, status setUrl,switchPath(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].x))
  //     setPath1(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType].x)
  //     getVid(video1, setUrl1,switchPath(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType].x))
  //   },[])

  const playVid= async ()=>{
      console.log(status)
      console.log('fuck')
      // console.log(video.current)
      // console.log(video1.current)

      try{


      video.current.playAsync()
      video1.current.playAsync()
      }
      
      catch(err){

        console.log(err)
      }
  }

  // useEffect(()=>{
  //   console.log(status,'status')
    
  // },[status]) 

  useEffect(()=>{
    console.log(ready,'ready')
    if(ready){
      playVid()
    }
  },[timerTiming,ready])


  const {width,height} = Dimensions.get('window');

  const onLayout =(event) => {
    var {x, y, width, height} = event.nativeEvent.layout;
    setHeight1(height-1)
  }
  
  return (
    <View style={styles.container}>
      <View onLayout={onLayout} style={{width:width,maxWidth:1200,flex:1}}>
      <View style={{position:'absolute',zIndex:1,width:width,maxWidth:1200,height:height1,opacity:0}}></View>

      <View style={show ? styles.container1 : styles.container2}>
        <Video
          ref={video}
          style={styles.video}
          videoStyle={{height:'100%',width:'100%'}}
          source={{
            uri: url,
          }}
          useNativeControls={false}
          resizeMode='contain'
          isLooping
          onPlaybackStatusUpdate={status => setStatus(() => status)}
        />            
    </View>
    <View style={show1 ? styles.container1 : styles.container2}>
      <Video
        ref={video1}
        style={styles.video1}
        videoStyle={{height:'100%',width:'100%'}}
        source={{
          uri: url1,
        }}
        useNativeControls={false}
        resizeMode="contain"
        isLooping
        onPlaybackStatusUpdate={status => setStatus(() => status1)}
      />            
    </View>

    
    </View>
    </View>
  );
}

export default observer(Videos)


const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignSelf:'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:palette.black,
    color: palette.white,
    // height: '100%'
    
  },
  container1: {
    paddingHorizontal: 10,
    paddingTop: 20,
    flex:1,
    // alignItems: 'center',
    justifyContent:'center',
    // zIndex: 199,
    // height:'100%',
    // borderWidth:1,
    // borderColor:'white',
    // borderRadius:5,
  },
  video:{
    // flex:1,
    // alignItems: 'center',
    height: '100%',
    // position: 'relative',
    // width: 500,
    // borderWidth:1,
    // borderColor:'white',
    // borderRadius:5,
  },
  container2: {
    paddingHorizontal: 10,
    paddingTop: 20,
    display: 'none',
  },
  video1:{
    // flex:1,
    height: '100%',
    // borderWidth:1,
    // borderColor:'white',
    // borderRadius:5,
  },
  
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  text1: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
    color:'#FFFF33',
  },
  text2: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
    color:'#00FF00',
  },
  text3: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
    color:'#FF2400',
  },
  outside:{
    flex: 1,
    alignSelf:'stretch',
    justifyContent: 'center',
  },
  separator: {
    height: 1,
    marginVertical: 20,
    marginHorizontal: 20,
    color: palette.white,
    borderBottomColor: palette.white,
    borderBottomWidth: 1,
    flex:1,
    // alignSelf:'stretch'
  },
  itemContainer1: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: palette.white,
  },
  itemContainer2: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemContainer3: {
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
  },

});
