import { Instance, SnapshotOut, types } from "mobx-state-tree"
// import { Workout } from "./Workout"
/**
 * Model description here for TypeScript hints.
 */


  // useEffect(()=>{
  //   const obj={}
  //   for(let i=1;i<=20;i++){
  //     obj[i.toString()] = {
  //       'type':'randomness',
  //       'time': {
  //         'repeat': 3,
  //         'WorkoutTime':30,
  //         'WorkoutSetTime':30,
  //         'WorkoutRestTime':15,
  //       },
  //       'level':'Experienced',
  //       'target':'Full Body',
  //       'equipment':{
  //         'Body Weight':true,
  //         'Trx Straps':false,
  //         'Bosu Ball':false,
  //         'Sandbag':false,
  //         'Pull Up Bar':false,
  //         'Medicine Ball':false,
  //         'Slamable Floor':false,
  //         'Throwable Wall':false,
  //         'Resistance Bands Short':false,
  //         'Resistance Bands Long':false,
          
  //       },
  //     }
  //   }
  //   setWorkoutObj(obj)
  // },[])

const Exercise = types.model({
  x:types.optional(types.string, ""),
  gif:types.optional(types.string, ""),
  leftRight:types.optional(types.boolean, false),
  audio:types.optional(types.string, ""),
  
})
const XType = types.model({
  '1':types.optional(types.array(Exercise),[{}]),
  '2':types.optional(types.array(Exercise),[{}]),
  '3':types.optional(types.array(Exercise),[{}]),
}).actions((self) => ({
    setXType: (key,e)=>{
      // console.log(key,e)
      self[key]=e
    },
}))
const XTypeCurrent = types.model({
  '1':types.optional(types.number,0),
  '2':types.optional(types.number,0),
  '3':types.optional(types.number,0),
  'type':types.optional(types.number,1),
}).actions((self) => ({
    setXType: (key,e)=>{
      // console.log(key,e)
      self[key]=e
    },
}))

const Workout = types.model({
  'type':types.optional(types.string,'randomness'),
  'repeat':types.optional( types.integer,3),
  'currentRepeat':types.optional(XTypeCurrent,{'1':0,'2':0,'3':0,'type':1}), 
  'roundTime':types.optional(types.integer,600),
  'currentTime':types.optional(types.integer,600),
  'roundSetTime':types.optional(types.integer,30),
  'currentSetTime':types.optional(types.integer,30),
  'roundRestTime':types.optional(types.integer,15),
  'currentRestTime':types.optional(types.integer,15),
  'targets':types.optional(types.array(types.string),['upper','lower','core']),
  'currentTarget':types.optional(types.number,0),
  'full':types.optional(types.boolean,true),
  'upper':types.optional(XType, {'1':[{x:'Push Ups'}],'2':[{x:'Posterior Chain Resisted Rows'}],'3':[{x:'Downward Dog Push Ups'}]}),
  'upperCurr': types.optional(XTypeCurrent,{'1':0,'2':0,'3':0,'type':1}),
  'lower':types.optional(XType,{'1':[{x:'Hip Circles'}],'2':[{x:'Squats'}],'3':[{x:'Calf Raises'}]}),
  'lowerCurr': types.optional(XTypeCurrent,{'1':0,'2':0,'3':0,'type':1}),
  'core':types.optional(XType,{'1':[{x:'Sit Ups'}],'2':[{x:'Russian Twist'}],'3':[{x:'Side Planks'}]}),
  'coreCurr': types.optional(XTypeCurrent,{'1':0,'2':0,'3':0,'type':1}),
  'arms':types.optional(XType,{'1':[{x:'Skull Crushers'}],'2':[{x:'Push Pulls'}],'3':[{x:'Finger Openers'}]}),
  'armsCurr': types.optional(XTypeCurrent,{'1':0,'2':0,'3':0,'type':1}),
  'booty':types.optional(XType,{'1':[{x:'Lunges'}],'2':[{x:'Hip Circles'}],'3':[{}]}),
  'bootyCurr': types.optional(XTypeCurrent,{'1':0,'2':0,'3':0,'type':1}),
      
}).views((self) => ({
    gettype: ()=>{
      return self.type
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    settype: (e)=>{
      self.type=e
    },
}))


export const WorkoutObjModel = types
  .model("WorkoutObj")
  .props({
    "1": types.optional(Workout,{targets:['lower'],full:false}),
    "2": types.optional(Workout,{targets:['core'],full:false}),
    "3": types.optional(Workout,{targets:['upper'],full:false}),
    "4": types.optional(Workout,{}),
    "5": types.optional(Workout,{}),
    "6": types.optional(Workout,{}),
    "7": types.optional(Workout,{}),
    "8": types.optional(Workout,{}),
    "9": types.optional(Workout,{}),
    "10": types.optional(Workout,{}),
    "11": types.optional(Workout,{}),
    "12": types.optional(Workout,{}),
    "13": types.optional(Workout,{}),
    "14": types.optional(Workout,{}),
    "15": types.optional(Workout,{}),
    "16": types.optional(Workout,{}),
    "17": types.optional(Workout,{}),
    "18": types.optional(Workout,{}),
    "19": types.optional(Workout,{}),
    "20": types.optional(Workout,{}),
    currentRound: types.optional(types.number,1),
  })
  .views((self) => ({
    getCurrentRound: ()=>{
      return self.currentRound
    },
    getWorkoutObj: (num)=>{
      return self[num]
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setCurrentRound: (e)=>{
      self.currentRound=e
    },
    setWorkoutObj: (num,key,e,fnum)=>{
      if(fnum!=undefined){
        // console.log(e,fnum)
        self[num][key].setXType(e,fnum)
      }else{
        self[num][key]=e
      }
    },
}))
type WorkoutType = Instance<typeof WorkoutObjModel>
export interface WorkoutObj extends WorkoutType {}
type WorkoutSnapshotType = SnapshotOut<typeof WorkoutObjModel>
export interface WorkoutSnapshot extends WorkoutSnapshotType {}
export const createWorkoutDefaultModel = () => types.optional(WorkoutObjModel, {})

