import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Dimensions,Modal,TextInput,Button,Image } from 'react-native';
import { RadioButton, mdiCameraFlipOutline,mdiCameraIris,mdiExitToApp } from 'react-native-paper';
import { useState, useEffect, useRef,useIsMounted } from "react"
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { Camera, CameraType } from 'expo-camera';
// sudo rm -rf node_modules/expo-camera/node_modules/react
// import { Image } from 'expo-image';
import { Buffer } from "buffer";

import {firebase} from './firebase';
import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models"
import { observer } from "mobx-react-lite"
// import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"


const DoneModal = ({ done, navigation })=> {

  // const navigation = useNavigation()
  const { roundObjStore, workoutObjStore,userStore } = useStores()
  const db = firebase.firestore();
  const [type, setType] = useState(CameraType.front);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [note, setNote] = useState('')
  const [showCamera, setShowCamera] = useState(false)
  const {width} = Dimensions.get('window');
  const cameraRef = useRef()
  const [picSource, setPicSource] = useState()
  const [picSize, setPicSize] = useState(1080)
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const [modalVisible, setModalVisible] = useState(false);


  const blurhash =
    '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';


  useEffect(() => {
      const unsubscribe = navigation.addListener('focus', () => {
        setShowCamera(false)
      });

      return ()=>{
        setShowCamera(false)
        unsubscribe;
      }
  }, [navigation]);

  useEffect(()=>{
    console.log(picSource)
    console.log("pic")
    // cameraRef.current.pausePreview()
    setShowCamera(false)



  },[picSource])

  const uriToBlob = async(uri) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      // If successful -> return with blob
      xhr.onload = function () {
        resolve(xhr.response);
      };

      // reject on error
      xhr.onerror = function () {
        reject(new Error('uriToBlob failed'));
      };

      // Set the response type to 'blob' - this means the server's response 
      // will be accessed as a binary object
      xhr.responseType = 'blob';

      // Initialize the request. The third argument set to 'true' denotes 
      // that the request is asynchronous
      xhr.open('GET', uri, true);

      // Send the request. The 'null' argument means that no body content is given for the request
      xhr.send(null);
    });
  };


  const ready =async ()=>{
    try{

    const sizes = await cameraRef.current.getAvailablePictureSizesAsync()

    setPicSize(parseInt(sizes.length/2))

    }catch(err){
      console.log('getAvailablePictureSizesAsync err: ',err)
    }
  }
  const exit =async ()=>{
    setShowCamera(false)
  }
 const takePic =async ()=>{
    console.log('takePic cameraRef:  ',cameraRef.current)
    setPicSource(await cameraRef.current.takePictureAsync({'quality':1,'scale':1,'base64':true}))
    // const pic = await cameraRef.current.takePictureAsync({'quality':.2,'scale':.2,'base64':true})

  }

  const Submit =async ()=>{
    setModalVisible(true)
    const d = new Date(),
        month = d.getMonth(),
        year = d.getFullYear(),
        day = d.getDate(),
        time = Date.now()+"",
        monthYear = month+""+year;
        console.log(userStore.getUser())
    const docMonthYear = firebase.firestore().collection("users").doc(userStore.getUser()).collection("workoutz").doc(monthYear);

    const docTime = firebase.firestore().collection("users").doc(userStore.getUser()).collection("workoutz").doc(monthYear);
      // console.log(picSource)
      if(picSource!=undefined&&picSource.base64!=undefined){

    try{
      console.log('saving....')



      // const split = picSource.base64.slice(0,20)
        const imageRef = storageRef.child('users/'+userStore.getUser());
        const imageRef1 = imageRef.child(""+time);
      if(picSource.base64.includes('base64,')){
        console.log('includes base64')
        const split = picSource.base64.split(':')
        console.log(split)
        const split1 = split[1].split(';')
        const split2 = picSource.base64.split('base64,')
        // console.log(split1[0])

        const buffer = Buffer.from(split2[1],'base64');
        const blob = new Blob([buffer], { type: split1[0] })
        // const blob = b64toBlob(split2[1],split1[0]);
        console.log('saving....yup')

        await imageRef1.put(blob)
        console.log('Uploaded a blob or file!');
      }else{
        console.log('doesn\'t include base64',picSource.uri)
      //   const buffer = Buffer.from(picSource.base64,'base64');
      // console.log(buffer)
      //   const blob = new Blob([buffer], { type: 'image/jpg' }) 
      const response = await uriToBlob(picSource.uri);
      // console.log(response)
        // const blob = await response.blob();       

      // const blob = b64toBlob(split2[1],split1[0]);
      console.log('saving....yup1')

      await imageRef1.put(response)
      // await imageRef1.put(picSource.uri)
      console.log('Uploaded a blob or file!');
      

      }
    
    }
    catch(error) {
      setModalVisible(false)
      console.error("Error writing document: ", error);
      alert("error uploading image");
      return
    }
      }

    // try{
    //   console.log('saving1....')
    //  await docMonthYear.set({
    //     dayArray: firebase.firestore.FieldValue.arrayUnion(day)
    //     // picture location path if picture taken
    //   }, {merge: true})
    //   console.log("Document successfully written!");
    // }
    // catch(error) {
    //     setModalVisible(false)
    //     alert("error saving");
    //     // $("#saveWorkoutModal").modal('show');
    //     console.error("Error writing document: ", error);
    // };

    try{
      console.log('saving2....')
      if(picSource!=undefined&&picSource.base64!=undefined){
        const obj = {}
        obj[time]={day:day,note:note,picture:time}
        await docTime.set(obj, {merge: true})

      }else{
        const obj = {}
        obj[time]={day:day,note:note,picture:''}
        await docTime.set(obj, {merge: true})
      }
      console.log("Document successfully written!");
      navigation.navigate('Profile')
    }
    catch(error) {
        setModalVisible(false)
        alert("error saving");
        // $("#saveWorkoutModal").modal('show');
        console.error("Error writing document: ", error);
    };
        setModalVisible(false)

    

    
  }

  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const CameraButt =async ()=>{
    if(!permission.granted){
      await requestPermission()
    }
    console.log(permission)
    setShowCamera(true)
  }

   function toggleCameraType() {
    setType(current => (current === CameraType.back ? CameraType.front : CameraType.back));
  }

  useEffect(()=>{
    console.log('cameraRef',cameraRef.current)
  },[cameraRef.current])
  

// add a "public" check box

  return (
    <View style={styles.container}>
       
          {
            !showCamera?
      <View style={styles.container1}>
        <Text style={{paddingRight:10,paddingBottom:10}}>Create a note:</Text>
        <TextInput
          value={note}
          onChangeText={(e) => setNote(e)}
          placeholder={''}
          style={styles.text}
        />
          <View style={{borderRadius:10,padding:20}}>
        
          <TouchableOpacity
            style={styles.loginScreenButton}
            onPress={CameraButt}
            underlayColor='#fff'>
            <Text style={styles.loginText}>Camera</Text>
           </TouchableOpacity>
          </View>
          {
            picSource!=null&&picSource.uri!=undefined&&
            <Image
              style={styles.image}
              source={{uri:picSource.uri}}
              // placeholder={blurhash}
              resizeMode="cover"
              // transition={1000}
          
            />
          }
          {
            (note!=''||(picSource!=null&&picSource.uri!=undefined))&&
          <View style={{borderRadius:10,padding:20}}> 

          <TouchableOpacity
            style={styles.loginScreenButton}
            onPress={Submit}
            underlayColor='#fff'>
            <Text style={styles.loginText}>Submit</Text>
           </TouchableOpacity>
          </View>
          }

      </View> 
      :
        <View style={{width:'100%',height:'100%',maxHeight:650}}>
          <Camera ref={cameraRef} style={{width:'100%',height:'100%'}} type={type} onCameraReady={ready} useCamera2Api={true} >
            <View style={styles.buttonContainer}>
              <MaterialIcons.Button name={'flip-camera-android'} backgroundColor={'rgba(76, 175, 80, 0.0)'} style={styles.button} onPress={toggleCameraType}>
              </MaterialIcons.Button>
               <MaterialIcons.Button name={"camera"} backgroundColor={'rgba(76, 175, 80, 0.0)'} style={styles.button} onPress={takePic}>
              </MaterialIcons.Button>
              <MaterialIcons.Button name={'exit-to-app'} backgroundColor={'rgba(76, 175, 80, 0.0)'} style={styles.button} onPress={exit}>
              </MaterialIcons.Button>
            </View>
          </Camera>
      </View> 
          }

      {
        // modalVisible&&
        <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          console.log('Modal has been closed.');
          setModalVisible(!modalVisible);
        }}>
          <View style={styles.centeredView2}>
            <View style={styles.centeredView}>
            <View style={styles.centeredView1}>
            <MaterialIcons.Button name={'exit-to-app'} backgroundColor={'rgba(76, 175, 80, 0.0)'} style={styles.button} onPress={() => setModalVisible(!modalVisible)}>
                </MaterialIcons.Button>
            </View>
              
              <View style={styles.modalView}>
                <Text style={styles.modalText}>Saving...</Text>
              </View>
            </View>
          </View>

      </Modal>
      }

      
    </View>
  );
}

export default observer(DoneModal)


const styles = StyleSheet.create({
  loginScreenButton:{
    marginRight:40,
    marginLeft:40,
    marginTop:10,
    paddingTop:10,
    paddingBottom:10,
    backgroundColor:'#1E6738',
    borderRadius:10,
    borderWidth: 1,
    borderColor: '#fff'
  },
  loginText:{
      color:'#fff',
      textAlign:'center',
      paddingLeft : 10,
      paddingRight : 10
  },
  container: {
    flex:1,
    // height:'100%',
    width:'100%',
    maxWidth:500,
    // backgroundColor:palette.black,
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'white',
  },
  buttonContainer: {
    position: 'absolute',
     bottom:20,
     left:0,
     flex: 1,
     flexDirection: 'row',
     backgroundColor: 'transparent',
     alignSelf: 'flex-end',
     width:'100%',
    justifyContent:'space-evenly'
   },
   image: {
       flex: 1,
       width: '100%',
       backgroundColor: '#0553',
       height:'100%',
       maxHeight:400
     },
   button: {
       // width: 50,
       // height: 25,
      
        flex:1,
       alignSelf: 'flex-end',
       alignItems: 'center',
     },
  container1: {
    paddingHorizontal: 10,
    flex:1,
    width:'100%',
    maxWidth:500,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'yellow',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // alignContent: 'flex-start',
    // alignSelf: 'flex-start',
    width:300,
    height:200,
    backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'yellow',
  },
  centeredView2: {

    marginTop:64,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'column',
    backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'red',
  },
  centeredView1: {
    // flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderTopLeftRadius:25,
    borderTopRightRadius:25,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 22,
    height:100,
    width:300,
    backgroundColor:'black',
    paddingBottom:0,
    marginBottom:-1,
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'green',

  },
  modalView: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    width:300,
    height:150,
    backgroundColor:'black',
    paddingTop:0,
    marginTop:-1,
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'blue',
  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    height: 26,
    textAlign: 'center',
    color: palette.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    width:'100%',
  },
  outside:{
    flex: 1,
    alignSelf:'stretch',
    justifyContent: 'center',
  },
  separator: {
    height: 1,
    marginVertical: 20,
    marginHorizontal: 20,
    color: palette.white,
    borderBottomColor: palette.white,
    borderBottomWidth: 1,
    flex:1,
    // alignSelf:'stretch'
  },
  itemContainer1: {
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
  },
  itemContainer2: {
    flex: 5,
    flexDirection: 'row',
    // alignSelf:'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemContainer3: {
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
  },
  itemContainer4: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
  },
  itemContainer5: {
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
    paddingHorizontal: 3,
  },

});
