import { StyleSheet, Button, TouchableOpacity,ScrollView, Platform } from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import Rounds from '../components/Rounds';
import Workout from '../components/Workout';
// import StartWorkoutButton from '../components/StartWorkoutButton';
import { Text, View } from '../components/Themed';
import { palette } from '../constants/Palette';
import { RootTabScreenProps } from '../types';
import { useState, useEffect, useRef } from "react"
import {firebase} from '../components/firebase';
import { useStores } from "../models"

import { quotes } from '../utils/quotes'
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"
import { A } from '@expo/html-elements';
import {Helmet} from "react-helmet";
import * as FileSystem from 'expo-file-system';
import { json11 } from '../utils/json1'
import { Video, AVPlaybackStatus, ResizeMode,Audio } from 'expo-av';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button"


export default function DownloadsScreen({ navigation }: RootTabScreenProps<'TabOne'>) {

  const downloadsDir = FileSystem.cacheDirectory + 'downloads/';
  
  
  const [os,setOs] = useState(Platform.OS)

  const db = firebase.firestore();
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const { userStore, downloadsStore } = useStores()
  const [quotes1, setQuotes1] = useState([]);
  const [platform, setPlatform] = useState(Platform.OS);
  const [playing, setPlaying] = useState(false);
  const [text,setShowText] = useState({'intro':{show:false,text:''}})
  const [downloads,setDownloads]=useState(JSON.parse(JSON.stringify(downloadsStore.getDownloads())))
  const [status,setStatus] = useState(null)
  const [selected,setSelected] = useState({'Level':'Beginner','Body Weight':true,'Trx Straps':false,'Bosu Ball':false,'Sandbag':false,'Pull Up Bar':false,'Medicine Ball':false,'Resistance Bands Long':false,'Resistance Bands Short':false})
  const video = useRef(null)
  const [activePro,setActivePro] = useState(userStore.getSubscriptionActive())
  const isFocused = useIsFocused();


  // useEffect(()=>{
  //   console.log('Platform: ',Platform)
  //   console.log('Downloads: ',downloadsStore.getDownloads())
  // },[])
  
  // const navigation = props.navigation

  // const { userStore, currentPageStore } = useStores()
  // const auth = firebase.auth()
  //   auth.onAuthStateChanged((user1) => {
  //   console.log(user1)
  //   if (user1) {
  //     // User is signed in, see docs for a list of available properties
  //     const uid = user1.uid;
  //     // setUser(uid)
  //     userStore.setUser(uid);
  //     console.log('uid*****************',uid)
  //   } else {
  //     // setUser('')
  //     userStore.setUser('');
  //     // userStore.setUser('1cO562FwUecu87cQc4bzHkNQ6Oj1');
        
  //     // User is signed out
  //   }
  // });



  async function ensureDirExists() {
    const dirInfo = await FileSystem.getInfoAsync(downloadsDir);
    if (!dirInfo.exists) {
      console.log("Downloads directory doesn't exist, creating...");
      await FileSystem.makeDirectoryAsync(downloadsDir, { intermediates: true });
    }
  }

  const check = async ()=>{
    console.log('check')
    try {
      // var ref = storageRef.child('vids/'+path);
      // const url = await ref.getDownloadURL()
      await ensureDirExists();
      const downs = JSON.parse(JSON.stringify(downloadsStore.getDownloads()))
      // console.log()
      Object.keys(downs).forEach(async(key,index)=>{
        console.log(key)
        const fileInfo = await FileSystem.getInfoAsync(downloadsDir+downs[key].gif);
        if(!fileInfo.exists){
        console.log(key, 'does not exsist')
          downloadsStore.setDownload({x:downs[key].x,delete:true})
        }
      })
      setDownloads(JSON.parse(JSON.stringify(downloadsStore.getDownloads())))


    } catch (e) {
      console.error("mhmm :", e);
    }
  }

  useEffect(()=>{
    if(userStore.getUser()==''){
      navigation.navigate('Login')
    }
    check()
  },[isFocused])

  const getDownloads=async(all)=>{

          // downloadsStore.setDownloads({})
    const json1 = await json11()

    try {
      // var ref = storageRef.child('vids/'+path);
      // const url = await ref.getDownloadURL()
      await ensureDirExists();

      const equip = ['Trx Straps','Bosu Ball','Sandbag','Pull Up Bar','Medicine Ball','Resistance Bands Long','Resistance Bands Short']

      console.log('Downloading', json1.length, 'gif files...');
      console.log(json1)
        // for(let i=0;i<1;i++){
        for(let i=0;i<json1.length;i++){

          if(!all){
            console.log('not all')
            if(selected['Level']=='Beginner'&&!json1[i]['level'].includes('Beginner')){
            console.log('out 1', json1[i]['level'])
              continue;
            }else if(selected['Level']=='Experienced'&&!json1[i]['level'].includes('Experienced')){
            console.log('out 2', json1[i]['level'])
              continue;
            }else if(!selected[json1[i]['equipment']]){
            console.log('out 3', json1[i]['equipment'])
              continue;
            }
          }

          // console.log(json1[i])
          // should check to see if already downloaded
          const fileInfo = await FileSystem.getInfoAsync(downloadsDir+json1[i].gif);
          // console.log(fileInfo)
          let audioFile = json1[i].audio.replace(/:/g,'_')
          audioFile = (downloadsDir+audioFile)
          



          // audioFile = audioFile.replace(/:/g,'_')
          // console.log(audioFile)
          const fileInfo1 = await FileSystem.getInfoAsync(audioFile);

          if (!fileInfo.exists||!fileInfo1.exists) {


            if(json1[i].x1!=undefined){
              let audioFile1 = json1[i].audio1.replace(/:/g,'_')
              audioFile1 = (downloadsDir+audioFile1)

              const fileV = await FileSystem.downloadAsync(await storageRef.child('vids/'+json1[i].gif).getDownloadURL(), (downloadsDir+json1[i].gif))
              const fileA = await FileSystem.downloadAsync(await storageRef.child('audio/'+json1[i].audio).getDownloadURL(), audioFile)
              
              const fileV1 = await FileSystem.downloadAsync(await storageRef.child('vids/'+json1[i].gif1).getDownloadURL(), (downloadsDir+json1[i].gif1))
              const fileA1 = await FileSystem.downloadAsync(await storageRef.child('audio/'+json1[i].audio1).getDownloadURL(), audioFile1)

              // console.log('file',file)
              // JSON.parse(JSON.stringify(downloadsStore.getDownloads()))
              downloadsStore.setDownload({x:json1[i].x,gif:json1[i].gif,audio:json1[i].audio,gifLoc:(downloadsDir+json1[i].gif),audioLoc:audioFile,x1:json1[i].x1,gif1:json1[i].gif1,audio1:json1[i].audio1,gifLoc1:(downloadsDir+json1[i].gif1),audioLoc1:audioFile1})
              console.log('should be in downloads: ',{x:json1[i].x,gif:json1[i].gif,gifLoc:(downloadsDir+json1[i].gif),audioLoc:audioFile,x1:json1[i].x1,gif1:json1[i].gif1,audio1:json1[i].audio1,gifLoc1:(downloadsDir+json1[i].gif1),audioLoc1:audioFile1})
              setDownloads(JSON.parse(JSON.stringify(downloadsStore.getDownloads())))
              
            }else{
              const fileV = await FileSystem.downloadAsync(await storageRef.child('vids/'+json1[i].gif).getDownloadURL(), (downloadsDir+json1[i].gif))
              const fileA = await FileSystem.downloadAsync(await storageRef.child('audio/'+json1[i].audio).getDownloadURL(), audioFile)

              // console.log('file',file)
              // JSON.parse(JSON.stringify(downloadsStore.getDownloads()))
              downloadsStore.setDownload({x:json1[i].x,gif:json1[i].gif,audio:json1[i].audio,gifLoc:(downloadsDir+json1[i].gif),audioLoc:audioFile})
              console.log('should be in downloads: ',{x:json1[i].x,gif:json1[i].gif,gifLoc:(downloadsDir+json1[i].gif),audioLoc:audioFile})
              setDownloads(JSON.parse(JSON.stringify(downloadsStore.getDownloads())))
            }

          }else if(downloads[json1[i].x]==undefined||(json1[i].x1!=undefined&&downloads[json1[i].x]!=undefined&&downloads[json1[i].x]['x1']==undefined)){

             if(json1[i].x1!=undefined){

              let audioFile1 = json1[i].audio1.replace(/:/g,'_')
              audioFile1 = (downloadsDir+audioFile1)

              downloadsStore.setDownload({x:json1[i].x,gif:json1[i].gif,audio:json1[i].audio,gifLoc:(downloadsDir+json1[i].gif),audioLoc:audioFile,x1:json1[i].x1,gif1:json1[i].gif1,audio1:json1[i].audio1,gifLoc1:(downloadsDir+json1[i].gif1),audioLoc1:audioFile1})
              console.log('should be in downloads1: ',{x:json1[i].x,gif:json1[i].gif,gifLoc:(downloadsDir+json1[i].gif),audioLoc:audioFile,x1:json1[i].x1,gif1:json1[i].gif1,audio1:json1[i].audio1,gifLoc1:(downloadsDir+json1[i].gif1),audioLoc1:audioFile1})
              setDownloads(JSON.parse(JSON.stringify(downloadsStore.getDownloads())))
              
             }else{
              downloadsStore.setDownload({x:json1[i].x,gif:json1[i].gif,audio:json1[i].audio,gifLoc:(downloadsDir+json1[i].gif),audioLoc:audioFile})
              console.log('should be in downloads1: ',{x:json1[i].x,gif:json1[i].gif,gifLoc:(downloadsDir+json1[i].gif),audioLoc:audioFile})
              setDownloads(JSON.parse(JSON.stringify(downloadsStore.getDownloads())))
            
             }

          }

        }
      // await Promise.all()
      
    } catch (e) {
      console.error("Couldn't download gif and audio files:", e);
    }
    

  }

 //  useEffect(()=>{
 //    getDownloads()    
 //    getQuotes()
 //  },[]) 

 // useEffect(()=>{  
 //    console.log('playing',playing)
 //  },[playing]) 

  // useEffect(()=>{
  //   downloads.forEach((item,index)=>{
  //   console.log('item',item,item["title"])
  //     setPlaying(prev=>{
  //       const clone = JSON.parse(JSON.stringify(prev))
  //       clone[item.url] = false
  //       return clone
  //     })
  //   })
  // },[downloads])

  var reword = function(quote){
    quote = quote.replace("'",'');
    var split = quote.split("' - ");
    return split;
  }



  const onStateChange = (url,state)=>{
    console.log('state change',state)
    if(state=='playing'){

      setPlaying(prev=>({
        ...prev,
        [url]: true
      }));
    }else{
      setPlaying(prev=>({
        ...prev,
        [url]: false
      }));
    }
  }

const togglePlaying = (url)=>{
      console.log('toggle', url,playing)

      setPlaying(prev =>({
        ...prev,
        [url]: !prev[url]
      }));
    
  }

  const playVid = async(item)=>{
    console.log(item,downloads[item].gifLoc,downloadsDir+downloads[item].gifLoc)
    const fileInfo = await FileSystem.getInfoAsync(downloads[item].gifLoc);
    console.log(fileInfo)
    if(fileInfo.exists){
      setDownloads(prev=>{
        const clone = JSON.parse(JSON.stringify(prev))
        Object.keys(clone).forEach((key,index)=>{
          if(key!=item&&clone[key]['playVid']){
            clone[key]['playVid']=false
          }
        })
        clone[item]['playVid'] =  clone[item]['playVid']!=undefined?!clone[item]['playVid']:true;

        setPlaying(!clone[item]['playVid'])
        setPlaying(clone[item]['playVid'])
        if(clone[item]['playVid']){
          playSound(item)

          setTimeout(function () {      
          if(video.current!=undefined&&video.current!=null&&!video.current.isPlaying){
            try{
            video.current.playAsync()
            }
            catch(err){
              console.log(err)
            }
          }
        }, 150);
        }
        return clone
      })
      console.log(item,' should be playing now')
    }else{
      console.log(item,' did not exsist - downloading')
      getDownloads()
    }

  }

  const playSound = async(item)=>{
    try{

    const sound = await new Audio.Sound()
    sound.loadAsync({uri:downloads[item]['audioLoc']})
      setTimeout(function () { 
      // console.log(sound)     
      if(sound._loaded){
        try{
        sound.playFromPositionAsync(0)
        }
        catch(err){
          console.log(err)
        }
      }
    }, 150);

    }catch(err){
      console.log(err)
    }

  } 

  // useEffect(()=>{
  //   console.log(playing)
  //   if(playing){

  //     setTimeout(function () {      
  //       if(video.current!=undefined&&video.current!=null&&!video.current.isPlaying){
  //         try{
  //         video.current.playAsync()
  //         }
  //         catch(err){
  //           console.log(err)
  //         }
  //       }
  //     }, 1000);

  //   }
  // },[playing])

  useEffect(()=>{
    console.log(video.current)
    // console.log(status)
    if(video.current!=undefined&&video.current!=null&&!video.current.isPlaying){
      try{
      video.current.playAsync()
      }
      catch(err){
        console.log(err)
      }
    }
  },[video])  
  // useEffect(()=>{
  //   // console.log(video.current)
  //   console.log(status)
    
  // },[status])


                // <Button title={playing[item.url] ? "pause" : "play"} onPress={()=>{togglePlaying(item.url)}} />
  return (

    <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} onStartShouldSetResponder={()=>true} style={styles.scroll}>
      

      <View style={styles.centeredView}>
      
        {
          os!='web'?
      <View style={{paddingTop:10}}>

        <Text>
          Download Video and Audio for offline use?
        </Text>
        <Text style={{paddingTop:8}}>
          Levels
        </Text>
        {
          activePro?

        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={selected['Level']}
          onSelected={(value) => setSelected(prev=>({
              ...prev,
              'Level': value
            })
          )}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="Beginner" label=<Text style={{color:'white'}}>Beginner</Text> />
          <RadioButtonItem value="Experienced" label=<Text style={{color:'white'}}>Experienced</Text> />
          <RadioButtonItem value="Athlete" label=<Text style={{color:'white'}}>Athlete</Text> />
        </RadioButtonGroup>
        :
        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={selected['Level']}
          onSelected={(value) => setSelected(prev=>({
              ...prev,
              'Level': value
            })
          )}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="Beginner" label=<Text style={{color:'white'}}>Beginner</Text> />
        </RadioButtonGroup>
        }

        <Text style={{paddingTop:8}}>
          Equipment
        </Text>

        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={'bw'}
          onSelected={console.log('bw')}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="bw" label=<Text style={{color:'white'}}>Body Weight</Text> />
        </RadioButtonGroup>

        {
          activePro&&
        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={selected['Trx Straps']}
          onSelected={(value) => {setSelected(prev=>({
              ...prev,
              [value]: !prev[value]?value:false
            })
          );console.log(selected)}}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="Trx Straps" label=<Text style={{color:'white'}}>Trx Straps</Text> />
        </RadioButtonGroup>
        }
        {
          activePro&&
        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={selected['Bosu Ball']}
          onSelected={(value) => setSelected(prev=>({
              ...prev,
              [value]: !prev[value]?value:false
            })
          )}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="Bosu Ball" label=<Text style={{color:'white'}}>Bosu Ball</Text> />
        </RadioButtonGroup>
        }
        {
          activePro&&
        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={selected['Sandbag']}
          onSelected={(value) => setSelected(prev=>({
              ...prev,
              [value]: !prev[value]?value:false
            })
          )}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="Sandbag" label=<Text style={{color:'white'}}>Sandbag</Text> />
        </RadioButtonGroup>
        }
        {
          activePro&&
        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={selected['Pull Up Bar']}
          onSelected={(value) => setSelected(prev=>({
              ...prev,
              [value]: !prev[value]?value:false
            })
          )}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="Pull Up Bar" label=<Text style={{color:'white'}}>Pull Up Bar</Text> />
        </RadioButtonGroup>
        }
        {
          activePro&&
        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={selected['Medicine Ball']}
          onSelected={(value) => setSelected(prev=>({
              ...prev,
              [value]: !prev[value]?value:false
            })
          )}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="Medicine Ball" label=<Text style={{color:'white'}}>Medicine Ball</Text> />
        </RadioButtonGroup>
        }
        {
          activePro&&
        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={selected['Resistance Bands Long']}
          onSelected={(value) => setSelected(prev=>({
              ...prev,
              [value]: !prev[value]?value:false
            })
          )}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="Resistance Bands Long" label=<Text style={{color:'white'}}>Resistance Bands Long</Text> />
        </RadioButtonGroup>
        }
        {
          activePro&&
        <RadioButtonGroup
          containerStyle={{ marginBottom: 10 }}
          selected={selected['Resistance Bands Short']}
          onSelected={(value) => setSelected(prev=>({
              ...prev,
              [value]: !prev[value]?value:false
            })
          )}
          containerOptionStyle={{margin:5}}
          radioBackground="white"
        >
          <RadioButtonItem value="Resistance Bands Short" label=<Text style={{color:'white'}}>Resistance Bands Short</Text> />
        </RadioButtonGroup>
        }
        
        

        <TouchableOpacity style={styles.button} onPress={()=>{getDownloads(false)}}>
          <Text>
          Download Selected
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={()=>{getDownloads(true)}}>
          <Text>
          Download All
          </Text>
        </TouchableOpacity>

      </View>
      :
      <View style={{paddingTop:10}}>
        <Text>
          Only available for mobile devices.
        </Text>
      </View>
      
        }

      {
        Object.keys(downloads).map((item,index)=>{
          // console.log(item,downloads[item])
          // if(item.url!=undefined&&item.url!='url'){
            return(
              
                <View key={item+index} style={downloads[item]['playVid']?styles.modalView1:styles.modalView2}>
                  <TouchableOpacity onPress={()=>{playVid(item)}} style={{ flex:1, flexDirection:'column', color:'white',minHeight:25,justifyContent:'center',alignItems:'center', paddingTop:10,}}>
                    <Text adjustsFontSizeToFit >
                    {downloads[item]['x1']!=undefined?downloads[item]['x']+' / Left':downloads[item]['x']}
                    </Text>
                  </TouchableOpacity>
                  {
                    downloads[item]['playVid']!=undefined&&downloads[item]['playVid']&&
                    <View style={{flex:5,justifyContent:'center',alignItems:'center',}}>
                    <Video
                      ref={video}
                      style={styles.video}
                      videoStyle={{height:'100%',width:'100%'}}
                      source={{
                        uri: downloads[item]['gifLoc'],
                      }}
                      useNativeControls={false}
                      resizeMode='contain'
                      isLooping
                      // onPlaybackStatusUpdate={status => setStatus(() => status)}
                    />            
                    </View>
                  }
                </View>

              

              )
          // }
        }) 
      }

     
      </View>


    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scroll: {
    flex: 0,
    flexGrow:1,
    flexDirection:'column',
    alignSelf: 'stretch',
    height:'100%',
    width:'100%',
    // maxWidth:1200,
    backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'red',

  },
  video:{
    flex:1,
    // alignItems: 'center',
    height: '100%',
    // position: 'relative',
    // width: 500,
    justifyContent:'center',alignItems:'center',
    borderWidth:1,borderColor:'white',borderStyle:'solid',
    
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: palette.black
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    padding:10,
    margin:10

  },
  modalView: {
    flex:1,
    flexWrap:'wrap',
    flexDirection: 'column',
    // alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    borderTopRightRadius:25,
    borderTopLeftRadius:25,
    width:'100%',
    maxWidth:600,
    minHeight:400,
    maxHeight:600,
    backgroundColor:'black',
    padding:15,
    marginTop:0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    // alignItemsVertically:'center'

  },
  modalView2: {
    flex:1,
    flexWrap:'wrap',
    flexGrow:1,
    flexDirection: 'column',
    // alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    borderTopRightRadius:25,
    borderTopLeftRadius:25,
    width:'100%',
    maxWidth:600,
    minHeight:50,
    // maxHeight:600,
    backgroundColor:'black',
    padding:15,
    marginTop:0,
    
    // alignItemsVertically:'center'

  },
  modalView3: {
    flex:1,
    flexWrap:'wrap',
    flexGrow:1,
    flexDirection: 'column',
    // alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    borderTopRightRadius:25,
    borderTopLeftRadius:25,
    width:'100%',
    maxWidth:600,
    minHeight:200,
    // maxHeight:600,
    backgroundColor:'black',
    padding:15,
    marginTop:0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    // alignItemsVertically:'center'

  },

  modalView1: {
    flex:1,
    // flexWrap:'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    borderTopRightRadius:25,
    borderTopLeftRadius:25,
    width:'100%',
    maxWidth:600,
    minHeight:400,
    // maxHeight:600,
    backgroundColor:'black',
    // padding:15,
    // marginTop:0,
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'green',
    // alignItemsVertically:'center'
    // alignSelf: 'stretch',
    // flexGrow:1,

  },

  centeredView: {
    flex: 1,
    flexGrow:1,

    justifyContent: 'flex-start',
    alignItems: 'center',
    // alignContent: 'flex-start',
    // alignSelf: 'flex-start',
    alignSelf: 'stretch',
    // maxWidth:1200,
    height:'100%',
    // maxHeight:600,
    // backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'yellow',
  },

  video: {
    marginTop: 20,
    // maxHeight: 200,
    width: 320,
    flex: 1
  }
});
