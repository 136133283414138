import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { RoundObjModel } from "../roundObj/roundObj"
import { WorkoutObjModel } from "../workoutObj/workoutObj"
import { UserModel } from "../user/user"
import { VidListModel } from "../vidList/vidList"
import { CurrentPageModel } from "../currentPage/currentPage"
import { DownloadsModel } from "../downloads/downloads"
// import { NavModel } from "../nav/nav"

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  workoutObjStore: types.optional(WorkoutObjModel, {} as any),
  roundObjStore: types.optional(RoundObjModel, {} as any),
  userStore: types.optional(UserModel, {} as any),
  vidListStore: types.optional(VidListModel, {} as any),
  currentPageStore: types.optional(CurrentPageModel, {} as any),
  downloadsStore: types.optional(DownloadsModel, {} as any),
  // navStore: types.optional(NavModel, {} as any),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
