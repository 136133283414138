/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { useState, useEffect, useRef } from 'react'

import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer, DefaultTheme, DarkTheme,createNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Pressable, Platform } from 'react-native';

import Colors from '../constants/Colors';
import { palette } from '../constants/Palette';
import useColorScheme from '../hooks/useColorScheme';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import TabOneScreen from '../screens/TabOneScreen';
import ParametersScreen from '../screens/ParametersScreen';
import WorkoutScreen from '../screens/WorkoutScreen';
import LoginScreen from '../screens/LoginScreen';
import ProfileScreen from '../screens/ProfileScreen';
import SupportScreen from '../screens/SupportScreen';
import DeleteAccountScreen from '../screens/DeleteAccountScreen';
import QuotesScreen from '../screens/QuotesScreen';
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen';
import WaiverScreen from '../screens/WaiverScreen';
import DownloadsScreen from '../screens/DownloadsScreen';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';

import { useStores } from "../models"
import { getSnapshot, onSnapshot } from "mobx-state-tree"
import * as Network from 'expo-network';


import {firebase} from '../components/firebase';
// import * as InAppPurchases from 'expo-in-app-purchases';
import Purchases from 'react-native-purchases';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {

  const functions = firebase.functions();
  const checkSubs = functions.httpsCallable('subCheckGoog')
  const db = firebase.firestore();

  const { userStore, currentPageStore, roundObjStore } = useStores()
  const auth = firebase.auth()
    auth.onAuthStateChanged(async(user1) => {
    console.log("user1",user1)
    if (user1) {
      // User is signed in, see docs for a list of available properties
      const uid = user1.uid;
      // setUser(uid)
      userStore.setUser(uid);
      console.log('uid*****************!',uid)
      const userDoc = await db.collection("users").doc(uid).get()
      const data = userDoc.data()
      console.log(data)
      const dateee = Date.now()
      console.log(dateee)

      if(data.promo){
        console.log('promo')
          userStore.setSubscriptionActive(true)
          userStore.setSubscriptionExpiration(dateee+(1000 * 60 * 60 * 24 * 30 * 1))
      }else if(data.expiry>dateee){
        console.log('expiry :',data.expiry,dateee)
        
        if(!userStore.subscriptionActive){
          userStore.setSubscriptionActive(true)
          userStore.setSubscriptionExpiration(expiry)
        } else{
          revCat() 
        }
      }else{
        revCat()
      }


    } else {
      // setUser('')
      userStore.setUser('');
      userStore.setSubscriptionActive(false)
      userStore.setSubscriptionExpiration(0)
      // userStore.setUser('1cO562FwUecu87cQc4bzHkNQ6Oj1');
        
      // User is signed out
    }
  });
  const [user, setUser] = useState(userStore.getUser()); 

  // useEffect(()=>{
  //   if(userStore.getUser()==''){
  //     console.log('user!!!!!!!!!!!',userStore.getUser())
  //     userStore.setSubscriptionActive(false)
  //     userStore.setSubscriptionExpiration(0)
  //   }else{
  //     console.log('user@@@@@@@@@@@',userStore.getUser())

  //   }
  // },[userStore])
  

  // const listen = async ()=>{
  //   InAppPurchases.setPurchaseListener(async({ responseCode, results, errorCode }) => {
  //       // Purchase was successful
  //       if (responseCode === InAppPurchases.IAPResponseCode.OK) {
  //         results.forEach(async (purchase) => {
  //           if (!purchase.acknowledged) {
  //             console.log(`Successfully purchased ${purchase.productId}`);
  //             // Process transaction here and unlock content...
  //             userStore.setSubscriptionActive(true)
              
  //             // Then when you're done
  //             await InAppPurchases.finishTransactionAsync(purchase, false);
  //             // await InAppPurchases.disconnectAsync()
  //           }
  //         });
  //       } else if (responseCode === InAppPurchases.IAPResponseCode.USER_CANCELED) {
  //         console.log('User canceled the transaction');
  //             await InAppPurchases.disconnectAsync()
  //       } else if (responseCode === InAppPurchases.IAPResponseCode.DEFERRED) {
  //         console.log('User does not have permissions to buy but requested parental approval (iOS only)');
  //             await InAppPurchases.disconnectAsync()
  //       } else {
  //         console.warn(`Something went wrong with the purchase. Received errorCode ${errorCode}`);
  //             await InAppPurchases.disconnectAsync()
  //       }
  //     });
  // }

  // const playStore = async ()=>{
  //   await InAppPurchases.connectAsync()
  //   console.log("should have connected@@@@@@@@@@@@@@@@@@@@@@@@@2")
  //   // const products = await InAppPurchases.getProductsAsync(['1_month','6_months','1_year'])
  //   const purchaseHistory = await InAppPurchases.getPurchaseHistoryAsync()
  //   // console.log(products)
  //   console.log(purchaseHistory)

  //   purchaseHistory.results.forEach(async (purchase,index)=>{
  //     if(purchase.acknowledged){
  //       await InAppPurchases.finishTransactionAsync(purchase, false);
  //     }
  //     if(index==0){

  //       if(purchase.productId=='1_year'||purchase.productId=='1_month'||purchase.productId=='6_months'||purchase.productId=='test1'){
  //         try{

  //           const check = await checkSubs({id:userStore.getUser(),packageName:'com.randomfitnessapp',subscriptionId:purchase.productId,token:purchase.purchaseToken})
  //           if(check.data.active!=undefined){
  //             console.log('check :',check)
  //             userStore.setSubscriptionActive(check.data.active)
  //             userStore.setSubscriptionExpiration(check.data.expiry)
  //           }else{
  //             console.log('check undefined : ',check)
  //           }
  //         }
  //         catch(err){
  //           console.log(err)
  //         }
  //       }
  //     }
  //   })



  //   await InAppPurchases.disconnectAsync()
  //   console.log("should have disconnected@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*2")
  // }

    // const netInfo = useNetInfo();
  const revCat = async ()=>{
    // console.log(netInfo.isConnected)
    const netInfo = await Network.getNetworkStateAsync();
    if(netInfo.isConnected!=undefined&&netInfo.isConnected){
      console.log("netInfo.isConnected",netInfo.isConnected)
    if (Platform.OS === 'ios') {
       // Purchases.configure({apiKey: <public_ios_sdk_key>});
      if(userStore.getUser()){
       await Purchases.configure({apiKey: "appl_ldYQIKKghHygdRrMTEhwZogCITY", appUserID:userStore.getUser()});
      }else{
       await Purchases.configure({apiKey: "appl_ldYQIKKghHygdRrMTEhwZogCITY"});
      }
    } else if (Platform.OS === 'android') {
      if(userStore.getUser()){
       await Purchases.configure({apiKey: "goog_cLWjXElrMXFvnoVQkvVdktHTYDU", appUserID:userStore.getUser()});
      }else{
       await Purchases.configure({apiKey: "goog_cLWjXElrMXFvnoVQkvVdktHTYDU"});
      }
    }


    // try {
    //   const offerings = await Purchases.getOfferings();
    //   if (offerings.current !== null) {  
    //     // Display current offering with offerings.current
    //     // console.log(offerings.current)

    //   }
    // } catch (e) {
    //     console.log(e)
    // }

    try {
      const customerInfo = await Purchases.getCustomerInfo();
        console.log("new",customerInfo)
      if(typeof customerInfo.entitlements.active["pro"] !== "undefined") {
        // Grant user "pro" access
        console.log(customerInfo.entitlements.active["pro"])
        userStore.setSubscriptionActive(customerInfo.entitlements.active["pro"].isActive)
        userStore.setSubscriptionExpiration(customerInfo.entitlements.active["pro"].expirationDateMillis)

        // console.log(userStore.getSubscriptionExpiration())
      }else{
        console.log("changed to false")
        userStore.setSubscriptionActive(false)
        userStore.setSubscriptionExpiration(0)
      }

    } catch (e) {
     // Error fetching purchaser info
        console.log(e)
    }
    }

    // console.log(userStore.getSubscriptionExpiration())

  }


  useEffect(()=>{
    const dateee = Date.now()
    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4",userStore.getSubscriptionExpiration(),dateee,userStore.getSubscriptionExpiration()>dateee)
    if(userStore.getSubscriptionExpiration()>dateee){
    console.log("$$$$$$$$$$$$$$$#####################$$$$4",true)
      userStore.setSubscriptionActive(true)
    }else{
      userStore.setSubscriptionActive(false)
      console.log('wtf#################################################################',userStore.getSubscriptionActive())
      for(let i=1;i<=20;i++){
        roundObjStore.setRoundObj(i,'Body Weight',true)
        roundObjStore.setRoundObj(i,'Trx Straps',false)
        roundObjStore.setRoundObj(i,'Bosu Ball',false)
        roundObjStore.setRoundObj(i,'Sandbag',false)
        roundObjStore.setRoundObj(i,'Pull Up Bar',false)
        roundObjStore.setRoundObj(i,'Medicine Ball',false)
        roundObjStore.setRoundObj(i,'Throwable Wall',false)
        roundObjStore.setRoundObj(i,'Slammable Floor',false)
        roundObjStore.setRoundObj(i,'Resistance Bands Long',false)
        roundObjStore.setRoundObj(i,'Resistance Bands Short',false)
      }
      for(let i=1;i<=20;i++){
        roundObjStore.setRoundObj(i,'level','Beginner')
      }
    }
    // if(!userStore.getSubscriptionActive()){
    //   // check if android or ios
    //   if (Platform.OS == 'android') {
    //     console.log('android not active')
    //     playStore()
    //   }
    // }else if(userStore.getSubscriptionExpiration()<dateee){
    //   if (Platform.OS == 'android') {
    //     console.log('android sub expired')
    //     playStore()
    //   }
    // }

  },[])

  console.log('user*****************',userStore.getUser())



  const navigationRef = createNavigationContainerRef()

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DarkTheme}
      >
      <RootNavigator/>
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator({ user, setUser}) {

  // console.log("root props - ", props)

  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={DrawerNavigator} options={{ headerShown: false }} />
      {/*<Stack.Screen name="Home" component={DrawerNavigator} options={{ title: 'Home' }} />
      <Stack.Screen name="Parameters" component={DrawerNavigator} options={{ title: 'Parameters' }} />
      <Stack.Screen name="Workout" component={DrawerNavigator} options={{ title: 'Workout' }} />
      <Stack.Screen name="Profile" component={DrawerNavigator} options={{ title: 'Profile' }} />
      <Stack.Screen name="Login" component={DrawerNavigator} options={{ title: 'Login' }} />*/}
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} options={{ title: 'Privacy Policy' }} />
      <Stack.Screen name="Support" component={SupportScreen} options={{ title: 'Support' }} />
      <Stack.Screen name="DeleteAccount" component={DeleteAccountScreen} options={{ title: 'DeleteAccount' }} />
      <Stack.Screen name="Waiver" component={WaiverScreen} options={{ title: 'Waiver' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Modal" component={ModalScreen} user={user} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const Drawer = createDrawerNavigator<RootTabParamList>();

function DrawerNavigator(props) {
  const colorScheme = useColorScheme();
  // console.log("props - ",props)
  const { userStore } = useStores()
  const [loggedIn,setLoggedIn] = useState(userStore.getUser() != '' ? 'Logout':'Login')

  onSnapshot(userStore, (newSnapshot) => {
    console.info("Got new snapshot:", newSnapshot)
    setLoggedIn(userStore.getUser() != '' ? 'Logout':'Login')

    if(!newSnapshot.subscriptionActive){
      for(let i=1;i<=20;i++){
        roundObjStore.setRoundObj(i,'Body Weight',true)
        roundObjStore.setRoundObj(i,'Trx Straps',false)
        roundObjStore.setRoundObj(i,'Bosu Ball',false)
        roundObjStore.setRoundObj(i,'Sandbag',false)
        roundObjStore.setRoundObj(i,'Pull Up Bar',false)
        roundObjStore.setRoundObj(i,'Medicine Ball',false)
        roundObjStore.setRoundObj(i,'Throwable Wall',false)
        roundObjStore.setRoundObj(i,'Slammable Floor',false)
        roundObjStore.setRoundObj(i,'Resistance Bands Long',false)
        roundObjStore.setRoundObj(i,'Resistance Bands Short',false)
      }
      for(let i=1;i<=20;i++){
        roundObjStore.setRoundObj(i,'level','Beginner')
      }
    }
  })

  return (
    <Drawer.Navigator
      initialRouteName="Home"
      screenOptions={{
        tabBarActiveTintColor: "white",        
        headerTintColor: "white",
      }}
      >
      <Drawer.Screen
        name="Home"
        component={TabOneScreen}
        options={({ navigation }: RootTabScreenProps<'Home'>) => ({
          title: 'Random Fitness App',
          headerTitleAlign: 'center',
          drawerLabel: 'Home',
          // headerRight: () => (
          //   <Pressable
          //     onPress={() => navigation.navigate('Modal')}
          //     style={({ pressed }) => ({
          //       opacity: pressed ? 0.5 : 1,
          //     })}>
          //     <FontAwesome
          //       name="info-circle"
          //       size={25}
          //       color={Colors[colorScheme].text}
          //       style={{ marginRight: 15 }}
          //     />
          //   </Pressable>
          // ),
        })}
      />
      
      <Drawer.Screen
        name="Parameters"
        component={ParametersScreen}
        options={({ navigation }: RootTabScreenProps<'Parameters'>) => ({
          title: 'Random Fitness App',
          headerTitleAlign: 'center',
          drawerLabel: 'Parameters',
        })}
      />
      <Drawer.Screen 
        name="Workout" 
        component={WorkoutScreen} 
        options={{ 
          title: 'Random Fitness App',
          headerTitleAlign: 'center',
          drawerLabel: 'Workout',
        }} 
      />
      <Drawer.Screen 
        name="Profile" 
        component={ProfileScreen} 
        options={{ 
          title: 'Random Fitness App',
          headerTitleAlign: 'center',
          drawerLabel: 'Profile',
        }} 
      />
      <Drawer.Screen 
        name="Quotes" 
        component={QuotesScreen} 
        options={{ 
          title: 'Random Fitness App Quotes',
          headerTitleAlign: 'center',
          drawerLabel: 'Quotes',
        }} 
      />
      <Drawer.Screen 
        name="Downloads" 
        component={DownloadsScreen} 
        options={{ 
          title: 'Random Fitness App Downloads',
          headerTitleAlign: 'center',
          drawerLabel: 'Downloads',
        }} 
      />
      
      <Drawer.Screen
        name="Login"
        component={LoginScreen}
        options={{
          title: 'Random Fitness App',
          headerTitleAlign: 'center',
          drawerLabel: loggedIn,
        }}
        // initialParams={{ user: props.route.params.user }}
      />
    </Drawer.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={5} style={{ marginBottom: -3 }} name="code" color={palette.lightGrey} />;
}
