import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Dimensions } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { useState, useEffect, useRef,useIsMounted } from "react"
import { FontAwesome } from '@expo/vector-icons';


import {firebase} from './firebase';
import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models"
import { observer } from "mobx-react-lite"

const Controls = ({ resting,setResting, volume, setVolume, setLeftRight })=> {

  const { roundObjStore, workoutObjStore,userStore } = useStores()
  const db = firebase.firestore();

   // useEffect(()=>{
   //  const yup1 =workoutObjStore[workoutObjStore.currentRound]
   //  console.log(yup1)
   //  console.log(workoutObjStore[workoutObjStore.currentRound].currentTarget)
   //  const yup2 =workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]]
   //  console.log(yup2)
   //  // const yup =workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].x
   //  // console.log(yup)
    
   // },[])

  function handleIncrement(dir){
    setLeftRight(true)
    workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentRestTime',workoutObjStore[workoutObjStore.currentRound].roundRestTime)
    workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentSetTime',workoutObjStore[workoutObjStore.currentRound].roundSetTime)
    if(!resting){
      setResting(true)
    
    if(dir=="left"){      
      // workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'full', true);
      // workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'targets', ['upper','core','lower']);
      let targetName = workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget].toString();
      let targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
      let typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length
      if(targetObj[targetObj.type]-1>=0){
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj.type, targetObj[targetObj.type]-1>=0?targetObj[targetObj.type]-1:typeLength-1)
      } else if( (workoutObjStore[workoutObjStore.currentRound].full && targetName=='booty' && targetObj.type-1>=1) || (workoutObjStore[workoutObjStore.currentRound].full && targetName!='booty' && targetObj.type-1>=1) ) {
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj.type, targetObj[targetObj.type]-1>=0?targetObj[targetObj.type]-1:typeLength-1)
        if(targetName=='booty'){
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type', targetObj.type-1>=1?targetObj.type-1:2)
        }else {
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type', targetObj.type-1>=1?targetObj.type-1:3)
        }
      }else {

      
        if(workoutObjStore[workoutObjStore.currentRound].full){
          console.log('full : ', workoutObjStore[workoutObjStore.currentRound].full)
          if(workoutObjStore[workoutObjStore.currentRound].currentTarget==0){
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',workoutObjStore[workoutObjStore.currentRound].targets.length)
            targetName = workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].targets.length-1].toString();
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTarget',workoutObjStore[workoutObjStore.currentRound].targets.length-1)
          }else {
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',workoutObjStore[workoutObjStore.currentRound].currentTarget)
            targetName = workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget-1].toString();
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTarget', workoutObjStore[workoutObjStore.currentRound].currentTarget-1)
          }
        }
        let targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
        let typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length

        console.log('targetName : ', targetName)
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj.type, targetObj[targetObj.type]-1>=0?targetObj[targetObj.type]-1:typeLength-1)
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type', targetObj.type-1>=1?targetObj.type-1:3)
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']['type'])
      
      }
      // }

    }else{
      // if(workoutObjStore[workoutObjStore.currentRound].type=='randomness'){

        const targetName = workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget];
        

        const targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
        console.log(targetName)
        console.log(targetObj.type)
        console.log(targetObj['1'])
        console.log(targetObj['2'])
        console.log(targetObj['3'])
        const typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length
        

        if(targetObj[targetObj.type]+1<=typeLength-1){
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj.type, targetObj[targetObj.type]+1>typeLength-1?0:targetObj[targetObj.type]+1)
          // workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']['type'])
        } else if( (workoutObjStore[workoutObjStore.currentRound].full && targetName=='booty' && targetObj.type+1<=2) || (workoutObjStore[workoutObjStore.currentRound].full && targetName!='booty' && targetObj.type+1<=3) ) {
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj.type, targetObj[targetObj.type]+1>typeLength-1?0:targetObj[targetObj.type]+1)
          // targetObj.type = targetObj.type+1>=2?2:targetObj.type+1;
          if(targetName=='booty'){
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>2?1:targetObj.type+1))
          }else{
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>3?1:targetObj.type+1))
          }
          // workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']['type'])
        
        } else {

          if(workoutObjStore[workoutObjStore.currentRound].full){
          // workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat',workoutObjStore[workoutObjStore.currentRound].currentTarget+1,workoutObjStore[workoutObjStore.currentRound].currentRepeat[workoutObjStore[workoutObjStore.currentRound].currentRepeat.type]-1)
            if(workoutObjStore[workoutObjStore.currentRound].currentTarget==2){
              workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',1)
              workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTarget',0)
            }else {
              workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',workoutObjStore[workoutObjStore.currentRound].currentTarget+2)
              workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTarget', workoutObjStore[workoutObjStore.currentRound].currentTarget+1)
            }
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj.type, targetObj[targetObj.type]+1>typeLength-1?0:targetObj[targetObj.type]+1)
            // targetObj.type = targetObj.type+1>=2?2:targetObj.type+1;
            if(targetName=='booty'){
              workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>2?1:targetObj.type+1))
            }else{
              workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>3?1:targetObj.type+1))
            }
          }else{

            // workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat',workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']['type'],workoutObjStore[workoutObjStore.currentRound].currentRepeat[workoutObjStore[workoutObjStore.currentRound].currentRepeat.type]-1)
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj.type, targetObj[targetObj.type]+1>typeLength-1?0:targetObj[targetObj.type]+1)
            // targetObj.type = targetObj.type+1>=2?2:targetObj.type+1;
            if(targetName=='booty'){
              workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>2?1:targetObj.type+1))
            }else{
              workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>3?1:targetObj.type+1))
            }
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']['type'])
          }
            
        }
    }
    }else{
      setResting(false)
    }
  }

  const {width} = Dimensions.get('window');

  // const handleVolume = ()=>{

  // }

  const blockX = async ()=>{
    let blockDoc = db
      .collection("users").doc(userStore.getUser())
      .collection("blockedXs").doc('blockedX');

      const targetName = workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget];
      
      const targetObj1 = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
      const targetObj2 = workoutObjStore[workoutObjStore.currentRound][targetName]

      if(targetObj2[targetObj1.type][targetObj1[targetObj1.type]]!=undefined){
        const xer = targetObj2[targetObj1.type][targetObj1[targetObj1.type]].x
          alert('Blocking - '+xer)

        const xer1 = xer.replace(/Left/gi,'Right');

        blockDoc.set({
            blocked: firebase.firestore.FieldValue.arrayUnion(xer1)
        },{merge: true})
        .then(function() {
          alert(xer+ ' has been blocked.  Go to profile page to see all blocked exercises.')
          handleIncrement('right')
        }).catch(function(error) {     
            console.error("Error Blocking: ", error);
            alert(error);
        });

      }
  }

  
  return (
    <View style={styles.container}>
      <View style={{ width: width, maxWidth:500, }}>

      <View style={styles.container1}>
        <View style={styles.outside}>
          {/*{screen1>0?*/}
          <TouchableOpacity onPress={()=>{handleIncrement('left')}}>
        <View style={styles.itemContainer1}>
          <FontAwesome
                  name="chevron-left"
                  size={25}
                  color={palette.white}
                  // style={{ marginLeft: 10 }}
                />
        </View> 
          </TouchableOpacity> 
        {/*:
          <View style={{ marginLeft: 20 }}/>
          }*/}
        </View>
        <View style={styles.itemContainer2}>
          {/*{screen1>0?*/}
          <TouchableOpacity style={styles.itemContainer4} onPress={()=>{setVolume(volume=>!volume)}} onLongPress={()=>{blockX()}}>
          {volume ?
            <View style={styles.itemContainer5}>
              <FontAwesome
                name="volume-up"
                size={25}
                color={palette.white}
                // style={{ paddingRight: 10 }}
              />
            </View>
            :
            <View style={styles.itemContainer5}>
              <FontAwesome
                name="volume-off"
                size={25}
                color={palette.white}
                // style={{ paddingRight: 10 }}
              />
            </View>             
          }
          <View style={styles.itemContainer5}>
            <Text style={styles.text}>

              {workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]]!=undefined&&
                workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].x}
            </Text>
          </View>
          </TouchableOpacity> 
        </View>
        <View style={styles.outside}>
          
          <TouchableOpacity onPress={()=>{handleIncrement('right')}}>
        <View style={styles.itemContainer3}>
          <FontAwesome
                  name="chevron-right"
                  size={25}
                  color={palette.white}
                  // style={{ marginRight: 10 }}
                />
        </View>
          </TouchableOpacity>
        
        </View>
      </View>

      <View style={styles.separator} >
      </View>

{/*      <View style={{alignSelf: 'stretch',}}>
        {screen1!=0?
          <RoundObj roundNum={roundNum} round={round} setRound={setRound} />
          :<View />
        }
      </View>*/}
      {/*<View >
        {screen1!=0?
          <View style={styles.separator} ></View>
          :<View />
        }
      </View>*/}


    

    </View>
    </View>
  );
}

export default observer(Controls)


const styles = StyleSheet.create({
  container: {
    // flex: 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth:500,
    // backgroundColor:palette.black,
    color: palette.white
  },
  container1: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // borderColor:'red',
    // borderWidth:1,
    // borderStyle:'solid',

  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
    // flex: 1, flexWrap: 'wrap',
    flexDirection:'row',
    flexShrink: 1, 
    // borderColor:'green',
    // borderWidth:1,
    // borderStyle:'solid',
  },
  outside:{
    flex: 1,
    alignSelf:'stretch',
    justifyContent: 'center',
  },
  separator: {
    height: 1,
    marginVertical: 20,
    marginHorizontal: 20,
    color: palette.white,
    borderBottomColor: palette.white,
    borderBottomWidth: 1,
    flex:1,
    // alignSelf:'stretch'
  },
  itemContainer1: {
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
    // borderColor:'yellow',
    // borderWidth:1,
    // borderStyle:'solid',
  },
  itemContainer2: {
    flex: 5,
    flexDirection: 'row',
    // alignSelf:'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    // borderColor:'blue',
    // borderWidth:1,
    // borderStyle:'solid',
  },
  itemContainer3: {
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
  //   borderColor:'purple',
  //   borderWidth:1,
  //   borderStyle:'solid',
  },
  itemContainer4: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
    // borderColor:'gold',
    // borderWidth:1,
    // borderStyle:'solid',
    // xOverflow:'none',
  },
  itemContainer5: {

    flexDirection:'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
    paddingHorizontal: 3,
    // borderColor:'aqua',
    // borderWidth:1,
    // borderStyle:'solid',
    flexShrink: 1, 
  },

});
