import { StyleSheet, Button,TouchableOpacity } from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import Rounds from '../components/Rounds';
import Workout from '../components/Workout';
// import StartWorkoutButton from '../components/StartWorkoutButton';
import { Text, View } from '../components/Themed';
import { palette } from '../constants/Palette';
import { RootTabScreenProps } from '../types';
import { useState, useEffect, useRef } from "react"
import {firebase} from '../components/firebase';
import { useStores } from "../models"




export default function ParametersScreen({ navigation }) {

  const { roundObjStore, workoutObjStore } = useStores()
  const [screen,setScreen] = useState(0)
  // const [roundObj, setRoundObj] = useState({})

  const startWorkout =async ()=>{
  //   setScreen(prev => prev==0? prev+1:prev-1)
    workoutObjStore.setCurrentRound(1)
    
    const obj = JSON.parse(JSON.stringify(roundObjStore))

    for(let a=obj.roundNum+1;a<=20;a++){
      if(obj.hasOwnProperty(a)) delete obj[a];
    }

    for(let i=1;i<=roundObjStore.roundNum;i++){
      workoutObjStore.setWorkoutObj(i,'currentTarget',0)
      workoutObjStore.setWorkoutObj(i,'currentRepeat', 'type' , 1)
      workoutObjStore.setWorkoutObj(i,'currentRepeat', '1' , roundObjStore[i].repeat)
      workoutObjStore.setWorkoutObj(i,'currentRepeat', '2' , roundObjStore[i].repeat)
      workoutObjStore.setWorkoutObj(i,'currentRepeat', '3' , roundObjStore[i].repeat)
      workoutObjStore.setWorkoutObj(i,'currentTime',roundObjStore[i].roundTime*60)
      workoutObjStore.setWorkoutObj(i,'currentSetTime',roundObjStore[i].roundSetTime)
      workoutObjStore.setWorkoutObj(i,'currentRestTime',roundObjStore[i].roundRestTime)
      
    }
    // const pickem1 = await pickem(userStore.getUser(),roundObjStore.getRoundObjj())
    // pickem1.forEach((item,index)=>{
    //   parts.forEach((item1,index1)=>{
    //     console.log(item,item1,item[item1])
    //       workoutObjStore.setWorkoutObj(String(index+1),item1,item[item1])
    //       workoutObjStore.setWorkoutObj(String(index+1),item1+'Curr',{'1':0,'2':0,'3':0,'type':1})
    //       // setReady(true)
    //   })
    // })
    navigation.navigate('Workout')
  //   console.log(JSON.stringify(obj));
  }

  return (
    <View style={styles.container}>
      {/*<Text style={styles.title}>Directions</Text>*/}
      {/*<Text style={styles.title}>Saved Workouts</Text>*/}
      <View style={{flex:1}}>
          <Rounds navigation={navigation} />
      </View>
      <View style={{flex:0, justifyContent:'flex-end',width:'80%',maxWidth:400, paddingBottom: 20}}>
        {/*{screen==0?*/}
          {/*<Button
            onPress={startWorkout}
            title={"Start Workout"}
            // color="#023609"
            accessibilityLabel="Start Workout Button"
            style={{borderRadius:10, backgroundColor:"#023609"}}
          />*/}
          <TouchableOpacity
            style={styles.loginScreenButton}
            onPress={startWorkout}
            underlayColor='#fff'>
            <Text style={styles.loginText}>Start Workout</Text>
           </TouchableOpacity>
          {/*:
          <View/>
        }*/}
      </View>
      


    </View>
  );
}

const styles = StyleSheet.create({
  loginScreenButton:{
    marginRight:40,
    marginLeft:40,
    marginTop:10,
    paddingTop:10,
    paddingBottom:10,
    backgroundColor:'#1E6738',
    borderRadius:10,
    borderWidth: 1,
    borderColor: '#fff'
  },
  loginText:{
      color:'#fff',
      textAlign:'center',
      paddingLeft : 10,
      paddingRight : 10
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: palette.black
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
