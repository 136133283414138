import React, { useState, useEffect } from "react"
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
// import { useBackButtonHandler, canExit, useNavigationPersistence } from "./navigators"
import { RootStore, RootStoreProvider, setupRootStore } from "./models"

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  // const {
  //   initialNavigationState,
  //   onNavigationStateChange,
  //   isRestored: isNavigationStateRestored,
  // } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  useEffect(() => {
    ;(async () => {
      setupRootStore().then(setRootStore)
    })()
  }, [])

  if (!isLoadingComplete || !rootStore) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <RootStoreProvider value={rootStore}>
          <Navigation colorScheme={colorScheme} />
          <StatusBar />
        </RootStoreProvider>

      </SafeAreaProvider>
    );
  }
}
