import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Dimensions,Button } from 'react-native';
import { RadioButton } from 'react-native-paper';
import PickNumRounds from './PickNumRounds';
import PickTypeRounds from './PickTypeRounds';
import PickTimeRounds from './PickTimeRounds';
import PickLevelRounds from './PickLevelRounds';
import PickTargetRounds from './PickTargetRounds';
import PickEquipmentRounds from './PickEquipmentRounds';
import RoundObj from './RoundObj';
import SwitchScreen from './SwitchScreen';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import { FontAwesome } from '@expo/vector-icons';
import { useStores } from "../models"
// import { RoundObjModel } from "../models/roundObj/roundObj"

import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
  import { observer } from "mobx-react-lite"
  import { pickem } from '../utils/pickem';


const Rounds = ({ navigation }) => {

  const { roundObjStore,workoutObjStore,userStore } = useStores()

  // const [roundNum, setRoundNum] = useState(1)
  // const [restNum, setRestNum] = useState(30)
  const [round, setRound] = useState(1)
  const [screen, setScreen] = useState("Set Number of Rounds")
  const [screen1, setScreen1] = useState(0)
  const [screenNum, setScreenNum] = useState(0)
  const screenArray = ["Set Number of Rounds","Set Type for Rounds","Set Time for Rounds","Set Level for Rounds","Set Target for Rounds","Set Equipment for Rounds"]
  const parts = ['upper','lower','core','arms','booty']

  useEffect(()=>{
    if(round>roundObjStore.roundNum){
      setRound(roundObjStore.roundNum)
    }
  },[roundObjStore.roundNum])  

  function handleIncrement(dir){
      const index = screenArray.indexOf(screen)
    if(dir=="left"){
      setScreen(screenArray[(index-1) >= 0 ? (index-1):index])
      setScreen1((index-1) >= 0 ? (index-1):index)
    }else{
      setScreen(screenArray[(index+1) <= (screenArray.length-1) ? index+1:index])
      setScreen1((index+1) <= (screenArray.length-1) ? index+1:screenArray.length-1)
    }
  }
  const {width} = Dimensions.get('window');

  

  return (
    <View style={styles.container}>
      <View style={{ width: width, maxWidth:500, }}>
      <View style={styles.container1}>
        <View style={styles.outside}>
          {screen1>0?
          <TouchableOpacity onPress={()=>{handleIncrement('left')}}>
        <View style={styles.itemContainer1}>
          <FontAwesome
                  name="chevron-left"
                  size={25}
                  color={palette.white}
                  style={{ marginLeft: 20 }}
                />
        </View> 
          </TouchableOpacity> 
        :
          <View style={{ marginLeft: 20 }}/>
          }
        </View>
        <View style={styles.itemContainer2}>
          <Text
            style={styles.text}
            >
            {screen}
          </Text>
        </View>
        <View style={styles.outside}>
          {screen1<screenArray.length-1?
          <TouchableOpacity onPress={()=>{handleIncrement('right')}}>
        <View style={styles.itemContainer3}>
          <FontAwesome
                  name="chevron-right"
                  size={25}
                  color={palette.white}
                  style={{ marginRight: 20 }}
                />
        </View>
          </TouchableOpacity>
          :
          <View style={{ marginRight: 20 }}/>
        }
        </View>
      </View>

      <View style={styles.separator} >
      </View>

      <View style={{alignSelf: 'stretch', }}>
        {screen1!=0?
          <RoundObj round={round} setRound={setRound} />
          :<View />
        }
      </View>
      <View >
        {screen1!=0?
          <View style={styles.separator} ></View>
          :<View></View>
        }
      </View>


      <View style={{alignSelf: 'stretch', justifyContent:'flex-start',alignItems:'center'}}>
      {screen1==0 ?
        <PickNumRounds />
        : screen1==1?
        <PickTypeRounds round={round} />
        : screen1==2?
        <PickTimeRounds round={round} />
        : screen1==3?
        <PickLevelRounds round={round} /> 
        : screen1==4?
        <PickTargetRounds round={round} />
        :
        <PickEquipmentRounds round={round} />
     
      }
      </View>

      

    </View>
    </View>
  );
}

export default observer(Rounds)


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth:500,
    // backgroundColor:palette.black,
    color: palette.white
  },
  container1: {
    
    paddingHorizontal: 10,
    paddingTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',


  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  // separatorCon: {
  //   flexDirection: 'row', 
  //   alignItems: 'center',
  //   height: 2,
  // },
  separator: {
    marginVertical: 20,
    marginHorizontal: 20,
    height: 1,
    // width: 300,
    color: palette.white,
    borderBottomColor: palette.white,
    borderBottomWidth: 1,
    flex:1,
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row',
    alignSelf:'stretch',
  },
  outside:{
    flex: 1,
    alignSelf:'stretch',
    justifyContent: 'center',
  },
  itemContainer1: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: palette.white,
  },
  itemContainer2: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemContainer3: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    color: palette.white,
  },

});
