import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */


export const CurrentPageModel = types
  .model("CurrentPage")
  .props({
    currentPage: types.optional(types.string, ""),
    token: types.optional(types.string, ""),
  })
  .views((self) => ({
    getCurrentPage: ()=>{
      return self.currentPage
    },
    getToken: ()=>{
      return self.token
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setCurrentPage: (e)=>{
      self.currentPage=e
    },
    setToken: (e)=>{
      self.token=e
    },
}))
type CurrentPageType = Instance<typeof CurrentPageModel>
export interface CurrentPage extends CurrentPageType {}
type CurrentPageSnapshotType = SnapshotOut<typeof CurrentPageModel>
export interface CurrentPageSnapshot extends CurrentPageSnapshotType {}
export const createCurrentPageDefaultModel = () => types.optional(CurrentPageModel, {})
