import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import SegmentedControl from '@react-native-segmented-control/segmented-control';



import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';

import { useStores } from "../models"
import { observer } from "mobx-react-lite"

const PickTargetRounds = ({ round})=> {

  const { roundObjStore,workoutObjStore } = useStores()

  const switchSelected = (type)=>{
    switch(type){
      case 'Upper Body': return 0;
        break;
      case 'Core': return 1;
        break;
      case 'Lower Body': return 2;
        break;
      default : return -1;
    }
  }
  const switchSelected1 = (type)=>{
    switch(type){
      case 'Full Body': return 0;
        break;
      case 'Arms': return 1;
        break;
      case 'Booty': return 2;
        break;
      default : return -1;
    }
  }

  const [selectedIndex, setSelectedIndex] = useState(switchSelected(roundObjStore[round]['target']))
  const [selectedIndex1, setSelectedIndex1] = useState(switchSelected1(roundObjStore[round]['target']))
  const typeArray = ['Upper Body', 'Core', 'Lower Body']
  const typeArray1 = ['Full Body', 'Arms', 'Booty']
  const typeArray2 = [['upper'], ['core'], ['lower']]
  const typeArray3 = [['lower', 'core', 'upper'], ['arms'], ['booty']]
  useEffect(()=>{
    // console.log([typeArray[selectedIndex]])
    // console.log([typeArray1[selectedIndex1]])
    workoutObjStore.setWorkoutObj(round,"targets",selectedIndex>=0? typeArray2[selectedIndex]: typeArray3[selectedIndex1])
    if(selectedIndex1!=0){
      workoutObjStore.setWorkoutObj(round,"currentTarget",0)
      workoutObjStore.setWorkoutObj(round,"full",false)
    }else{
      workoutObjStore.setWorkoutObj(round,"full",true)
    }
    roundObjStore.setRoundObj(round,"target",selectedIndex>=0? typeArray[selectedIndex]: typeArray1[selectedIndex1])
  },[selectedIndex,selectedIndex1])

  

  useEffect(()=>{
    setSelectedIndex(switchSelected(roundObjStore[round]['target']))
    setSelectedIndex1(switchSelected1(roundObjStore[round]['target']))
  },[round])

  return (
    <View style={styles.container}>
      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Target for Round {round}
        </Text>
      </View>
      <View style={styles.numbers}>
      <SegmentedControl
          values={typeArray}
          selectedIndex={selectedIndex}
          onChange={(event) => {
            setSelectedIndex(event.nativeEvent.selectedSegmentIndex);
            setSelectedIndex1(-1);
          }}
          tintColor={palette.lightGrey}
          appearance={'dark'}
        />
      </View>
      <View style={styles.numbers}>
      <SegmentedControl
          values={typeArray1}
          selectedIndex={selectedIndex1}
          onChange={(event) => {
            setSelectedIndex(-1);
            setSelectedIndex1(event.nativeEvent.selectedSegmentIndex);
          }}
          tintColor={palette.lightGrey}
          appearance={'dark'}
          // style={{}}
        />
      </View>
      {/*<View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          {roundObjStore[round]['target']}
        </Text>
        
      </View>*/}

    </View>
  );
}

export default observer(PickTargetRounds)


const styles = StyleSheet.create({
  container: {
    alignSelf:'stretch',
    // flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    // flexDirection:'row',
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    // flex:1,
    marginBottom: 20,
    marginHorizontal: 20,
    alignItems: 'center',

  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    // flex:1,
    alignSelf:"stretch",
    // flex: 1,
    // flexDirection:'row',
    marginTop: 5,
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
  },
});
