import { StyleSheet, Button, TouchableOpacity,ScrollView, Platform } from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import Rounds from '../components/Rounds';
import Workout from '../components/Workout';
// import StartWorkoutButton from '../components/StartWorkoutButton';
import { Text, View } from '../components/Themed';
import { palette } from '../constants/Palette';
import { RootTabScreenProps } from '../types';
import { useState, useEffect, useRef } from "react"
import {firebase} from '../components/firebase';
import { useStores } from "../models"

import { quotes } from '../utils/quotes'
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"
import YoutubePlayer from "react-native-youtube-iframe";
import { AntDesign } from '@expo/vector-icons'
import { A } from '@expo/html-elements';
import {Helmet} from "react-helmet";





export default function TabOneScreen({ navigation }: RootTabScreenProps<'TabOne'>) {

  const db = firebase.firestore();
  const { roundObjStore, workoutObjStore, vidListStore } = useStores()
  const [quotes1, setQuotes1] = useState([]);
  const [platform, setPlatform] = useState(Platform.OS);
  const [playing, setPlaying] = useState({});
  const [text,setShowText] = useState({'intro':{show:false,text:''}})
  const [vids,setVids]=useState(JSON.parse(JSON.stringify(vidListStore.getVidList())))

  useEffect(()=>{
    console.log('Platform: ',Platform)
  },[])
  
  // const navigation = props.navigation

  // const { userStore, currentPageStore } = useStores()
  // const auth = firebase.auth()
  //   auth.onAuthStateChanged((user1) => {
  //   console.log(user1)
  //   if (user1) {
  //     // User is signed in, see docs for a list of available properties
  //     const uid = user1.uid;
  //     // setUser(uid)
  //     userStore.setUser(uid);
  //     console.log('uid*****************',uid)
  //   } else {
  //     // setUser('')
  //     userStore.setUser('');
  //     // userStore.setUser('1cO562FwUecu87cQc4bzHkNQ6Oj1');
        
  //     // User is signed out
  //   }
  // });

  const checkVids=async()=>{

    try{

      const userDoc = await db.collection("feeds").doc('vidList').get()
      const data = userDoc.data()
      console.log(data)
      if(data.vidListArray!=undefined){
        vidListStore.setVidList(data.vidListArray)
      }
    }
    catch(err){
      console.log(err)
    }

  }

  useEffect(()=>{
    checkVids()    
    getQuotes()
  },[]) 

 useEffect(()=>{  
    console.log('playing',playing)
  },[playing]) 

  useEffect(()=>{
    vids.forEach((item,index)=>{
    console.log('item',item,item["title"])
      setPlaying(prev=>{
        const clone = JSON.parse(JSON.stringify(prev))
        clone[item.url] = false
        return clone
      })
    })
  },[vids])

  var reword = function(quote){
    quote = quote.replace("'",'');
    var split = quote.split("' - ");
    return split;
  }

  const getQuotes = async ()=>{
    const q = await quotes()
    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay)-1;
    console.log('Day of year: ' + day);
    const item1 = reword(q[day]['quote'])

    setQuotes1({'quote':item1[0],'author':item1[1],fontSize:q[day]['fontSize']})


  }

  const onStateChange = (url,state)=>{
    console.log('state change',state)
    if(state=='playing'){

      setPlaying(prev=>({
        ...prev,
        [url]: true
      }));
    }else{
      setPlaying(prev=>({
        ...prev,
        [url]: false
      }));
    }
  }
const togglePlaying = (url)=>{
      console.log('toggle', url,playing)

      setPlaying(prev =>({
        ...prev,
        [url]: !prev[url]
      }));
    
  }


                // <Button title={playing[item.url] ? "pause" : "play"} onPress={()=>{togglePlaying(item.url)}} />
  return (
    <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} onStartShouldSetResponder={()=>true} style={styles.scroll}>
      {/*<Helmet>
        <meta name="facebook-domain-verification" content="vph1qhr0rfzcgor5wg5uci204nu3pn" />
      </Helmet>*/}

      <View style={styles.centeredView}>

      {platform=='web'&&
        <View style={styles.modalView3}>
          <TouchableOpacity style={{ flex:1, flexDirection:'row', color:'white',minHeight:25,justifyContent:'center',alignItems:'center', paddingTop:10,}}>
            <A style={{color:'white', fontSize:25}} fontSize={50} href="https://play.google.com/store/apps/details?id=com.randomfitnessapp">Download the App for Android</A>
          </TouchableOpacity>
        </View>
      }
      {platform=='web'&&
        <View style={styles.modalView3}>
          <TouchableOpacity style={{ flex:1, flexDirection:'row', color:'white',minHeight:25,justifyContent:'center',alignItems:'center', paddingTop:10,}}>
            <A style={{color:'white', fontSize:25}} fontSize={50} href="https://apps.apple.com/us/app/random-fitness-app/id6454231039">Download the App for ios</A>
          </TouchableOpacity>
        </View>
      }

      {
       quotes1['fontSize']!=undefined&& 
      <View style={styles.modalView}>
        <View style={{justifyContent:'center',width:'100%',padding:0}}>
          <Text adjustsFontSizeToFit style={{color:'white',fontSize:Number(quotes1['fontSize']*.85),justifyContent:'center',alignItems:'center', paddingTop:10,}}>{quotes1['quote']}  </Text>
        </View>
        <View style={{alignItems:'center',justifyContent:'center',width:'100%',padding:0}}>
          <Text adjustsFontSizeToFit style={{color:'white',fontSize:Number(quotes1['fontSize']*.85),justifyContent:'center',alignItems:'center',padding:10,}}>{quotes1['author']}  </Text>
        </View>
        </View>
      }


      {
        vids.map((item,index)=>{
          console.log(item)
          if(item.url!=undefined&&item.url!='url'){
            return(
              <View key={item+index} style={styles.modalView1}>
              
              <View style={styles.modalView2}>
                <TouchableOpacity style={{ flex:1, flexDirection:'row', color:'white',minHeight:25,justifyContent:'center',alignItems:'center', paddingTop:10,}}>
                  <Text adjustsFontSizeToFit >
                  {item.title + " "}
                  </Text>
                  
                </TouchableOpacity>
                <YoutubePlayer
                  key={item+index}
                  height={'90%'}
                  width={'100%'}
                  play={playing[item.url]}
                  videoId={item.url}
                  onChangeState={(state)=>{onStateChange(item.url,state)}}
                  onError={(err)=>{console.log(err)}}
                  webViewStyle={{opacity: 0.99}}
                  resumePlayAndroid={false}
                />
              </View>

              {item.text!=undefined&&
                <View style={styles.modalView2}>
                <TouchableOpacity style={{ flex:1, flexDirection:'column', color:'white',minHeight:25,justifyContent:'center',alignItems:'center', paddingTop:10,}}>

                  <Text adjustsFontSizeToFit style={{ color:'white',minHeight:25,justifyContent:'center',alignItems:'center', paddingTop:10,}}>
                    {item.title}
                  </Text>
                  <Text style={{color:'white',minHeight:25,justifyContent:'center',alignItems:'center', paddingTop:10,}}>
                    {item.text}
                  </Text>
                </TouchableOpacity>
                </View>
              }
                </View>

              )
          }
        }) 
      }

      <View style={{paddingTop:10}}>
        <TouchableOpacity onPress={()=>{navigation.navigate('PrivacyPolicy')}}>
          <Text>
          Privacy Policy
          </Text>
        </TouchableOpacity>
      </View>
      </View>


    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scroll: {
    flex: 0,
    flexGrow:1,
    flexDirection:'column',
    alignSelf: 'stretch',
    height:'100%',
    width:'100%',
    // maxWidth:1200,
    backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'red',

  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: palette.black
  },
  modalView: {
    flex:1,
    flexWrap:'wrap',
    flexDirection: 'column',
    // alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    borderTopRightRadius:25,
    borderTopLeftRadius:25,
    width:'100%',
    maxWidth:600,
    minHeight:400,
    maxHeight:600,
    backgroundColor:'black',
    padding:15,
    marginTop:0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    // alignItemsVertically:'center'

  },
  modalView2: {
    flex:1,
    flexWrap:'wrap',
    flexGrow:1,
    flexDirection: 'column',
    // alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    borderTopRightRadius:25,
    borderTopLeftRadius:25,
    width:'100%',
    maxWidth:600,
    minHeight:400,
    // maxHeight:600,
    backgroundColor:'black',
    padding:15,
    marginTop:0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    // alignItemsVertically:'center'

  },
  modalView3: {
    flex:1,
    flexWrap:'wrap',
    flexGrow:1,
    flexDirection: 'column',
    // alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    borderTopRightRadius:25,
    borderTopLeftRadius:25,
    width:'100%',
    maxWidth:600,
    minHeight:200,
    // maxHeight:600,
    backgroundColor:'black',
    padding:15,
    marginTop:0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    // alignItemsVertically:'center'

  },

  modalView1: {
    flex:1,
    // flexWrap:'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'black',
    // borderBottomLeftRadius:25,
    // borderBottomRightRadius:25,
    // borderTopRightRadius:25,
    // borderTopLeftRadius:25,
    width:'100%',
    maxWidth:600,
    minHeight:800,
    // maxHeight:600,
    backgroundColor:'black',
    // padding:15,
    // marginTop:0,
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'green',
    // alignItemsVertically:'center'
    // alignSelf: 'stretch',
    // flexGrow:1,

  },

  centeredView: {
    flex: 1,
    flexGrow:1,

    justifyContent: 'flex-start',
    alignItems: 'center',
    // alignContent: 'flex-start',
    // alignSelf: 'flex-start',
    alignSelf: 'stretch',
    // maxWidth:1200,
    height:'100%',
    // maxHeight:600,
    // backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'yellow',
  },

  video: {
    marginTop: 20,
    // maxHeight: 200,
    width: 320,
    flex: 1
  }
});
