import { StyleSheet, Button,Linking,ScrollView } from 'react-native';

import Rounds from '../components/Rounds';
import Profile from '../components/Profile';
// import StartWorkoutButton from '../components/StartWorkoutButton';
import { Text, View } from '../components/Themed';
import { palette } from '../constants/Palette';
import { RootTabScreenProps } from '../types';
import { useState, useEffect, useRef } from "react"
import {firebase} from '../components/firebase';
import { useStores } from "../models"
import { RootStackScreenProps } from '../types';
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"




export default function WaiverScreen({}: RootStackScreenProps<'Waiver'>) {
  const db = firebase.firestore();
  const navigation = useNavigation()

  const { userStore } = useStores()
  // const [screen,setScreen] = useState(0)
  // const [roundObj, setRoundObj] = useState({})
  const accept = async()=>{
    console.log('accept')
    var waiverSigned = db
    .collection("users").doc(userStore.getUser())
    .collection("waivers").doc('waiver');
    try{
      const doc = await waiverSigned.set({'signed':true})
      navigation.navigate('Root',{'screen':'Workout'})
    }
    catch(err){
      console.log(err)
      if(String(err).includes('permission')){
        console.log('includes')
        navigation.navigate('Root',{'screen':'Login'})
      }else{
        alert(err)
      }
    }
  }

  return (
    <View style={styles.container}>
      {/*<Text style={styles.title}>Directions</Text>*/}
      {/*<Text style={styles.title}>Saved Workouts</Text>*/}
      <ScrollView style={{flex:1,padding:10}}>
        <Text>WAIVER & RELEASE</Text>
        <Text>Because physical exercise can be strenuous and subject to risk of serious injury, we urge you to obtain a physical examination from a doctor before using any exercise equipment or participating in any exercise activity. You agree that by participating in physical exercise or training activities, you do so entirely at your own risk. Any recommendation for changes in diet including the use of food supplements, weight reduction and/or body building enhancement products are entirely your responsibility and you should consult a physician prior to undergoing any dietary or food supplement changes. You agree that you are voluntarily participating in these activities and assume all risks of injury, illness, or death. You acknowledge that you have carefully read this “waiver and release” and fully understand that it is a release of liability. You expressly agree to release and discharge random fitness app and the trainer from any and all claims or causes of action and you agree to voluntarily give up or waive any right that you may otherwise have to bring a legal action against the trainer or instructor for personal injury or property damage. To the extent that statute or case law does not prohibit releases for negligence, this release is also for negligence. If any portion of this release from liability shall be deemed by a Court of competent jurisdiction to be invalid, then the remainder of this release from liability shall remain in full force and effect and the offending provision or provisions severed here from. I acknowledge that I understand its content and that this release cannot be modified orally.</Text>
        <View style={{paddingTop:20, justifyContent:'center',alignItems:'center'}} >
        <Button style={{maxWidth:200}} onPress={accept} title={'Accept'}/>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: palette.black
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
