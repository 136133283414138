 import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Text,Platform } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import {firebase} from './firebase';
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"


import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { View } from './Themed';
import { useStores } from "../models";
import { observer } from "mobx-react-lite"
// import * as InAppPurchases from 'expo-in-app-purchases';
import Purchases from 'react-native-purchases';
import { A } from '@expo/html-elements';



const Subscriptions = ({}) => {

  const navigation = useNavigation()
  const db = firebase.firestore();
  const { userStore } = useStores()
  const [confirmed,setConfirmed] = useState(false)
  const [products,setProducts] = useState([])
  const [productsIos,setProductsIos] = useState([])
  const [custy,setCusty] = useState({})
  const [connected,setConnected] = useState('')

  useEffect(()=>{
    

  },[])

  //  const listen = async ()=>{
  //   InAppPurchases.setPurchaseListener(async({ responseCode, results, errorCode }) => {
  //       // Purchase was successful
  //       if (responseCode === InAppPurchases.IAPResponseCode.OK) {
  //         results.forEach(async (purchase) => {
  //           if (!purchase.acknowledged) {
  //             console.log(`Successfully purchased ${purchase.productId}`);
  //             // Process transaction here and unlock content...
  //             // Then when you're done
  //             await InAppPurchases.finishTransactionAsync(purchase, false);

  //             if(purchase.productId=='1_year'||purchase.productId=='1_month'||purchase.productId=='6_months'||purchase.productId=='test1'){
  //             // save to database
  //               const check = await checkSubs({packageName:'com.randomfitnessapp',subscriptionId:purchase.productId,token:purchaseToken})
  //               if(check.active!=undefined){
  //                 console.log('check :',check)
  //                 userStore.setSubscriptionActive(check.active)
  //                 userStore.setSubscriptionExpiration(check.expiry)
  //               }else{
  //                 console.log('check undefined : ',check)
  //               }
  //             }
  //           }
  //         });
  //             await InAppPurchases.disconnectAsync()
  //       } else if (responseCode === InAppPurchases.IAPResponseCode.USER_CANCELED) {
  //         console.log('User canceled the transaction');
  //             await InAppPurchases.disconnectAsync()
  //       } else if (responseCode === InAppPurchases.IAPResponseCode.DEFERRED) {
  //         console.log('User does not have permissions to buy but requested parental approval (iOS only)');
  //             await InAppPurchases.disconnectAsync()
  //       } else {
  //         console.log(`Something went wrong with the purchase. Received errorCode ${errorCode}`);
  //             await InAppPurchases.disconnectAsync()
  //       }
  //     });
  // }

  // const purchase = async (productId)=>{
  //   await InAppPurchases.connectAsync()
  //   listen()
  //   const prods = await InAppPurchases.getProductsAsync([productId])
  //   const purchase = await InAppPurchases.purchaseItemAsync(productId)
  //   await InAppPurchases.disconnectAsync()
  // }

  // const appStore = async ()=>{
  //   await InAppPurchases.connectAsync()
  //   console.log("should have connected@@@@@@@@@@@@@@@@@@@@@@@@@@@2")
  //   const prods = await InAppPurchases.getProductsAsync(['1_month','6_months','1_year','test1'])
  //   setProducts(prods.results)
    
  //   const purchaseHistory = await InAppPurchases.getPurchaseHistoryAsync()
  //   console.log(products)
  //   console.log(purchaseHistory)

  //   purchaseHistory.results.forEach(async (purchase)=>{
  //     if(!purchase.acknowledged){

  //       await InAppPurchases.finishTransactionAsync(purchase, false);
  //       userStore.setSubscriptionActive(true)
  //     }
  //   })


  //   if(purchaseHistory.results !=undefined && purchaseHistory.results.length <= 1){
  //     userStore.setSubscriptionActive(false)
  //      // Set purchase listener
  //      // listen()

  //     // // test buy year subscription
  //     // const purchase = await InAppPurchases.purchaseItemAsync('test1')
  //     // console.log(purchase) 
  //   }else{
  //     //unlock stuff 
  //     userStore.setSubscriptionActive(true)


  //   }

  //   await InAppPurchases.disconnectAsync()
  //   console.log("should have disconnected@@@@@@@@@@@@@@@@@@@@@@@@@@@2")
  // }

    const purchase = async (product)=>{
      // console.log('packageId',packageId)
      try {
        const purchaseMade = await Purchases.purchasePackage(product);
        console.log('purchaseMade')
        if (typeof purchaseMade.customerInfo.entitlements.active["pro"] !== "undefined") {
          // Unlock that great "pro" content
          userStore.setSubscriptionActive(purchaseMade.customerInfo.entitlements.active["pro"].isActive)
          userStore.setSubscriptionExpiration(purchaseMade.customerInfo.entitlements.active["pro"].expirationDateMillis)
          await db.collection("users").doc(userStore.getUser()).update({active:purchaseMade.customerInfo.entitlements.active["pro"].isActive,expiry:Number(purchaseMade.customerInfo.entitlements.active["pro"].expirationDateMillis)})
        }
      } catch (e) {
        if (!e.userCancelled) {
          console.log('userCancelled ',e);
        }else{
          console.log('error: ',e);
        }
      }
    }



    const revCat = async ()=>{
    if (Platform.OS === 'ios') {
       // Purchases.configure({apiKey: <public_ios_sdk_key>});
      if(userStore.getUser()!=''){
       await Purchases.configure({apiKey: "appl_ldYQIKKghHygdRrMTEhwZogCITY", appUserID:userStore.getUser()});
      }else{
       await Purchases.configure({apiKey: "appl_ldYQIKKghHygdRrMTEhwZogCITY"});
      }
    } else if (Platform.OS === 'android') {
      if(userStore.getUser()!=''){
       await Purchases.configure({apiKey: "goog_cLWjXElrMXFvnoVQkvVdktHTYDU", appUserID:userStore.getUser()});
      }else{
       await Purchases.configure({apiKey: "goog_cLWjXElrMXFvnoVQkvVdktHTYDU"});
      }
    }

    try {
      const offerings = await Purchases.getOfferings();
      console.log(Platform.OS)
      if (offerings.current !== null) {  
        // Display current offering with offerings.current
        // console.log('offers current ',Platform.OS,offerings.current)

        // console.log('availablePackages ',offerings.current.availablePackages)
        // console.log('introprice ',offerings.current.availablePackages[0].product.introPrice)
        if(Platform.OS=='android'){
          setProducts(offerings.current.availablePackages)
        }else if(Platform.OS=='ios'){
          setProductsIos(offerings.current.availablePackages)
        }
      }
      else{
        console.log('offerings',offerings)
        setConnected('couldn\'t connect 1')

      }
    } catch (e) {
        console.log(e)
        setConnected('couldn\'t connect 2')
    }

    try {
      const customerInfo = await Purchases.getCustomerInfo();
      if(typeof customerInfo.entitlements.active["pro"] !== "undefined") {
        // Grant user "pro" access
        // console.log(customerInfo)
        // console.log(customerInfo.entitlements.active["pro"])
        userStore.setSubscriptionActive(customerInfo.entitlements.active["pro"].isActive)
        userStore.setSubscriptionExpiration(customerInfo.entitlements.active["pro"].expirationDateMillis)
        setCusty(customerInfo)
        // console.log(userStore.getSubscriptionExpiration())
      }else{
        console.log('else ',customerInfo)
      }
    } catch (e) {
     // Error fetching purchaser info
        console.log('err',e)
    }

  }

  useEffect(()=>{
    if(userStore.getUser()!=''){
      revCat()
    }
  },[])



  return (
    <View style={styles.container}>
      <View style={styles.helpContainer}>
      

      
      
      {
        custy.entitlements!=undefined&& custy.entitlements.active.pro!=undefined && custy.entitlements.active.pro.productIdentifier!=undefined&&
          <Text style={{flex:1,color:'#fff',minHeight:25,textAlign:'left',alignSelf:'center',justifyContent:'center'}}>
            Active Subscription
          </Text>
      }
      
      {
        custy.entitlements!=undefined&& custy.entitlements.active.pro!=undefined && custy.entitlements.active.pro.productIdentifier!=undefined&& 
         
          <Text style={{flex:1,color:'#fff',minHeight:25,textAlign:'left',alignSelf:'center',justifyContent:'center'}}>
            {
          custy.entitlements.active.pro.productIdentifier
            }
          </Text>
          
          
          
      }

      {
        custy.entitlements!=undefined&& custy.entitlements.active.pro!=undefined && custy.entitlements.active.pro.expirationDate!=undefined&&
        <Text style={{flex:1,color:'#fff',minHeight:25,textAlign:'left',alignSelf:'center',justifyContent:'center', marginTop:10}}>
          Subscription Expiration Date
        </Text>
      }
      {
        custy.entitlements!=undefined&& custy.entitlements.active.pro!=undefined && custy.entitlements.active.pro.expirationDate!=undefined&&
        <Text style={{flex:1,color:'#fff',minHeight:25,textAlign:'left',alignSelf:'center',justifyContent:'center'}}>
          {
            custy.entitlements.active.pro.expirationDate
          }
        </Text>
      }

      {
        custy.entitlements!=undefined&& custy.entitlements.active.pro!=undefined && custy.entitlements.active.pro.willRenew!=undefined&&
        <Text style={{flex:1,color:'#fff',minHeight:25,textAlign:'left',alignSelf:'center',justifyContent:'center', marginTop:10}}>
          Auto Renew
        </Text>
      }
      {
        custy.entitlements!=undefined&& custy.entitlements.active.pro!=undefined && custy.entitlements.active.pro.willRenew!=undefined&&
        <Text style={{flex:1,color:'#fff',minHeight:25,textAlign:'left',alignSelf:'center',justifyContent:'center'}}>
          {
            custy.entitlements.active.pro.willRenew.toString()
          }
        </Text>
      }
      
      {
        products!=undefined&&products.length>0&&
        <Text style={{flex:1,flexWrap:'wrap',color:'#fff',minHeight:25, marginTop:10}} lightColor={Colors.light.tint}>
            Choose a Subscription
        </Text>
      }
      {
        products!=undefined&&products.length>0?
      products.map((product,index)=>{
        // console.log(product)
        // console.log(product.identifier)
        // console.log(product.product.defaultOption.productId)
        // console.log(product.productId)
        // console.log(product.product.title)
        Object.keys(product.product).forEach((item,index)=>{
          // console.log(item, product.product[item])
        })
        if(custy.entitlements!=undefined&& custy.entitlements.active.pro!=undefined && custy.entitlements.active.pro.productIdentifier!=undefined&&custy.entitlements.active.pro.willRenew){
          if(product.product.defaultOption.productId=='1_year'){
            // console.log('fuck me')
            return(
              <Text>
              </Text>
              )
          }
        }


        return(
          // <TouchableOpacity key={product.title+index} style={{flex:1,flexDirection:'row',alignItems:'center',width:'80%',justifyContent:'center',minHeight:50, padding:10,borderWidth:1,borderType:'solid',borderColor:'white'}} onPress={}>
          <TouchableOpacity key={product.product.title+index} style={{flex:1,flexDirection:'row',alignItems:'center',width:'80%',justifyContent:'center',minHeight:50, padding:10,borderWidth:1,borderType:'solid',borderColor:'white'}} onPress={()=>{purchase(product)}}>
            <Text style={{flex:1,color:'#fff',minHeight:25,textAlign:'left',alignSelf:'center',justifyContent:'center'}} >
              {product.product.title.replace('(Random Fitness App)','')+' '}
            </Text>
            {product.product.defaultOption.freePhase!=null&&
            <Text style={{flex:1,color:'#fff',minHeight:35, textAlign:'center',alignSelf:'center',justifyContent:'center'}} >
              {product.product.defaultOption.freePhase.billingPeriod.value + " "+ product.product.defaultOption.freePhase.billingPeriod.unit+ " "+ product.product.defaultOption.freePhase.offerPaymentMode}
            </Text>
            }
            <Text style={{flex:1,color:'#fff',minHeight:25, textAlign:'right',alignSelf:'center',justifyContent:'center'}} >
              {product.product.priceString}
            </Text>
          </TouchableOpacity>
          )

      
      })
      :productsIos!=undefined&&productsIos.length>0?
      productsIos.map((product,index)=>{
        // console.log(product)
        // console.log(product.identifier)
        // console.log(product.product)
        // console.log(product.productId)
        // console.log(product.product.title)
        Object.keys(product.product).forEach((item,index)=>{
          // console.log(item, product.product[item])
        })
        if(custy.entitlements!=undefined&& custy.entitlements.active.pro!=undefined && custy.entitlements.active.pro.productIdentifier!=undefined&&custy.entitlements.active.pro.willRenew){
          if(product.product.identifier=='1_year'){
            // console.log('fuck me')
            return(
              <Text>
              </Text>
              )
          }
        }


        return(
          <TouchableOpacity key={product.title+index} style={{flex:1,flexDirection:'column',alignItems:'center',width:'90%',justifyContent:'center',minHeight:50, padding:10,borderWidth:1,borderType:'solid',borderColor:'white'}} onPress={()=>{purchase(product)}}>
          <TouchableOpacity style={{flex:1,flexDirection:'row',alignItems:'center',width:'100%',justifyContent:'center',minHeight:25,paddingTop:10}} onPress={()=>{purchase(product)}} >
            <Text style={{flex:1,color:'#fff',minHeight:25,textAlign:'left',alignSelf:'center',justifyContent:'center'}} >
              {product.product.title.replace('(Random Fitness App)','')+' '}
            </Text>
            
            <Text style={{flex:1,color:'#fff',minHeight:25, textAlign:'right',alignSelf:'center',justifyContent:'center'}} >
              {product.product.priceString}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={{flex:1,flexDirection:'row',alignItems:'center',width:'100%',justifyContent:'center',minHeight:25}} onPress={()=>{purchase(product)}}>

          {product.product.introPrice.periodUnit!=null&&
          <Text style={{flex:1,color:'#fff',minHeight:25, textAlign:'center',alignSelf:'center',justifyContent:'center'}} >
            {product.product.introPrice.periodNumberOfUnits + " "+ product.product.introPrice.periodUnit+ " Free trial"}
          </Text>
          }
          </TouchableOpacity>
          </TouchableOpacity>


          )

      
      })
      :
      <Text style={{flex:1,flexWrap:'wrap',color:'#fff',minHeight:25}} >
      {connected==''? 'loading...'  :connected}
      </Text>
      }
      {Platform.OS === 'ios'&&
      <A style={{flex:1,flexWrap:'wrap',color:'#fff',minHeight:25}} href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">Terms of use</A>
      }

      
        
      </View>
      
     {/* <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Rest Inbetween Rounds
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={0} max={120} increment={5} num={roundObjStore.restNum} set={roundObjStore.setRestNum} />
        </View>
      </View>*/}

    </View>
  );
}
export default observer(Subscriptions)
const styles = StyleSheet.create({
  container: {
    flex:1,
    justifyContent:'flex-start',
    width:'100%',
    maxWidth:600,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    flex: 1,
    flexDirection:'column',
    marginTop: 15,
    // marginHorizontal: 20,
    alignItems: 'center',
    width:'100%',
    maxWidth:600,
    // justifyContent:'flex-start'
    color:'white',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    paddingHorizontal: 5,
    paddingTop: 10,
  },
});


