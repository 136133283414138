import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RadioButton } from 'react-native-paper';
import PickNumRounds from './PickNumRounds';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import { FontAwesome } from '@expo/vector-icons';


import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
  import { useStores } from "../models"
  import { observer } from "mobx-react-lite"


const RoundObj = ({ round,setRound }) => {

  const { roundObjStore } = useStores()
  
  const [screen, setScreen] = useState("Set Number of Rounds")
  const [screen1, setScreen1] = useState(0)
  const [screenNum, setScreenNum] = useState(0)
  const screenArray = ["Set Number of Rounds","Set Type for Rounds","Set Time for Rounds","Set Level for Rounds","Set Target for Rounds","Set Equipment for Rounds"]

  function handleIncrement(dir){
      const index = screenArray.indexOf(screen)
    if(dir=="left"){
      setRound((round-1) >= 1 ? (round-1):round)
    }else{
      setRound((round+1) <= (roundObjStore.roundNum) ? round+1:round)
    }
  }
  
  return (

      <View style={styles.container1}>
        <View style={styles.outside}>
          {round>1?
          <TouchableOpacity onPress={()=>{handleIncrement('left')}}>
        <View style={styles.itemContainer1}>
          <FontAwesome
                  name="chevron-left"
                  size={25}
                  color={palette.white}
                  style={{ marginLeft: 20 }}
                />
        </View>
          </TouchableOpacity> 
          :
          <View style={{ marginLeft: 20 }}/>
          }
        </View>
        <View style={styles.itemContainer2}>
          <Text
            style={styles.text}
            >
            {round}
          </Text>
        </View>
        <View style={styles.outside}>
          {round<roundObjStore.roundNum?
          <TouchableOpacity onPress={()=>{handleIncrement('right')}}>
        <View style={styles.itemContainer3}>
          <FontAwesome
                  name="chevron-right"
                  size={25}
                  color={palette.white}
                  style={{ marginRight: 20 }}
                />
        </View>
          </TouchableOpacity>
          :
          <View style={{ marginRight: 20 }}/>
        }
        </View>
      </View>

  );
}

export default observer(RoundObj)

const styles = StyleSheet.create({
  // container: {
  //   flex: 1,
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   // alignSelf: 'stretch',
  //   // maxWidth: 500,
  //   // width: "100%",
  //   // flexDirection: 'row',
  //   color: palette.white,
  // },
  container1: {
    alignSelf: 'stretch',
    // width: "100%",
    paddingHorizontal: 10,
    // paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // marginRight: 10,
    // marginLeft: 10,
    
  },
    outside:{
    flex: 1,
    alignSelf:'stretch',
    justifyContent: 'center',
  },
  itemContainer1: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  itemContainer2: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemContainer3: {
    alignSelf: 'stretch',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },

});
