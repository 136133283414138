import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */

export const DownloadsModel = types
  .model("Downloads")
  .props({
    downloads: types.optional(types.map(types.map(types.string)), {}),
  })
  .views((self) => ({
    getDownloads: ()=>{
      return self.downloads
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setDownloads: (e)=>{
      self.downloads=e
    },
    setDownload: (e)=>{
      const clone = JSON.parse(JSON.stringify(self.downloads))
      // if(clone[e.exercise]!=undefined){
      //   clone[e.exercise]=e
      // }else{
      // }
      if(e.delete){
        delete clone[e.x]
      }else{
        clone[e.x]=e

      }
      self.downloads=clone
      // console.log('fuck ',self, e.gif,e.loc)
    },
}))
type DownloadsType = Instance<typeof DownloadsModel>
export interface Downloads extends DownloadsType {}
type DownloadsSnapshotType = SnapshotOut<typeof DownloadsModel>
export interface DownloadsSnapshot extends DownloadsSnapshotType {}
export const createDownloadsDefaultModel = () => types.optional(DownloadsModel, {})
