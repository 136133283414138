import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */


export const UserModel = types
  .model("User")
  .props({
    user: types.optional(types.string, ""),
    token: types.optional(types.string, ""),
    subscriptionActive: types.optional(types.boolean, false),
    subscriptionExpiration: types.optional(types.integer, 0),
  })
  .views((self) => ({
    getUser: ()=>{
      return self.user
    },
    getToken: ()=>{
      return self.token
    },
    getSubscriptionActive: ()=>{
      return self.subscriptionActive
    },
    getSubscriptionExpiration: ()=>{
      return self.subscriptionExpiration
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setUser: (e)=>{
      self.user=e
    },
    setToken: (e)=>{
      self.token=e
    },
    setSubscriptionActive: (e)=>{
      self.subscriptionActive=e
    },
    setSubscriptionExpiration: (e)=>{
      self.subscriptionExpiration=e
    },
}))
type UserType = Instance<typeof UserModel>
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>
export interface UserSnapshot extends UserSnapshotType {}
export const createUserDefaultModel = () => types.optional(UserModel, {})
