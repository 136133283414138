import { StyleSheet, Button,Linking,ScrollView } from 'react-native';

import Rounds from '../components/Rounds';
import Profile from '../components/Profile';
// import StartWorkoutButton from '../components/StartWorkoutButton';
import { Text, View } from '../components/Themed';
import { palette } from '../constants/Palette';
import { RootTabScreenProps } from '../types';
import { useState, useEffect, useRef } from "react"
import {firebase} from '../components/firebase';
import { useStores } from "../models"
import { RootStackScreenProps } from '../types';





export default function SupportScreen({ navigation }: RootStackScreenProps<'Support'>) {

  // const { roundObjStore, workoutObjStore } = useStores()
  // const [screen,setScreen] = useState(0)
  // const [roundObj, setRoundObj] = useState({})
  

  return (
    <View style={styles.container}>
      {/*<Text style={styles.title}>Directions</Text>*/}
      {/*<Text style={styles.title}>Saved Workouts</Text>*/}
      <ScrollView style={{flex:1}}>
        <Text>Support</Text>
        <Text>If you need technical support please email Brian Gease at briangease7@gmail.com</Text>
  
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: palette.black
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
