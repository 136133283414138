import { Instance, SnapshotOut, types } from "mobx-state-tree"
// import { Round } from "./round"
/**
 * Model description here for TypeScript hints.
 */


  // useEffect(()=>{
  //   const obj={}
  //   for(let i=1;i<=20;i++){
  //     obj[i.toString()] = {
  //       'type':'randomness',
  //       'time': {
  //         'repeat': 3,
  //         'roundTime':30,
  //         'roundSetTime':30,
  //         'roundRestTime':15,
  //       },
  //       'level':'Experienced',
  //       'target':'Full Body',
  //       'equipment':{
  //         'Body Weight':true,
  //         'Trx Straps':false,
  //         'Bosu Ball':false,
  //         'Sandbag':false,
  //         'Pull Up Bar':false,
  //         'Medicine Ball':false,
  //         'Slamable Floor':false,
  //         'Throwable Wall':false,
  //         'Resistance Bands Short':false,
  //         'Resistance Bands Long':false,
          
  //       },
  //     }
  //   }
  //   setRoundObj(obj)
  // },[])

const round = types.model({
  'type':types.optional(types.string,'Randomness'),
  'repeat':types.optional( types.integer,3),
  'roundTime':types.optional(types.integer,10),
  'roundSetTime':types.optional(types.integer,30),
  'roundRestTime':types.optional(types.integer,15),
  'level':types.optional(types.string,'Experienced'),
  'target':types.optional(types.string,'Full Body'),
  'Body Weight':types.optional( types.boolean,true),
  'Trx Straps':types.optional(types.boolean,false),
  'Bosu Ball':types.optional(types.boolean,false),
  'Sandbag':types.optional(types.boolean,false),
  'Pull Up Bar':types.optional(types.boolean,false),
  'Medicine Ball':types.optional(types.boolean,false),
  'Slammable Floor':types.optional(types.boolean,false),
  'Throwable Wall':types.optional(types.boolean,false),
  'Resistance Bands Short':types.optional(types.boolean,false),
  'Resistance Bands Long':types.optional(types.boolean,false),
      
}).views((self) => ({
    gettype: ()=>{
      return self.type
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    settype: (e)=>{
      self.type=e
    },
}))


export const RoundObjModel = types
  .model("RoundObj")
  .props({
    "1": types.optional(round,{'target':'Lower Body'}),
    "2": types.optional(round,{'target':'Core'}),
    "3": types.optional(round,{'target':'Upper Body'}),
    "4": types.optional(round,{}),
    "5": types.optional(round,{}),
    "6": types.optional(round,{}),
    "7": types.optional(round,{}),
    "8": types.optional(round,{}),
    "9": types.optional(round,{}),
    "10": types.optional(round,{}),
    "11": types.optional(round,{}),
    "12": types.optional(round,{}),
    "13": types.optional(round,{}),
    "14": types.optional(round,{}),
    "15": types.optional(round,{}),
    "16": types.optional(round,{}),
    "17": types.optional(round,{}),
    "18": types.optional(round,{}),
    "19": types.optional(round,{}),
    "20": types.optional(round,{}),
    roundNum: types.optional(types.number,3),
    restNum: types.optional(types.number,30),
  })
  .views((self) => ({
    getRoundNum: ()=>{
      return self.roundNum
    },
    getRestNum: ()=>{
      return self.restNum
    },
    getRoundObj: (num)=>{
      return self[num]
    },
    getRoundObjj: ()=>{
      return JSON.parse(JSON.stringify(self))
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setRoundNum: (e)=>{
      self.roundNum=e
    },
    setRestNum: (e)=>{
      self.restNum=e
    },
    setRoundObj: (num,key,e)=>{
      self[num][key]=e
    },
}))
type RoundType = Instance<typeof RoundObjModel>
export interface RoundObj extends RoundType {}
type RoundSnapshotType = SnapshotOut<typeof RoundObjModel>
export interface RoundSnapshot extends RoundSnapshotType {}
export const createRoundDefaultModel = () => types.optional(RoundObjModel, {})

