import { StyleSheet, Button } from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import Rounds from '../components/Rounds';
import Workout from '../components/Workout';
// import StartWorkoutButton from '../components/StartWorkoutButton';
import { Text, View } from '../components/Themed';
import { palette } from '../constants/Palette';
import { RootTabScreenProps } from '../types';
import { useState, useEffect, useRef } from "react"
import {firebase} from '../components/firebase';
import { useStores } from "../models"




export default function WorkoutScreen({ navigation }) {

  const { roundObjStore, workoutObjStore } = useStores()
  const [screen,setScreen] = useState(0)
  // const [roundObj, setRoundObj] = useState({})


  
  const getExercises = ()=>{
    setScreen(prev => prev==0? prev+1:prev-1)
    workoutObjStore.setCurrentRound(1)
    
    const obj = JSON.parse(JSON.stringify(roundObjStore))

    for(let a=obj.roundNum+1;a<=20;a++){
      if(obj.hasOwnProperty(a)) delete obj[a];
    }

    for(let i=1;i<=roundObjStore.roundNum;i++){
      workoutObjStore.setWorkoutObj(i,'currentTarget',0)
      workoutObjStore.setWorkoutObj(i,'currentRepeat', 'type' , 1)
      workoutObjStore.setWorkoutObj(i,'currentRepeat', '1' , roundObjStore[i].repeat)
      workoutObjStore.setWorkoutObj(i,'currentRepeat', '2' , roundObjStore[i].repeat)
      workoutObjStore.setWorkoutObj(i,'currentRepeat', '3' , roundObjStore[i].repeat)
      workoutObjStore.setWorkoutObj(i,'currentTime',roundObjStore[i].roundTime*60)
      workoutObjStore.setWorkoutObj(i,'currentSetTime',roundObjStore[i].roundSetTime)
      workoutObjStore.setWorkoutObj(i,'currentRestTime',roundObjStore[i].roundRestTime)
      
    }
    console.log(JSON.stringify(obj));
  }

  return (
    <View style={styles.container}>
      {/*<Text style={styles.title}>Directions</Text>*/}
      {/*<Text style={styles.title}>Saved Workouts</Text>*/}
      <View style={{flex:1}}>
        <Workout navigation={navigation} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: palette.black
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
