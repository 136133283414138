import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';


import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models";
import { observer } from "mobx-react-lite"
const PickNumRounds = ({}) => {

  const { roundObjStore } = useStores()

  return (
    <View style={styles.container}>
      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Number of Rounds
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={1} max={20} increment={1} num={roundObjStore.roundNum} set={roundObjStore.setRoundNum}  />
          
        </View>
      </View>
      
      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Rest Inbetween Rounds
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={0} max={120} increment={5} num={roundObjStore.restNum} set={roundObjStore.setRestNum} />
        </View>
      </View>

    </View>
  );
}
export default observer(PickNumRounds)
const styles = StyleSheet.create({
  container: {
    flex:0,
    justifyContent:'flex-start'
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    // flex: 0,
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
    // justifyContent:'flex-start'
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    paddingHorizontal: 5,
    paddingTop: 10,
  },
});
