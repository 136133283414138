import React from "react"
import { useState, useEffect, useRef } from "react"
import { ViewStyle,Keyboard,TouchableWithoutFeedback, TouchableOpacity, Dimensions, TextInput, Button } from "react-native"
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { getSnapshot, onSnapshot } from "mobx-state-tree"

import { useStores } from "../models"
import { palette } from "../constants/Palette"
import {firebase} from '../components/firebase';
import { Text, View } from '../components/Themed';
// import * as Network from 'expo-network';





const ROOT: ViewStyle = {
  backgroundColor: palette.black,
  flex: 1,
  flexDirection: "column",
  justifyContent: "center"

}
const ROOT1: ViewStyle = {
  backgroundColor: palette.black,
  // flex: 1,
  // justifyContent: "flex-start",
  
  zIndex:1,

}
const ROOT2: ViewStyle = {
  backgroundColor: palette.black,
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  
  // transform: [{translateY : 5}]

  // height: 
}

const HEADER: ViewStyle = {
  textAlign: "center",
  color: palette.lightBlue,

}
const HEADER1: ViewStyle = {
  textAlign: "center",
  fontSize: 35,
  paddingVertical:20,

}
const BUTTON: TextStyle = {
  fontSize: 20,
}
const BUTTON1: ViewStyle = {
  backgroundColor: palette.lightBlue,
  marginTop: 20,
  paddingVertical: 15,
  width: "95%",
  maxWidth: 400,
  justifyContent: "center",

}
const INPUT: TextStyle = {
  backgroundColor: palette.white,
  width: "95%",
  maxWidth: 400,
  minHeight:25,
  justifyContent: "center",
  // marginTop: 10,
  marginBottom: 10,
  borderRadius: 5,
  borderWidth: 1,
  borderColor: palette.black,
  color:'black',
}
const TEXT: ViewStyle = {
  fontSize: 20,
  color: palette.lightBlue,
  marginTop: 20,
  paddingVertical: 15,
  textAlign: "center",
}


const LoginScreen = observer((props) => {

  // console.log(props)
  const navigation = props.navigation
  // Pull in one of our MST stores
  const { userStore } = useStores()
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(300)
  const [height,setHeight] = useState(300)
  const [username,setUsername] = useState("")
  const [password,setPassword] = useState("")
  const [passwordConfirmation,setPasswordConfirmation] = useState("")
  // const [screen,setScreen] = useState('login')
  const [screen,setScreen] = useState(userStore.getUser() != ''?'logout':'login')


  // const { username, password, setUsername, setPassword } = loginStore
  
  // const auth = getAuth()

  // const { canGoBack, focus } =navStore;
  const isFocused = useIsFocused();
 
  // useEffect(()=>{
  //   if(isFocused){
  //     navStore.setCanGoBack(navigation.canGoBack())
  //     navStore.setFocus('login')
  //   }
  //   // if(userStore.getUser() != ''){
  //   //   const resetAction = CommonActions.reset({
  //   //       index: 0,
  //   //       routes: [{
  //   //           name: "home"
  //   //         }]
  //   //     });
  //   //     navigation.dispatch(resetAction);
  //   // }
  // },[isFocused])

  // useEffect(()=>{
  //   netState()
  // },[])
  // const netState = async()=>{
  //   try{
  //     const networkState = await Network.getNetworkStateAsync();
  //     console.log(networkState)
  //   }
  //   catch(err){
  //     console.log(err)
  //   }

  // }

  onSnapshot(userStore, (newSnapshot) => {
    // console.info("Got new snapshot:", newSnapshot)
    setScreen(userStore.getUser() != ''?'logout':'login')
  })

  const handleLogin = () => {
    // alert('signing in '+username+' '+password)

    // need spinning circle
    // console.log(username,password)

    firebase.auth().signInWithEmailAndPassword(username.trim(), password)
      .then((something) => {
        console.log(something)
        // setShowLogin(false)
        // alert('signed in')
        navigation.goBack()
      })
      .catch((error) => {
        alert(error.message)
        console.log(error)
      });
  };
  
  const handleLogout = () => {
    // alert('signing in '+username+' '+password)

    // need spinning circle

    firebase.auth().signOut()
      .then(() => {
        // setShowLogin(false)
        // alert('signed in')
        setScreen('login')
      })
      .catch((error) => {
        console.log(error)
        alert(error)
      });
  };

  const handleReset = ()=>{
    if(username!=''){

      firebase.auth().sendPasswordResetEmail(username.trim()).then(() => {
        console.log('email sent!');
        alert('Reset password email sent to :'+username)
      }).catch(function(error) {
        // An error happened.
        console.log(error)
      });
    }else{
      alert('enter vaild email')
    }
  }

  const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  const handleSignup = () => {
    // alert('signing up'+username+' '+password+' '+passwordConfirmation)

    if(validateEmail(username.trim())){

      if(password == passwordConfirmation){
        if(password.length >=8){


        firebase.auth().createUserWithEmailAndPassword(username.trim(), password.trim())
          .then((cred) => {
            // setShowLogin(false)
            const user1 = cred.user
            const uid = user1.uid;
            const userRef = firebase.firestore().collection("users").doc(uid);
              userRef.set({
                email: username.trim(),
                token: "",
              } , { merge: true })
            // userStore.setUser(uid);
            setUsername('')
            setPassword('')
            setPasswordConfirmation('')
            navigation.goBack()

            alert('signed up')

          })
          .catch((error) => {
            alert(error)
          });
        }else{
          alert("Password must be longer then or equal to 8 characters")

        }
      } else{
        alert("Password must match password confirmation")

      }
    } else{
      alert("Email not formated correctly")
    }
  };

  useEffect(()=>{
    const windowWidth = Dimensions.get('window').width;
    if((windowWidth*.75)<=300){
     setWidth(windowWidth*.75)
    }
    const windowHeight = Dimensions.get('window').height;
    if((windowHeight*.75)<=300){
     setHeight(windowHeight*.75)
    }
    
  })

  return (

      <View style={ROOT}>
        { screen == 'login' ?
      <View style={ROOT2}>

        <Text style={HEADER} >{"Log In"}</Text>
        <TextInput placeholder="  email" style={INPUT} label="Email" onChangeText={(e)=>{setUsername(e)}}/>
        <TextInput placeholder="  password" style={INPUT} label="Password" secureTextEntry={true} onChangeText={(e)=>{setPassword(e)}}/>
        <Button title="Submit" style={BUTTON1} textStyle={BUTTON} onPress={handleLogin}/>
        <Text style={TEXT} onPress={()=>{setScreen('signup')}}>{"Create an Account?"}</Text>
        <Text style={TEXT} onPress={()=>{handleReset()}}>{"Forgot Password?"}</Text>
      </View>
         : screen == 'logout' ?
      <View style={ROOT2}>
        <Text style={HEADER} >{"Log Out"}</Text>
        <Text style={TEXT}>{"Are you sure you want to Log Out?"}</Text>
        <Button title="Log Out" style={BUTTON1} textStyle={BUTTON} onPress={handleLogout}/>
      </View>
        : 
      <View style={ROOT2}>
        <Text style={HEADER} >{"Sign Up"}</Text>
        <TextInput placeholder="  email" style={INPUT} label="Email" onChangeText={(e)=>{setUsername(e)}}/>
        <TextInput placeholder="  password" style={INPUT} label="Password" secureTextEntry={true} onChangeText={(e)=>{setPassword(e)}}/>
        <TextInput placeholder="  password confirmation" style={INPUT} label="Password Confirmation" secureTextEntry={true} onChangeText={(e)=>{setPasswordConfirmation(e)}}/>
        <Button title="Submit" style={BUTTON1} textStyle={BUTTON} onPress={handleSignup}/>
        <Text style={TEXT} onPress={()=>{setScreen('login')}}>{"Log in with Existing Account?"}</Text>
         
      
      </View>
       }
      </View>

  )
})

export default LoginScreen;