import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Dimensions,Platform } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { useState, useEffect, useRef,useIsMounted } from "react"
import { FontAwesome,Ionicons } from '@expo/vector-icons';

import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models"
import { observer } from "mobx-react-lite"
import {firebase} from '../components/firebase';
import * as FileSystem from 'expo-file-system';




// somewhere in this page is causing the 


const Audios = ({ soundObj,volume,setVolume,doubleDone,setDoubleDone,timerVolume,leftRight,setLeftRight,switchRound })=> {
  let Audio = require('expo-av').Audio;

  const downloadsDir = FileSystem.cacheDirectory + 'downloads/';
  const { roundObjStore, workoutObjStore } = useStores()
  const storage = firebase.storage();
  const storageRef = storage.ref();

  const [os,setOs] = useState(Platform.OS)
  const status = useRef(null)
  // const [status,setStatus] = useState(null)
  const [url,setUrl] = useState(null)
  const path = useRef("")
  const [show,setShow] = useState(true)
  const [show1,setShow1] = useState(false)

  const status1 = useRef(null)
  const [url1,setUrl1] = useState(null)
  const path1 = useRef("")
  const last = useRef("")

  const [sound,setSound] = useState({path:'',sound:new Audio.Sound()});
  const [sound1,setSound1] = useState({path:'',sound:new Audio.Sound()});

  // const [soundObj,seSoundObj] = useState({})
  // const [playing,setPlaying] = useState({})
  const prevVol = useRef(false)
  const prevLeftRight = useRef(false)
  
  const soundObjCount = useRef(0)
  const curr = useRef(null)
  const next = useRef(null)
  const [whichVid,setWhichVid] = useState(0)
  

  useEffect(()=>{
    if(switchRound){
      unload()
    }
  },[switchRound])

  const unload = async()=>{
    try{
      for( let sound in soundObj.current ){

        if (soundObj.current.hasOwnProperty(sound) && sound!='status' && sound!=next.current&&sound!=curr.current) {
          // console.log(sound)
          await soundObj.current[sound].sound.unloadAsync()
          delete soundObj.current[sound]
        }
      }
      soundObj.current={}
    } catch(err){
      console.log(err)
    }
  }

  const switchPath = (path)=>{

    let path1 = path.replace('Body Weight','BodyWeight')
    path1 = path1.replace('Resistance Bands Short','ResistanceBandsShort')
    path1 = path1.replace('Resistance Bands Long','ResistanceBandsLong')
    path1 = path1.replace('Bosu Ball','BosuBall')
    path1 = path1.replace('Trx Straps','TrxStraps')
    path1 = path1.replace('Medicine Ball','MedicineBall')
    path1 = path1.replace('Pull Up Bar','PullUpBar')
    path1 = path1.replace(/ /g,':')
    path1+='.mp3';
    // console.log(path1)
    return path1;
    }
   
  const showHideVid = function(setShow, setShow1){
    setShow(true)
    setShow1(false)
  }

  const getAudio = async function(path,sound,status){
    // unload()
    sound.path = path
    if(path!='a'){

      let fileInfo = {exists:false};
      if(os!='web'){
        fileInfo = await FileSystem.getInfoAsync(downloadsDir+audioFile1);
      }
      let audioFile1 = path.replace(/:/g,'_')

      console.log(fileInfo)
      
      if(fileInfo.exists && fileInfo.uri!=undefined){
            console.log('downloaded audio exists')
          // if(soundObj.current[path] == undefined){
          //   console.log('soundObj.current[path] == undefined')
          //   soundObj.current[path] = {}
          //   // soundObj.current[path].sound = await new Audio.Sound()
          //   soundObj.current[path].played = false
          //   await sound.loadAsync({uri:fileInfo.uri})
          //   await sound.setOnPlaybackStatusUpdate(async(stat)=>{try{status.current=await stat}catch(err){console.log(err)}})
          // }else {
            // if(soundObj.current[path].sound != undefined){
            //   await soundObj.current[path].sound.unloadAsync()
            // }
            if(sound.sound._loaded){
              await sound.sound.unloadAsync()
            }
            console.log('soundObj.current[path] != undefined')
            // soundObj.current[path].sound = await new Audio.Sound()
            await sound.sound.loadAsync({uri:fileInfo.uri})
            await sound.sound.setOnPlaybackStatusUpdate(async(stat)=>{try{status.current=await stat}catch(err){console.log(err)}})
          // }
      }else{
        console.log('downloaded audio does not exists')
        const ref = storageRef.child('audio/'+path);
        // console.log(path+'2')

        try {
        
          const url1 = await ref.getDownloadURL();
        // await ref.getDownloadURL().then(async function(url1) {
        
          // if(soundObj.current[path] == undefined){
          //   console.log('soundObj.current[path] == undefined')
          //   soundObj.current[path] = {}
          //   // soundObj.current[path].sound = await new Audio.Sound()
          //   soundObj.current[path].played = false
          //   await sound.loadAsync({uri:url1})
          //   await sound.setOnPlaybackStatusUpdate(async(stat)=>{try{status.current=await stat}catch(err){console.log(err)}})
          // }else {
            // if(soundObj.current[path].sound != undefined){
            //   await soundObj.current[path].sound.unloadAsync()
            // }
            if(sound.sound._loaded){
              await sound.sound.unloadAsync()
            }
            console.log('soundObj.current[path] != undefined')
            // soundObj.current[path].sound = await new Audio.Sound()
            await sound.sound.loadAsync({uri:url1})
            await sound.sound.setOnPlaybackStatusUpdate(async(stat)=>{try{status.current=await stat}catch(err){console.log(err)}})
          // }

          if(os!='web'){
            await FileSystem.downloadAsync(url1, (downloadsDir+audioFile1))
          }

        }
        catch(error) {
          // $("#details0").html(error)
          console.log(error);
          // alert(error)

        };
      }
    }
  }

  // useEffect(()=>{
  //   return ()=>{
  //     // console.log(soundObj.current)
  //     try{
  //       for( let sound in soundObj.current ){
  //         if (soundObj.current.hasOwnProperty(sound)) {
  //           // console.log(sound)
  //           soundObj.current[sound].sound.unloadAsync()
  //         }
  //       }
  //     } catch(err){
  //       console.log(err)
  //     }
  //     // console.log(soundObj.current)
  //   } 
  // },[soundObj.current])

const changeAudio =async (sound,sound1,status,status1)=>{
      // console.log("changeAudio^^^^^^^^^^^")
      console.log('audio things',(timerVolume && doubleDone &&volume))
      // console.log((!timerVolume && volume))
      // console.log((!prevVol.current&&volume))
      // console.log((timerVolume && leftRight &&volume))
      if((timerVolume && doubleDone &&volume) || (!timerVolume && volume) || (!prevVol.current&&volume) || (timerVolume && leftRight &&volume) ){
        setDoubleDone(false)
      // console.log(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].audio
        let target,type1, currXInType;
        if(workoutObjStore[workoutObjStore.currentRound].full){
          target = workoutObjStore[workoutObjStore.currentRound].currentTarget!=2? workoutObjStore[workoutObjStore.currentRound].currentTarget+1 : 0;
          const targetName = workoutObjStore[workoutObjStore.currentRound].targets[target];
          const targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
          const typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length
          currXInType = targetObj[targetObj.type]
          type1 = targetObj.type;
        }else {
          target = 0;
          const targetName = workoutObjStore[workoutObjStore.currentRound].targets[target];
          const targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
          const typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length
          
          currXInType = targetObj[targetObj.type]
          type1 = targetName=='booty'? targetObj.type+1>2?1:targetObj.type+1 :targetObj.type+1>3?1:targetObj.type+1;
        }
        curr.current = workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].audio
        next.current = workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType].audio
          // console.log(soundObj.current)
          // console.log(curr)
          // console.log(soundObj.current[curr])
          if(sound.path!=curr.current){
            await getAudio(curr.current,sound,status)
          }
          // console.log(soundObj.current[curr])
          // setWhichVid(whichVid=>whichVid==0?1:0)
          // console.log(sound1,sound1._loaded )
          // console.log(last.current)
          // console.log(soundObj.current[last.current])
          // if(last.current!="" && soundObj.current[last.current]!=undefined){
          // }
            const statussss = sound.sound.getStatusAsync()
              if(statussss.isLoaded&& statussss.isPlaying){
                await sound.sound.stopAsync();
                // await soundObj.current[last.current].sound.setPositionAsync(0);
                // await sound.unloadAsync();
              }
          // console.log(curr)
          // console.log(soundObj.current[curr])
          // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!")
          // if(soundObj.current[curr.current]!=undefined){
          // console.log("curr status *****************: ",soundObj.current[curr].status)
            // const stat = await soundObj.current[curr].sound.getStatusAsync()
            try{

            
            // if(soundObj.current.hasOwnProperty('sound')){
              //   const statiusMaximus = await soundObj.current.sound.getStatusAsync()
              //   // console.log("sound status *********: ",statiusMaximus)
              //   if(statiusMaximus.isPlaying){
              //     await soundObj.current.sound.stopAsync()
              //   }
              // }
              
              console.log('soundObj : ',sound)
              sound.sound.playFromPositionAsync(0)



            } catch(error){
              console.log('audios err : ',error)
              // console.log('soundObj : ',soundObj.current[curr.current])
              Audio = require('expo-av').Audio;

            }
          // } else {
          //   console.log("$$$$$$$$$$$$$$$$$$$$$$$$$")
          // }
        // if(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType]!=undefined&&soundObj.current[workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[target]][type1][currXInType].audio]==undefined){

        // }
          await getAudio(next.current,sound1,status1)

      last.current = curr.current
    }
    prevVol.current=volume
    if(leftRight){
      setLeftRight(false)
    }
  }

  useEffect(()=>{
    if(workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]]!=undefined){
      setTimeout(function () { 
        if(sound.path==workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]].audio){
          console.log('sound $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$')
          changeAudio(sound,sound1,status,status1)  
        }else{
          console.log('sound1 $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$')
          changeAudio(sound1,sound,status1,status)  
        }
      }, 250);
    }
  },[doubleDone, volume, workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'][workoutObjStore[workoutObjStore.currentRound][workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget]+'Curr'].type]]])
    
  return (
    <View style={styles.container}>
    </View>
  );
}

export default observer(Audios)


const styles = StyleSheet.create({
  container: {
    maxWidth:500,
    color: palette.white,
  },
  
});
