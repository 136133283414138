import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView, Platform } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"



import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models"
import { observer } from "mobx-react-lite"

const PickLevelRounds = ({ round})=> {

  const { roundObjStore,userStore } = useStores()
  const [platform,setPlatform] = useState(Platform.OS)
  const [enabled,setEnabled] = useState(userStore.getSubscriptionActive())
  const isFocused = useIsFocused();

  useEffect(()=>{
    setEnabled(userStore.getSubscriptionActive())
  },[isFocused])

  const switchSelected = (type)=>{
    switch(type){
      case "Beginner": return 0;
        break;
      case "Athlete": return 2;
        break;
      default : return 1;
    }
  }

  const [selectedIndex, setSelectedIndex] = useState(switchSelected(roundObjStore[round]['level']))
  
  // const typeArray = userStore.getSubscriptionActive()? ['Beginner','Experienced','Athlete']:['Beginner']
  const typeArray = ['Beginner','Experienced','Athlete']

  useEffect(()=>{
    // console.log(userStore.getSubscriptionActive())
       roundObjStore.setRoundObj(round,"level",typeArray[selectedIndex])
  },[selectedIndex])

  useEffect(()=>{
    setSelectedIndex(switchSelected(roundObjStore[round]['level']))
  },[round])

  return (
    <View style={styles.container}>
      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Level of Round {round}
        </Text>
      </View>
      <View style={styles.numbers}>
      <SegmentedControl
          values={typeArray}
          selectedIndex={selectedIndex}
          onChange={(event) => {
            if(userStore.getSubscriptionActive()){
              setSelectedIndex(event.nativeEvent.selectedSegmentIndex);
            }
          }}
          tintColor={palette.lightGrey}
          appearance={'dark'}
          enabled={enabled}
        />
      </View>
      {/*<View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          {roundObjStore[round]['level']}
        </Text>
        
      </View>*/}
      {
        !enabled&&
      <Text style={{marginTop:25, minHeight:25, color:'#fff'}}>Unlock All Levels with a Pro Subscription</Text>
      }
      {
        !enabled&&
      <Text style={{marginTop:25, minHeight:25, color:'#fff'}}>Try Pro with a 30 day free trail</Text>      
      }
      {
        !enabled&&
      <Text style={{marginTop:25, minHeight:25, color:'#fff'}}>Find Subscriptions in your Profile Page</Text>      
      }
      {
        platform=='web'&&
        <Text style={{marginTop:25, minHeight:25, color:'#fff'}}>Currently, must purchase subscription from Mobile App</Text>      

      }


    </View>
  );
}

export default observer(PickLevelRounds)

const styles = StyleSheet.create({
  container: {
    alignSelf:'stretch',
    // flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    // flexDirection:'row',
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    // flex:1,
    marginBottom: 20,
    marginHorizontal: 20,
    alignItems: 'center',

  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    // flex:1,
    alignSelf:"stretch",
    // flex: 1,
    // flexDirection:'row',
    marginTop: 5,
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
  },
});
