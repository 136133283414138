import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Text } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import {firebase} from './firebase';



import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { View } from './Themed';
import { useStores } from "../models";
import { observer } from "mobx-react-lite"
const BlockedXs = ({}) => {

  const db = firebase.firestore();
  const { userStore } = useStores()
  const blockedDoc = userStore.getUser()==''?'needs to login' : db
    .collection("users").doc(userStore.getUser())
    .collection("blockedXs").doc('blockedX');
 
  const [blocked,setBlocked] = useState([])

  useEffect(()=>{
    if(userStore.getUser()!=''){
      blockedDoc.onSnapshot((doc) => {
        console.log("blocked data: ", doc.data());
        const data = doc.data();
        if(data!=undefined&&data.blocked!=undefined){
        console.log('doc data1');
          setBlocked(data.blocked);
        }else{
        console.log('doc data2');
          setBlocked([])
        }

      })
    }

  },[])

  const restore = (x)=>{
    if(userStore.getUser()!=''){
      var xer1="";
      
      blocked.splice(x,1);
      
      blockedDoc.update({
        blocked: blocked
      }).then(function(doc) {
        blockedDoc.get().then(function(doc) {
          console.log('doc data');
          console.log(doc.data());
          const data = doc.data();
          if(data!=undefined&&data.blocked!=undefined){
            setBlocked(data.blocked);
          }else{
            setBlocked([])
          }
        }, 1000);
      }).catch(function(error) {
          console.log("Error getting documents: ", error);
      });
    } 
  }

  useEffect(()=>{
    console.log(blocked)
  },[blocked])

  return (
    <View style={styles.container}>
      <View style={styles.helpContainer}>
      {
        blocked.length<1?
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          None
        </Text>
        :
        blocked.map((item,index)=>{

          return(
            <View key={item+""+index} style={{flex:1, flexWrap:'wrap', flexDirection:'row',width:'100%',minHeight:45,maxWidth:600, padding:20,color:'white'}}>
              <Text style={{flex:5,flexWrap:'wrap',color:'white'}}>
              {item}
              </Text>
              <TouchableOpacity style={{flex:1,alignItems:'center',justifyContent:'center'}} onPress={()=>{restore(index)}}>
                <Text style={{flex:1,color:'white',borderWidth:1,borderType:'solid',borderColor:'white',textAlign:'center',padding:2,minHeight:25}} >
                  X
                </Text>
              </TouchableOpacity>
            </View>
            
          )

        })

      }
        
      </View>
      
     {/* <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Rest Inbetween Rounds
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={0} max={120} increment={5} num={roundObjStore.restNum} set={roundObjStore.setRestNum} />
        </View>
      </View>*/}

    </View>
  );
}
export default observer(BlockedXs)
const styles = StyleSheet.create({
  container: {
    flex:1,
    justifyContent:'flex-start',
    width:'100%',
    maxWidth:600,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    flex: 1,
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
    width:'100%',
    maxWidth:600,
    // justifyContent:'flex-start'
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    paddingHorizontal: 5,
    paddingTop: 10,
  },
});
