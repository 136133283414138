import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import SegmentedControl from '@react-native-segmented-control/segmented-control';



import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
  import { useStores } from "../models"
  import { observer } from "mobx-react-lite"

const PickTypeRounds = ({ round }) => {

  const switchSelected = (type)=>{
    switch(type){
      case "Circut": return 1;
        break;
      default : return 0;
    }
  }
  const { roundObjStore,workoutObjStore, } = useStores()

  const [selectedIndex, setSelectedIndex] = useState(switchSelected(roundObjStore[round]['type']))
  const typeArray = ['Randomness', 'Circut']
  useEffect(()=>{
    roundObjStore.setRoundObj(round,'type',typeArray[selectedIndex])
    workoutObjStore.setWorkoutObj(round,'type',typeArray[selectedIndex].toLowerCase())

  },[selectedIndex])

  useEffect(()=>{
    setSelectedIndex(switchSelected(roundObjStore[round]['type']))
  },[round])

  return (
    <View style={styles.container}>
      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Round {round} Type
        </Text>
      </View>
      <View style={styles.numbers}>
      <SegmentedControl
          values={typeArray}
          selectedIndex={selectedIndex}
          onChange={(event) => {
            setSelectedIndex(event.nativeEvent.selectedSegmentIndex);
          }}
          tintColor={palette.lightGrey}
          appearance={'dark'}
        />
      </View>
      {/*<View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          {roundObjStore[round]['type']}
        </Text>
        
      </View>*/}

    </View>
  );
}

export default observer(PickTypeRounds)

const styles = StyleSheet.create({
  container: {
    alignSelf:'stretch',
    // flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    // flexDirection:'row',
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    // flex:1,
    marginBottom: 20,
    marginHorizontal: 20,
    alignItems: 'center',

  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    // flex:1,
    alignSelf:"stretch",
    // flex: 1,
    // flexDirection:'row',
    marginTop: 5,
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
  },
});
