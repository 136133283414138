import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, Dimensions, Animated } from 'react-native';
import React from "react"
import { useState, useEffect, useRef } from "react"
import { FontAwesome } from '@expo/vector-icons';
// import { GestureHandler } from 'expo';
// const { Swipeable } = GestureHandler;
import {
  GestureHandlerRootView,
  PanGestureHandler,
} from 'react-native-gesture-handler';

import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
  import { useStores } from "../models";
  import { observer } from "mobx-react-lite"
  import { roundObjStore } from "../models/roundObj/roundObj"


const NumberScroll = observer(function NumberScroll({ min, max, increment, num, set }) {
  const { roundObjStore } = useStores()
  const [numArray, setNumArray]=useState([])
  useEffect(()=>{
    const array=[]
    if(min>=0&&max<=300){
      for(let a=min;a<=max;a+=increment){
        array.push({val:a})
      }
    }
    setNumArray(array)
  },[min,max])

  function handleGetValue(val) {
    console.log('val - ',val)
  }
  function handleIncrement(inc) {
    if(inc=='add'){
      if(num+increment<=max){
        set(num+increment)
      }
    }else if(num-increment>=min){
      set(num-increment)
    }
  }

  // const {width} = Dimensions.get('window');

  return (
    <View horizontal style={styles.container}>
      <TouchableOpacity onPress={()=>{handleIncrement('sub')}}>
        <View style={styles.itemContainer1}>
        <FontAwesome
                name="chevron-circle-left"
                size={25}
                color={palette.black}
                style={{ marginRight: 20 }}
              />
        </View>
      </TouchableOpacity>
      <View style={styles.itemContainer}>
        <View style={styles.itemContainer} >
          <Text style={styles.getStartedText}>{num}</Text>
        </View>        
      </View>
      <TouchableOpacity onPress={()=>{handleIncrement('add')}}>
        <View style={styles.itemContainer1}>
          <FontAwesome
            name="chevron-circle-right"
            size={25}
            color={palette.black}
            style={{ marginLeft: 20 }}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
})

export default NumberScroll

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: palette.white,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: palette.white,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  itemContainer: {
    // flex: 1,
    // backgroundColor: palette.darkgrey
    backgroundColor: palette.white,
    // alignItems: 'center',
    // marginHorizontal: 50,
    alignItems: 'center',
    justifyContent: 'center',
    // maxWidth: 40,
    // width: width,
    zIndex:0,
  },
  itemContainer1: {
    // flex: 1,
    backgroundColor: palette.white,
    alignItems: 'center',
    // marginHorizontal: 50,
    justifyContent: 'center',
    // maxWidth: 40,
    // width: width*.3,
    zIndex:99,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
    color: palette.black
  },
  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
  },
});
