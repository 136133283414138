/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  // prefixes: [],
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          // TabOne: {
          //   screens: {
          //     TabOneScreen: 'Home',
          //   },
          // },
          Home:'',
          Login:'Login',
          Profile: 'Profile',
          Parameters: 'Parameters',
          Workout: 'Workout',
          Quotes: 'Quotes',
          Downloads: 'Downloads',
          
        },

      },    
        
      
      Waiver: 'Waiver',
      PrivacyPolicy: 'PrivacyPolicy',
      Support: 'Support',
      DeleteAccount: 'DeleteAccount',
      Modal: 'modal',
      Home:'Home',
      NotFound: '*',
    },
  },
};

export default linking;
