import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';


import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models"
import { observer } from "mobx-react-lite"

export default function PickTimeRounds({ round }) {

  const { roundObjStore,workoutObjStore } = useStores()

  const [roundTime, setRoundTime] = useState(roundObjStore[round]['roundTime'])
  const [roundSetTime, setRoundSetTime] = useState(roundObjStore[round]['roundSetTime'])
  const [roundRestTime, setRoundRestTime] = useState(roundObjStore[round]['roundRestTime'])
  const [roundRepeat, setRoundRepeat] = useState(roundObjStore[round]['repeat'])

  useEffect(()=>{
    roundObjStore.setRoundObj(round,'roundTime',roundTime)
    workoutObjStore.setWorkoutObj(round,'roundTime',roundTime*60)
    workoutObjStore.setWorkoutObj(round,'currentTime',roundTime*60)
  },[roundTime])
  useEffect(()=>{
    roundObjStore.setRoundObj(round,'repeat',roundRepeat)
    workoutObjStore.setWorkoutObj(round,'repeat',roundRepeat)
    workoutObjStore.setWorkoutObj(round,'currentRepeat','type',1)
    workoutObjStore.setWorkoutObj(round,'currentRepeat','1',roundRepeat)
    workoutObjStore.setWorkoutObj(round,'currentRepeat','2',roundRepeat)
    workoutObjStore.setWorkoutObj(round,'currentRepeat','3',roundRepeat)
  },[roundRepeat])
  useEffect(()=>{
    workoutObjStore.setWorkoutObj(round,'roundSetTime',roundSetTime)
    workoutObjStore.setWorkoutObj(round,'currentSetTime',roundSetTime)
    roundObjStore.setRoundObj(round,'roundSetTime',roundSetTime)
  },[roundSetTime])
  useEffect(()=>{
    workoutObjStore.setWorkoutObj(round,'roundRestTime',roundRestTime)
    workoutObjStore.setWorkoutObj(round,'currentRestTime',roundRestTime)
    roundObjStore.setRoundObj(round,'roundRestTime',roundRestTime)
  },[roundRestTime])

  useEffect(()=>{
    setRoundTime(roundObjStore[round]['roundTime'])
    setRoundSetTime(roundObjStore[round]['roundSetTime'])
    setRoundRestTime(roundObjStore[round]['roundRestTime'])
    setRoundRepeat(roundObjStore[round]['repeat'])

  },[round])



  return (
    <View style={styles.container}>
      {roundObjStore[round].type == 'Circut'?

      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Repeat Sets for Round {round}
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={3} max={5} num={roundRepeat} increment={1} set={setRoundRepeat}  />
        </View>
      </View>
      :
      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Time of Round {round}
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={1} max={60} num={roundTime} increment={1} set={setRoundTime}  />
        </View>
      </View>
      
      }
      
      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Time of Each Set for Round {round}
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={5} max={120} num={roundSetTime} increment={5} set={setRoundSetTime} pauseStart={false} />
        </View>
      </View>

      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Rest Time Inbetween Each Set for Round {round}
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={5} max={120} num={roundRestTime} increment={5} set={setRoundRestTime} pauseStart={false} />
        </View>
      </View>

      {/*<View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          {roundObjStore[round]['roundTime']}
        </Text>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          {roundObjStore[round]['roundSetTime']}
        </Text>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          {roundObjStore[round]['roundRestTime']}
        </Text>
        
      </View>*/}

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex:0,
    justifyContent:'flex-start'
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    // flex: 0,
    // flex: 1,
    marginBottom: 20,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    // flex: 1,
    paddingTop: 10,
    paddingHorizontal: 25,
  },
});
