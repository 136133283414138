  import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Modal,Pressable,Text } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef} from "react"
import React from 'react';
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import CalendarPicker from 'react-native-calendar-picker';
import {firebase} from './firebase';
import moment from 'moment';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { Image } from 'expo-image';
import {Helmet} from "react-helmet";
import { H1,A } from '@expo/html-elements';

import { useIsFocused } from '@react-navigation/native';


import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { View } from './Themed';
import { useStores } from "../models";
import { observer } from "mobx-react-lite"
import { quotes } from '../utils/quotes'

// const Helmt = ()=>{
//   return (
//     <Helmet>
//         <meta charSet="utf-8" />
//         <title>Fart Test</title>
//         <meta name="description" content="fart test desc" />
//     </Helmet>
//     )
// }

const Quotes = ({table}) => {
  const isFocused = useIsFocused();
  const [timer,setTimer] = useState()
  const { userStore } = useStores()
  const [startDate,setStartDate] = useState()
  const [dayArray,setDayArray] = useState({})
  const [customDatesStyles,setCustomDatesStyles] = useState([])
  const [modalVisible, setModalVisible] = useState(false);
  const [quotes1, setQuotes1] = useState([]);
  const [quotes2, setQuotes2] = useState([]);
  const [fontSize, setFontSize] = useState(50);
  const [doTheThing, setDoTheThing] = useState(true);
  const [handleScroll, setHandleScroll] = useState();
  const ref = useRef()
  const scrollRef = useRef()

  const db = firebase.firestore();
  const storage = firebase.storage();
  const storageRef = storage.ref();

  const content = 'January February March April May June July August September October November December'.split(' ');
  const weekDayName = 'SUN MON TUES WED THURS FRI'.split(' ');
  const daysOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const d = new Date();



  useEffect(()=>{
    // console.log(ref.current)
    // ref.current={}
    // // console.log(ref.current)
    // for(let x=0;x<367;x++){
    //   // const ref1 = useRef()
    //   ref.current[String(x)]=React.createRef()
    // // console.log(ref.current)
    // }
    getQuotes()
  },[]) 

  useEffect(()=>{
    setTimer(false)
    const timer = setTimeout(() => {
       setTimer(true)
     }, 250);
     return () => clearTimeout(timer);

  },[isFocused])
  // useEffect(()=>{


  //   if(handleScroll!='null'){
  //     // console.log(handleScroll)
  //     if(ref.current!='null'&&ref.current!=undefined&&ref.current['365'].current!='null'&&ref.current['365'].current!=undefined&&doTheThing){
  //       // console.log(ref.current)
  //       for(let x1=0;x1<366;x1++){
  //       // console.log(x1+"  num")

  //         if(ref['current'][String(x1)]['current'].offsetParent.offsetTop-1400<handleScroll&&ref['current'][String(x1)]['current'].offsetParent.offsetTop+1400>handleScroll){
  //           resize(x1)
  //         }
          
  //       }
  //       setDoTheThing(false)
  //       // console.log(ref.current)

  //     }
  //   }

  // },[handleScroll])
  
  const getQuotes = async ()=>{
    const q = await quotes()
    setQuotes1(q)
    
  }

  var reword = function(quote){
    quote = quote.replace("'",'');
    var split = quote.split("' - ");
    return split;
  }

  // useEffect(()=>{
  //   if(ref.current!='null'&&ref.current!=undefined&&ref.current['365'].current!='null'&&ref.current['365'].current!=undefined&&doTheThing){
  //   // console.log(ref.current)
      
  //   // for(let x1=0;x1<366;x1++){
  //   // // console.log(x1+"  num")

  //   //   // if(ref['current'][String(x1)]['current'].offsetParent.offsetTop){
  //   //     resize(x1)
  //   //   // }
      
  //   // }
  //     setDoTheThing(false)
  //   // console.log(ref.current)

  //   }
  // },[ref.current])

  useEffect(()=>{
    if(scrollRef.current!='null'&&scrollRef.current!=undefined){
      var now = new Date();
      var start = new Date(now.getFullYear(), 0, 0);
      var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay)-1;
      console.log('Day of year: ' + day);

      scrollRef.current.scrollTo({x: 0, y:(day*400), animated: true})
      setDoTheThing(false)
    }
  },[scrollRef.current])

  //    useEffect(()=>{
  //   console.log(JSON.stringify(quotes2))

  // },[quotes2]) 

  const resize = async (x1)=>{

        var x=0;
        var newFontSize;
        // console.log(ref['current'][String(x1)]['current'])
          ref['current'][String(x1)]['current'].setNativeProps({style : {fontSize : (70+'px'),overflowY:'auto'}});
        while(((ref['current'][String(x1)]['current'].scrollWidth > ref['current'][String(x1)]['current'].offsetWidth) && x<20) || ((ref['current'][String(x1)]['current'].scrollHeight > ref['current'][String(x1)]['current'].offsetHeight) && x<20)) {
          // var newFontSize = (parseFloat($(ref['current'][String(x1)]['current']).css('font-size').slice(0, -2)) * 0.95) + 'px';
        
        // console.log(ref['current'][String(x1)]['current'].scrollWidth)
        // console.log(ref['current'][String(x1)]['current'].offsetWidth )
        // console.log(ref['current'][String(x1)]['current'].scrollHeight)
        // console.log(ref['current'][String(x1)]['current'].offsetHeight)
          newFontSize = (parseFloat((ref['current'][String(x1)]['current'].style.fontSize).slice(0, -2)) * 0.90).toFixed(2);
          if(newFontSize<10){
            break;
          }
          // console.log(x1)
          // console.log(newFontSize)
          ref['current'][String(x1)]['current'].setNativeProps({style : {fontSize : (newFontSize+'px'),overflowY:'auto'}});
          // $(".glyphicon").css('font-size',newFontSize1+ 'px');
          // console.log(ref['current'][String(x1)]['current'].style);
          // console.log($(ref['current'][String(x1)]['current']).height());
          // console.log(newFontSize);

          x++;
        }
          var newFontSize1 = (newFontSize*0.90).toFixed(2);
          ref['current'][String(x1)]['current'].setNativeProps({style : {fontSize : (newFontSize1+'px'),overflowY:'none'}});
      
          setQuotes2((prev)=>[...prev,{'quote':quotes1[x1],'fontSize':newFontSize1=='NaN'?70:newFontSize1}])
  } 
 




      // <View style={{backgroundColor:'white'}}>
  return (
    <ScrollView ref={scrollRef} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} onStartShouldSetResponder={()=>true} style={styles.scroll}>
    {
      isFocused&&timer&&
      <Helmet>
        <title>Daily Motivational Quotes</title>
        <meta name="description" content="Daily Motivational Quotes List" />
      </Helmet>
    }     
    <Pressable>
    <View style={styles.centeredView}>
        {/*<A href="https://randomworkoutz.com" rel="nofollow" target="_blank" title="whooa" style={{color:'white'}} >link</A>*/}
        {
          (quotes1).map((item,index)=>{
            // console.log(item,index)
            // if(!doTheThing){
            //   setDoTheThing(true)
            // }
            const item1 = reword(item['quote'])

            return(
                
                <View  key={item+''+index} style={styles.modalView}>
                  <View style={{justifyContent:'center',width:'100%',padding:0}}>
                    <Text adjustsFontSizeToFit style={{color:'white',fontSize:Number(item['fontSize']*.85),justifyContent:'center',alignItems:'center', paddingTop:10,}}>{item1[0]}  </Text>
                  </View>
                  <View style={{alignItems:'center',justifyContent:'center',width:'100%',padding:0}}>
                    <Text adjustsFontSizeToFit style={{color:'white',fontSize:Number(item['fontSize']*.85),justifyContent:'center',alignItems:'center',padding:10,}}>{item1[1]}  </Text>
                  </View>
                  </View>
                  

              )
          })
        }
        </View>
      </Pressable>
    </ScrollView>

    // do a Platform.os == 'web' vs Platfoem.os == 'android'
                  // <Text  style={{padding:5,flexWrap:'wrap',alignSelf:'center',textAlign:'center',color:'white',fontSize:Number(item['fontSize']),alignItemsVertical:'center'}}>{item1[1]}</Text>
                  // <Text  style={styles.modalText} >{item1[0]}  </Text>
                  // <Text style={styles.modalText1} >{item1[1]}</Text>
                  

  );
}
export default observer(Quotes)
const styles = StyleSheet.create({
  container: {
    flex:0,
    justifyContent:'flex-start'
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    // flex: 0,
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
    // justifyContent:'flex-start'
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    paddingHorizontal: 5,
    paddingTop: 10,
  },
  textContainer: {
    flex:1,
    flexDirection:'row',
    fontSize:25,
    overflowX:'none',
    overflowY:'none',
    textAlign:'center',
    justifyContent: 'center',
    alignItems: 'center',
    // height:'100%',
    alignSelf: 'stretch',

    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'blue',
  },
  modalText: {
    // flex:9,
    padding: 15,
    textAlign: 'center',
    color:'white',
    // padding:15,
    width:'100%',
    // fontSize:50,
    

    // justifyContent: 'center',
    // alignItems: 'center',

    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'green',

  },modalText1: {
    width:'100%',
    // flex:1,
    padding: 15,
    textAlign: 'center',
    color:'white',
    paddingTop:15,
    // fontSize:25,
    
    // justifyContent: 'center',
    // alignItems: 'center',
    

    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'red',

  },
  scroll: {
    flex: 0,
    flexGrow:1,
    flexDirection:'column',
    alignSelf: 'stretch',
    height:'100%',
    width:'100%',
    // maxWidth:1200,
    backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'red',
  },
  centeredView: {
    flex: 1,
    flexGrow:1,

    justifyContent: 'flex-start',
    alignItems: 'center',
    // alignContent: 'flex-start',
    // alignSelf: 'flex-start',
    alignSelf: 'stretch',
    maxWidth:1200,
    height:'100%',
    // maxHeight:600,
    backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'yellow',
  },
  
  modalView: {
    flex:1,
    flexWrap:'wrap',
    flexDirection: 'column',
    // alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    borderTopRightRadius:25,
    borderTopLeftRadius:25,
    width:'100%',
    // maxWidth:600,
    minHeight:400,
    maxHeight:600,
    backgroundColor:'black',
    padding:15,
    marginTop:0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    // alignItemsVertically:'center'
  },
  
});
