import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Text } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import {firebase} from './firebase';
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"


import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { View } from './Themed';
import { useStores } from "../models";
import { observer } from "mobx-react-lite"
const DeleteAccount = ({}) => {

  const navigation = useNavigation()
  // const db = firebase.firestore();
  const { userStore } = useStores()
  const [confirmed,setConfirmed] = useState(false)
  const [loggedIn,setloggedIn] = useState(false)

  useEffect(()=>{
    if(userStore.getUser()!=''){
      setloggedIn(true)
    }else{
      setloggedIn(false)
      nav()
        
    }
  },[])

  const nav = async()=>{
    await new Promise(resolve => setTimeout(resolve, 4000));
    navigation.navigate('Root', { screen: 'Login' })
  }

  const deleteAccount = (x)=>{
    if(userStore.getUser()!=''){
      
      const user = firebase.auth().currentUser;

      user.delete().then(() => {
        // User deleted.

        navigation.navigate('Root', { screen: 'Login' })
        
      }).catch((error) => {
        // An error ocurred
        // ...
      });
      
     } else{
        navigation.navigate('Root', { screen: 'Login' })
     }
  }



  return (
    <View style={styles.container}>
      <View style={styles.helpContainer}>
      {

      !loggedIn&&
      <Text style={{minHeight:25,flex:5,flexWrap:'wrap',color:'white'}} lightColor={Colors.light.tint}>
          Need To Be Logged In
      </Text>
    }
      {

      !confirmed?
      <Text style={{minHeight:25,flex:5,flexWrap:'wrap',color:'white'}} lightColor={Colors.light.tint}>
          Do you want to delete your account?
      </Text>
      :
      <Text style={{minHeight:25,flex:5,flexWrap:'wrap',color:'white'}} lightColor={Colors.light.tint}>
          Confirm Account Deletion
      </Text>

      }
      <View style={{flex:1,flexDirection:'row',maxWidth:'90%'}} >
        <TouchableOpacity style={{minHeight:50,margin:5,flex:1,alignItems:'center',justifyContent:'center',padding:10,borderWidth:1,borderType:'solid',borderColor:'white'}} onPress={()=>{setConfirmed(false)}}>
          <Text style={{minHeight:25,flex:1,color:'white'}} >
            No
          </Text>
        </TouchableOpacity>

        <TouchableOpacity style={{minHeight:50,margin:5,flex:1,alignItems:'center',justifyContent:'center',borderWidth:1,borderType:'solid',borderColor:'white',padding:10}} onPress={()=>{!confirmed? setConfirmed(true):deleteAccount()}}>
          <Text style={{minHeight:25,flex:1,color:'white'}} >
            Yes
          </Text>
        </TouchableOpacity>
      </View>

      
        
      </View>
      
     {/* <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Rest Inbetween Rounds
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={0} max={120} increment={5} num={roundObjStore.restNum} set={roundObjStore.setRestNum} />
        </View>
      </View>*/}

    </View>
  );
}
export default observer(DeleteAccount)
const styles = StyleSheet.create({
  container: {
    flex:1,
    justifyContent:'flex-start',
    width:'100%',
    maxWidth:600,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    flex: 1,
    marginTop: 15,
    // marginHorizontal: 20,
    alignItems: 'center',
    width:'100%',
    maxWidth:600,
    // justifyContent:'flex-start'
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    paddingHorizontal: 5,
    paddingTop: 10,
  },
});
