import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RadioButton } from 'react-native-paper';
import MultiPicker from './MultiPicker';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"

import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models"
import { observer } from "mobx-react-lite"
import { getSnapshot, onSnapshot } from "mobx-state-tree"


const PickEquipmentRounds = ({ round})=> {

  const { roundObjStore,userStore } = useStores()
  const [roundObjStore1,setRoundObjStore1] = useState(roundObjStore.getRoundObjj())
  const [enabled,setEnabled] = useState(userStore.getSubscriptionActive())
  const isFocused = useIsFocused();

  useEffect(()=>{
    setEnabled(userStore.getSubscriptionActive())
  },[isFocused])
  
  // useEffect(()=>{
  //   setRoundObjStore1(roundObjStore.getRoundObjj())
  // },[roundObjStore])

  onSnapshot(roundObjStore, (newSnapshot) => {
    console.info("Got new roundObjStore snapshot:", newSnapshot)
    setRoundObjStore1(newSnapshot)
  })

  const handleTouch = (equip) => {
    console.log(equip)
    // userStore.setSubscriptionActive(true)
    if(userStore.getSubscriptionActive()){
    console.log(equip,' in there like swimware')
      const change = !roundObjStore[round][equip]
      roundObjStore.setRoundObj(round,equip,change)
      setTimeout(function () {      
        setRoundObjStore1(roundObjStore.getRoundObjj())  
        }, 150);
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Equipment for Round {round}
        </Text>
      </View>

      <MultiPicker first={"Body Weight"} firstState={roundObjStore1[round]['Body Weight']} second={"Trx Straps"} secondState={roundObjStore1[round]['Trx Straps']} third={"Bosu Ball"} thirdState={roundObjStore1[round]['Bosu Ball']} handleTouch={handleTouch} />
      <MultiPicker first={"Sandbag"} firstState={roundObjStore1[round]['Sandbag']} second={"Pull Up Bar"} secondState={roundObjStore1[round]['Pull Up Bar']} third={false} thirdState={false} handleTouch={handleTouch} />
      <MultiPicker first={"Medicine Ball"} firstState={roundObjStore1[round]['Medicine Ball']} second={"Throwable Wall"} secondState={roundObjStore1[round]['Throwable Wall']} third={"Slammable Floor"} thirdState={roundObjStore1[round]['Slammable Floor']} handleTouch={handleTouch} />
      <MultiPicker first={"Resistance Bands Long"} firstState={roundObjStore1[round]['Resistance Bands Long']} second={"Resistance Bands Short"} secondState={roundObjStore1[round]['Resistance Bands Short']} third={false} thirdState={false} handleTouch={handleTouch} />

      {/*<View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          {(roundObjStore[round]['Trx Straps']).toString()}
        </Text>
        
      </View>*/}

      {
        !enabled&&
      <Text style={{marginTop:25, minHeight:25, color:'#fff'}}>Unlock All Levels with a Pro Subscription</Text>
      }
      {
        !enabled&&
      <Text style={{marginTop:25, minHeight:25, color:'#fff'}}>Try Pro with a 30 day free trail</Text>      
      }
      {
        !enabled&&
      <Text style={{marginTop:25, minHeight:25, color:'#fff'}}>Find Subscriptions in your Profile Page</Text>      
      }

    </View>
  );
}

export default observer(PickEquipmentRounds)

const styles = StyleSheet.create({
  container: {
    alignSelf:'stretch',
    // flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  itemContainer1: {
    flexDirection:'row',
    alignSelf:'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#0e1111',
    // paddingHorizontal: 4,
    backgroundColor: '#0e1111',
  },
  false1: {
    borderRadius: 3,
    paddingHorizontal: 4,
    backgroundColor: palette.black
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    // flex:1,
    marginBottom: 20,
    marginHorizontal: 10,
    alignItems: 'center',

  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    // flex:1,
    alignSelf:"stretch",
    // flex: 1,
    // flexDirection:'row',
    marginTop: 5,
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
  },
});
