  import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Modal,Pressable,Image } from 'react-native';
import { RadioButton } from 'react-native-paper';
import NumberScroll from './NumberScroll';
import { useState, useEffect, useRef} from "react"
import React from 'react';
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import CalendarPicker from 'react-native-calendar-picker';
import {firebase} from './firebase';
import moment from 'moment';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
// import { Image } from 'expo-image';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button"




import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models";
import { observer } from "mobx-react-lite"

const Calendar = ({table}) => {

  const { userStore } = useStores()
  const [startDate,setStartDate] = useState()
  const [dayArray,setDayArray] = useState({})
  const [customDatesStyles,setCustomDatesStyles] = useState([])
  const [modalVisible, setModalVisible] = useState(false);
  const [pickedDay, setPickedDay] = useState({});
  const calRef = useRef()
  const db = firebase.firestore();
  const storage = firebase.storage();
  const storageRef = storage.ref();

  const content = 'January February March April May June July August September October November December'.split(' ');
  const weekDayName = 'SUN MON TUES WED THURS FRI'.split(' ');
  const daysOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const d = new Date();

   const blurhash =
    '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';



  useEffect(()=>{
      setStartDate(String(new Date()))
  },[])

  const onDateChange = (date)=> {
        console.log(date._i.day,date._i.month,date._i.year,date.format('x'))
        setStartDate(date.toString())
        
        setPickedDay({})
        let yup=false
        Object.keys(dayArray).forEach((item,index)=>{
          if(dayArray[item]['day']==date._i.day){
            yup==true
            getPic(item,date);
          }
        })
        if(!yup){setModalVisible(false)}
     
    }

  const onMonthChange = (date)=> {
        console.log(date._i.month,date._i.year)
        // setStartDate(date.toString())
        if(true){
          getPrevWorkoutz(date._i.month+""+date._i.year);
        }
     
    }

  useEffect(()=>{
   const month = d.getMonth();
   const day = d.getDay();
   const year = d.getFullYear();
   const date = d.getDate();
    console.log(month);
    console.log(year);
    console.log(day);
    console.log(date);
    var totalDaysOfMonth = daysOfMonth[month];
    var counter = 1;
    getPrevWorkoutz(month+""+year);
  },[])

  useEffect(()=>{
    if(dayArray!=null){
      setCustomDatesStyles([])
      Object.keys(dayArray).forEach((item,index)=>{
        console.log(item)
        const obj = {
              date: moment(item,'x'),
              // Random colors
              style: {backgroundColor: '#05eff7'},
              textStyle: {color: 'white'}, // sets the font color
              containerStyle: [], // extra styling for day container
              allowDisabled: true, // allow custom style to apply to disabled dates
            }

        console.log(obj)
        setCustomDatesStyles(prev=>[...prev,obj])

            

        
      })
    }
  },[dayArray])

 


var getPrevWorkoutz = function(monthYear, dayClicked){
  if(userStore.getUser()==''){
  console.log('user', userStore.getUser())
    return
  }else if(monthYear==undefined){
  console.log('monthyear', monthYear)
    return
  }
   var docMonthYear = db
    .collection("users").doc(userStore.getUser())
    .collection("workoutz").doc(monthYear);
  
    docMonthYear.get()
    .then(function(doc) {
       
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      
      var data = doc.data();
      if(data!=undefined){
        setDayArray(data)
      }
        
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
}

const save = async (item)=>{
  console.log('item',item)
  console.log(item.monthYear)
  console.log(item.id)
  console.log(item.day)
  console.log(item.note)
  console.log(item.picture)
  console.log(item.public)
  const ref = db
    .collection("users").doc(userStore.getUser())
    .collection("workoutz").doc(item['monthYear']);
    if(item['containsNudity']!=undefined){
      ref.update({ [item['id']]:{day:item['day'],note:item['note'],picture:item['picture'],public:!item['public'],containsNudity:item['containsNudity'] } })
    }else{
      ref.update({ [item['id']]:{day:item['day'],note:item['note'],picture:item['picture'],public:!item['public'] } })
    }

}

var getPic = function(path,date){
  console.log('path ',path)
  console.log('dayArray[\'path\'] ',dayArray[path])
  console.log('public',dayArray[path]['public'])
  var ref = storageRef.child('users/'+userStore.getUser()+'/'+path);

  setPickedDay((prev)=>{
      const clone = JSON.parse(JSON.stringify(prev))
      if(clone[path]==undefined){
        clone[path]={}
      }
        clone[path]['day']=dayArray[path]['day'];
        clone[path]['note']=dayArray[path]['note'];
        clone[path]['picture']=dayArray[path]['picture'];
        clone[path]['public']=dayArray[path]['public']!=undefined?dayArray[path]['public']:false;
        clone[path]['containsNudity']=dayArray[path]['containsNudity']!=undefined?dayArray[path]['containsNudity']:undefined;
        clone[path]['monthYear'] = date._i.month+""+date._i.year
        clone[path]['month'] = date._i.month
        clone[path]['year'] = date._i.year
        clone[path]['id'] = path
      console.log(clone)
    setModalVisible(true)
      return clone
    })

  ref.getDownloadURL().then(function(url) {
    // $("#"+picId).attr("src",url);
    // $("#"+picId).show();
    console.log(url);
    setPickedDay((prev)=>{
      const clone = JSON.parse(JSON.stringify(prev))
      if(clone[path]==undefined){
        clone[path]={}
      }
        clone[path]['url']=url;
      console.log(clone)
      return clone
    })
    setModalVisible(true)

    // Insert url into an <img> tag to "download"
  }).catch(function(error) {
    console.log('errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',error);
    // $("#"+picId).attr("src",'');
    // $("#"+picId).hide();
    
  });
}

const move = (next)=>{
    // console.log(moment().day())
    // console.log(pickedDay)
    const picked = Object.keys(pickedDay)
    const dayObj = Object.keys(dayArray).sort()

    console.log(dayObj)

    Object.keys(dayArray).forEach((item,index)=>{
      if(dayArray[item]['day']==pickedDay[picked[0]].day){
        const indy = dayObj.indexOf(picked[0])

        // getPic(item,date);
        if(dayArray[dayObj[indy+(next?1:-1)]]!=undefined){
          calRef.current.handleOnPressDay({year:pickedDay[picked[0]]['year'],month:pickedDay[picked[0]]['month'],day:(Number(dayArray[dayObj[indy+(next?1:-1)]]['day']))})
          console.log('wtf is going on: ',dayArray[dayObj[index+1]])
        }else{

          calRef.current.handleOnPressDay({year:pickedDay[picked[0]]['year'],month:pickedDay[picked[0]]['month'],day:(Number(pickedDay[picked[0]]['day'])+(next?1:-1))})
        }
      }
    })

  

}


// add a "public" check box 


      // <View style={{backgroundColor:'white'}}>
  return (
    <View style={styles.container}>
      <CalendarPicker
          ref={calRef}
          onDateChange={onDateChange}
          onMonthChange={onMonthChange}
          textStyle={{color:'white'}}
          customDatesStyles={customDatesStyles}
        />

        <View>
          <Text>SELECTED DATE:{ startDate }</Text>
        </View>
      {/*</View>*/}
      
     {/* <View style={styles.helpContainer}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>
          Rest Inbetween Rounds
        </Text>
        <View style={styles.numbers}>
          <NumberScroll min={0} max={120} increment={5} num={roundObjStore.restNum} set={roundObjStore.setRestNum} />
        </View>
      </View>*/}

        {
        // modalVisible&&
        <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          console.log('Modal has been closed.');
          setModalVisible(false);
        }}>
          <Pressable
          onPress={(event) => {if(event.target == event.currentTarget){setModalVisible(false)};console.log(event.target,event.currentTarget)}}
          style={styles.centeredView3}
          > 
                  <ScrollView onStartShouldSetResponder={()=>true} style={styles.scroll}>
                  <Pressable>
                  <View style={styles.centeredView}>
                  <View style={styles.centeredView1}>
                  <MaterialIcons.Button name={'exit-to-app'} backgroundColor={'rgba(76, 175, 80, 0.0)'} style={styles.button} onPress={() => setModalVisible(false)}>
                      </MaterialIcons.Button>
                  </View>
            {
              Object.keys(pickedDay).map((item,index)=>{
                console.log(item,index)
                console.log(pickedDay[item]['url'])
                return(
                    
                    <View  key={item+''+index} style={styles.modalView}>
                     
                      <Text style={styles.modalText}>{pickedDay[item]['note']}</Text>
                      <View style={{flex:1,flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <TouchableOpacity style={{position:'absolute',left:10,zIndex:10,height:'100%',justifyContent:'center',alignItems:'center',paddingRight:15}} onPress={() => move(false)}>
                        <MaterialIcons name={'arrow-back-ios'} color='white' size={24} backgroundColor={'rgba(76, 175, 80, 0.0)'} >
                            </MaterialIcons>
                        </TouchableOpacity>

                        {
                          pickedDay[item]['url']!=undefined&&
                          <Image
                            style={styles.image}
                            source={{uri:pickedDay[item]['url']}}
                            // defaultSource={{uri:blurhash}}
                            resizeMode="contain"
                            // transition={1000}
                        
                          />
                        }
                        <TouchableOpacity style={{position:'absolute',right:10,zIndex:10,height:'100%',justifyContent:'center',alignItems:'center',paddingLeft:15}} onPress={() => move(true)}>
                        <MaterialIcons name={'arrow-forward-ios'} color='white' size={24} backgroundColor={'rgba(76, 175, 80, 0.0)'} >
                            </MaterialIcons>
                        </TouchableOpacity>
                        
                      </View>
                      <Text style={styles.modalText}>{moment(item,'x').toString()}</Text>

                      <View style={styles.modalRadio}>
                      <RadioButtonGroup
                        containerStyle={{ marginBottom: 10 }}
                        selected={pickedDay[item]['public']?'public':false}
                        onSelected={(value) => {save(pickedDay[item]);setPickedDay(prev=>({
                            ...prev,
                            [item]: { ...prev[item],
                              [value]: !prev[item][value]?value:false
                            }
                          })
                        );console.log(pickedDay[item])}}
                        containerOptionStyle={{margin:5}}
                        radioBackground="white"
                      >
                        <RadioButtonItem value="public" label=<Text style={pickedDay[item]['containsNudity']!=undefined&&pickedDay[item]['containsNudity']&&pickedDay[item]['public']?{color:'red'}:{color:'white'}}>Public</Text> />
                      </RadioButtonGroup>
                      </View>
                      
                    </View>

                  )
              })
            }
                    </View>
                  </Pressable>
                </ScrollView>
                  </Pressable>
          

      </Modal>
      }

    </View>
  );
}
                      // <Text style={styles.modalText}>{'Public : '+(pickedDay[item]['public']!=undefined?pickedDay[item]['public']:false)}</Text>
export default observer(Calendar)
const styles = StyleSheet.create({
  container: {
    flex:0,
    justifyContent:'flex-start'
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    // flex: 0,
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
    // justifyContent:'flex-start'
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    paddingHorizontal: 5,
    paddingTop: 10,
  },
  modalRadio: {
    marginBottom: 15,
    textAlign: 'center',
    color:'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    color:'white',
  },
  centeredView: {
    flex: 1,
    flexGrow:1,
    marginTop:60,
    justifyContent: 'center',
    alignItems: 'center',
    // alignContent: 'flex-start',
    // alignSelf: 'flex-start',
    width:'100%',
    maxWidth:600,
    height:'100%',
    // maxHeight:600,
    backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'yellow',
  },
  centeredView3: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // alignContent: 'flex-start',
    // alignSelf: 'flex-start',
    backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'blue',
  },
  scroll: {

    marginTop:60,
    flex: 1,
    flexGrow:1,
    flexDirection:'column',
    width:'100%',
    height:'100%',
    maxWidth:600,
    backgroundColor:'rgba(76, 175, 80, 0.0)',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'red',
  },
  centeredView1: {
    // flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderTopLeftRadius:25,
    borderTopRightRadius:25,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 22,
    height:100,
    width:'100%',
    maxWidth:600,
    backgroundColor:'black',
    paddingBottom:0,
    marginBottom:-1,
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'green',

  },
  modalView: {
    flex:1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25,
    width:'100%',
    maxWidth:600,
    minHeight:400,
    // maxHeight:600,
    backgroundColor:'black',
    paddingTop:0,
    marginTop:-1,
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'blue',
  },
  image: {
      flex: 1,
      flexGrow:1,
      width: '100%',
      backgroundColor: '#0553',
      height:'100%',
      // maxHeight:600,
    //   borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: 'red',
    },
});
