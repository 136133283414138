import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Dimensions } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { useState, useEffect, useRef,useIsMounted } from "react"
import { FontAwesome } from '@expo/vector-icons';
import Controls from './Controls';
import Timer from './Timer';
import Videos from './Videos';
import Audios from './Audios';
import DoneModal from './DoneModal';
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"
import {firebase} from './firebase';


// import { CirclesLoader, PulseLoader, TextLoader, DotsLoader} from 'react-native-indicator';


import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models"
import { observer } from "mobx-react-lite"
import { pickem } from '../utils/pickem';



const Workout = ({})=> {
  let Audio = require('expo-av').Audio;
  const db = firebase.firestore();
  const navigation = useNavigation()
  const { roundObjStore, workoutObjStore,userStore } = useStores()
  const [resting,setResting] = useState(false) 
  const [timerTiming,setTimerTiming] = useState(false)
  const [timerVolume,setTimerVolume] = useState(false)
  const [volume,setVolume] = useState(false)
  const [doubleDone,setDoubleDone] = useState(false)
  const [leftRight,setLeftRight] = useState(false)
  const parts = ['upper','lower','core','arms','booty']
  const [ready,setReady] = useState(false)
  const [spinner,setSpinner] = useState(false)
  const [done,setDone] = useState(false)
  const [switchRound,setSwitchRound] = useState(false)
  const isFocused = useIsFocused();
  const soundObj = useRef({})
  const beep = useRef({});

  useEffect(()=>{
    console.log('get waiver sign')
    if(userStore.getUser()!=''){
    console.log('yup')
      getWaiverSigned()
    }

  },[userStore,isFocused])

  const enableAudio = async () => {
    Audio = require('expo-av').Audio;
    await Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
      staysActiveInBackground: true,      
    })
  }

  useEffect(()=>{
    setTimerTiming(false)
//      console.log('is focused')
      setVolume(false)
      setReady(false)
      setTimerVolume(false)
      setDone(false)
      console.log(soundObj)
      Object.keys(soundObj.current).forEach((key,index)=>{
        if(key!='sound'){
          delete soundObj.current[key]
        }
      })
      // soundObj.Current = {}
      // beep.current = {}
      enableAudio()

  },[isFocused])

  const getWaiverSigned = async()=>{
    var waiverSigned = db
    .collection("users").doc(userStore.getUser())
    .collection("waivers").doc('waiver');
    try{
      const doc = await waiverSigned.get()
      const data = doc.data()
      if(!data['signed']){
        navigation.navigate('Waiver')
      }
    } 
    catch(err){
      console.log(err)
      if(String(err).includes('permission')){
        console.log('includes')
        navigation.navigate('Login')
      }else if(!String(err).includes('offline')){
        navigation.navigate('Waiver')
      }
    }
  }
  useEffect(() => {
      const unsubscribe = navigation.addListener('focus', () => {
        workoutObjStore.setCurrentRound(1)
        setReady(false)

        if(userStore.getUser()==''){
          navigation.navigate('Login')
        }else{
          setSpinner(true)
          getPicks()

        }

        // do something
      });

      return ()=>{
        setSpinner(false)
        unsubscribe;
      }
  }, [navigation]);

  // useEffect(()=>{
  //   let timeout ='fart'
  //   if(spinner){
  //       setTimeout(() => {
        
  //     }, 7000);
  //   }

  //   return clearTimeout(timeout)
  // },[spinner])

  const getPicks=async()=>{
    setDone(false)
    for(let x=1;x<20;x++){
      parts.forEach((item1,index1)=>{
        // console.log(item,item1,item[item1])
          workoutObjStore.setWorkoutObj(String(x),item1,{'1':[{x:'Loading',gif:'g',audio:'a',leftRight:false}],'2':[{x:'',gif:'g',audio:'a',leftRight:false}],'3':[{x:'',gif:'g',audio:'a',leftRight:false}]})
          workoutObjStore.setWorkoutObj(String(x),item1+'Curr',{'1':0,'2':0,'3':0,'type':1})
          workoutObjStore.setWorkoutObj(String(x),'currentRepeat',{'1':(roundObjStore.getRoundObj(String(x)))['repeat'],'2':(roundObjStore.getRoundObj(String(x)))['repeat'],'3':(roundObjStore.getRoundObj(String(x)))['repeat'],'type':1})
          workoutObjStore.setWorkoutObj(String(x),'currentTime', (workoutObjStore.getWorkoutObj(String(x)))['roundTime'])
          workoutObjStore.setWorkoutObj(String(x),'currentSetTime',(workoutObjStore.getWorkoutObj(String(x)))['roundSetTime'])
          workoutObjStore.setWorkoutObj(String(x),'currentRestTime',(workoutObjStore.getWorkoutObj(String(x)))['roundRestTime'])
          workoutObjStore.setWorkoutObj(String(x),'currentTarget',0)
      })
    }

    const pickem1 = await pickem(userStore.getUser(),roundObjStore.getRoundObjj())
    pickem1.forEach((item,index)=>{
      parts.forEach((item1,index1)=>{
        // console.log(item,item1,item[item1])
          workoutObjStore.setWorkoutObj(String(index+1),item1,item[item1])
          workoutObjStore.setWorkoutObj(String(index+1),item1+'Curr',{'1':0,'2':0,'3':0,'type':1})
        setSpinner(false)
          setReady(true)
          // console.log('fuck')
      })
    })

  }
  
  const {width} = Dimensions.get('window');

  return (
    <View style={styles.container}>
    {
      !done&&
      <View style={{ width: width, maxWidth:500, flex:1, }}>
        <Timer beep={soundObj} setDone={setDone} resting={resting} setResting={setResting} timerTiming={timerTiming} setTimerTiming={setTimerTiming} timerVolume={timerVolume} setTimerVolume={setTimerVolume} doubleDone={doubleDone} setDoubleDone={setDoubleDone} switchRound={switchRound} setSwitchRound={setSwitchRound}/>
        <Controls resting={resting} setResting={setResting} volume={volume} setVolume={setVolume} leftRight={leftRight} setLeftRight={setLeftRight}   />
        {
          ready&&
          <Videos timerTiming={timerTiming} ready={ready} />
        }
        {
          ready&&
          <Audios soundObj={soundObj} volume={volume} setVolume={setVolume} doubleDone={doubleDone} setDoubleDone={setDoubleDone} timerVolume={timerVolume} leftRight={leftRight} setLeftRight={setLeftRight} switchRound={switchRound} />
        }

      
      </View>
    }

    {
      done&&
      <View style={{ width: width, maxWidth:500, flex:1, }}>
        <DoneModal navigation={navigation} done={done}/>
      </View>
     
    }
    </View>
  );
}

export default observer(Workout)


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth:500,
    // backgroundColor:palette.black,
    color: palette.white
  },
  container1: {
    paddingHorizontal: 10,
    paddingTop: 35,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  outside:{
    flex: 1,
    alignSelf:'stretch',
    justifyContent: 'center',
  },
  separator: {
    height: 1,
    marginVertical: 20,
    marginHorizontal: 20,
    color: palette.white,
    borderBottomColor: palette.white,
    borderBottomWidth: 1,
    flex:1,
    // alignSelf:'stretch'
  },
  itemContainer1: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: palette.white,
  },
  itemContainer2: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemContainer3: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    color: palette.white,
  },

});
