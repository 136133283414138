import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView,Dimensions } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { useState, useEffect, useRef,useIsMounted } from "react"
import { FontAwesome,Ionicons } from '@expo/vector-icons';
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"


import Beep from '../assets/sounds/beep.mp3';
import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import { useStores } from "../models"
import { observer } from "mobx-react-lite"
import { useKeepAwake } from 'expo-keep-awake';
const Timer = ({ beep, setDone,resting,setResting,timerTiming,setTimerTiming,timerVolume,setTimerVolume,doubleDone,setDoubleDone,ready,switchRound,setSwitchRound })=> {

  useKeepAwake();
  const { roundObjStore, workoutObjStore, userStore } = useStores()
  const isFocused = useIsFocused();
  let Audio = require('expo-av').Audio;


  const [restRound,setRestRound] = useState(false)
  const [hours,setHours] = useState(0)
  const [minutes,setMinutes] = useState(0)
  const [secs,setSecs] = useState(0)
  const [timeDisplay,setTimeDisplay] = useState("")
  const [currentRestInbetweenRounds,setCurrentRestInbetweenRounds]=useState(roundObjStore.restNum)
  const [playDouble,setPlayDouble] = useState(false)
  const [playingDouble,setPlayingDouble] = useState(false)
  const [test,setTest] = useState(false)

  useEffect(()=>{
    beep.current.sound = new Audio.Sound()
    beep.current.status=null
    console.log('************************new sound*********************')
    beepFunc()

  },[isFocused])

  const beepFunc = async()=>{
      console.log("beeeeeeeeeep")
      // console.log(beep.current.sound)
      // console.log(beep.current.sound._loading)
      
      // if(beep.current.sound._loading){
      // }
      // if(beep.current.sound==undefined){
      //   beep.current.sound = new Audio.Sound()
      // }
      await beep.current.sound.unloadAsync()
      await beep.current.sound.loadAsync(Beep)
      await beep.current.sound.setOnPlaybackStatusUpdate(async(stat)=>{try{beep.current.status=await stat}catch(err){console.log(err)}})
      console.log("beeeeeeeeeep11")
    }
  useEffect(()=>{

    return ()=>{
      if(beep.current.sound!=undefined){
        beep.current.sound.unloadAsync()
      }
    }
  },[])

  const beepStatusFunc = async()=>{
      console.log("@@@@@@@@@@@ ",beep.current.status)
      // if(beep.current.status!=null && beep.current.status.didJustFinish && playDouble){
      //   //setDoubleDone(false)
      //   console.log("******************")
      //   await beep.current.sound.playFromPositionAsync(0)
      //   setPlayDouble(false)
      //   setPlayingDouble(true)
      //   setDoubleDone(true)
      // }
      //else if(beep.current.status!=null && beep.current.status.didJustFinish && !playDouble && playingDouble){
        //setDoubleDone(true)
        //setPlayingDouble(false)

      //} 
    }
  // useEffect(()=>{
  //   beepStatusFunc()
  // },[beep.current.status])

  const playBeep = async(double)=>{
    //const stat = await beep.current.sound.getStatusAsync()
    try{
      //if(stat.positionMillis>0){
        //console.log('replayAsync')
        //await beep.current.sound.replayAsync()
      //}else{
        //console.log('playAsync')
        //await beep.current.sound.playAsync()
      //}
      await beepFunc()
      console.log('should be beeping####################################3')
      // console.log(beep.current.sound)
      // const statiusMaximus = await beep.current.sound.getStatusAsync()

      // console.log(statiusMaximus)
      // if(statiusMaximus.isPlaying){
      // console.log('playing')
      //   await beep.current.sound.stopAsync()
      // }
      await beep.current.sound.playFromPositionAsync(0)
      // await beep.current.sound.setPositionAsync(0)
      // await beep.current.sound.playAsync()
      console.log('should have just beeped&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')

      if(double){
        console.log("~~~~~~~~~double")
//        setPlayDouble(true)
        setTimeout(async function () {  
          console.log("`````````````timeout done") 
          await beep.current.sound.stopAsync()      
          await beep.current.sound.playFromPositionAsync(0)
          setDoubleDone(true)
        }, 200);

      }
    } catch(error){
      console.log('beep err: ',error)
      Audio = require('expo-av').Audio;

    }
  }

  useEffect(()=>{
    if(timerVolume){
      playBeep(false)
    }
  },[timerVolume])

  useEffect(() => {
    const intervalFunc1 = setInterval(() => {
      // console.log(timerTiming)
      if(timerTiming){
        if(switchRound){
          setCurrentRestInbetweenRounds(currentRestInbetweenRounds=>currentRestInbetweenRounds-1)
        } else if(resting){
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentRestTime',workoutObjStore[workoutObjStore.currentRound].currentRestTime-1)
          if(workoutObjStore[workoutObjStore.currentRound].type=='randomness'){
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTime',workoutObjStore[workoutObjStore.currentRound].currentTime-1)        
          }
        } else {
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentSetTime',workoutObjStore[workoutObjStore.currentRound].currentSetTime-1)
          if(workoutObjStore[workoutObjStore.currentRound].type=='randomness'){
            workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTime',workoutObjStore[workoutObjStore.currentRound].currentTime-1)        
          }
        }
      }

    }, 1000);
    return () => {clearInterval(intervalFunc1)};
  }, [timerTiming,switchRound,resting,restRound]);

  useEffect(()=>{
    // console.log(workoutObjStore[workoutObjStore.currentRound].currentTime)
    if(workoutObjStore[workoutObjStore.currentRound].type!='randomness'){
      let num = '3'
      if(workoutObjStore[workoutObjStore.currentRound].targets[0]=='booty'){
        num = '2'
      } 
      console.log('currRepeat 1 : ',workoutObjStore[workoutObjStore.currentRound].currentRepeat['1'])
      console.log('currRepeat 2 : ',workoutObjStore[workoutObjStore.currentRound].currentRepeat['2'])
      console.log('currRepeat 3 : ',workoutObjStore[workoutObjStore.currentRound].currentRepeat['3'])
      // if(timerVolume){
      //   playBeep(true)
      // }
      let one = workoutObjStore[workoutObjStore.currentRound].currentRepeat['1'];
      let two = workoutObjStore[workoutObjStore.currentRound].currentRepeat['2'];
      let three = workoutObjStore[workoutObjStore.currentRound].currentRepeat['3'];
      let type = workoutObjStore[workoutObjStore.currentRound].currentRepeat['type'];
      let check = false;
      if(num=='2'){
        if(one<=0&&two<=0){
          check=true
        }
      }else{
        if(one<=0&&two<=0&&three<=0){
          check=true
        }
      }
      // for(let a=1;a<=3;a++){
      // }


      if(check){
        setResting(resting => true)
        switchExcercise('next')
        if(workoutObjStore.currentRound+1<=roundObjStore.roundNum){
          workoutObjStore.setCurrentRound(workoutObjStore.currentRound+1)
          if(roundObjStore.restNum>0){
            setSwitchRound(true)
          }
        }else{
          setTimerTiming(false)
          alert("done")
          setDone(true)
          // console.log('why')
        }
      }
    }       
  },[workoutObjStore[workoutObjStore.currentRound].currentRepeat['3'],workoutObjStore[workoutObjStore.currentRound].currentRepeat['2'],workoutObjStore[workoutObjStore.currentRound].currentRepeat['1']])

  useEffect(()=>{
    // console.log(workoutObjStore[workoutObjStore.currentRound].currentTime)
    if(workoutObjStore[workoutObjStore.currentRound].currentTime<0){
      setResting(resting => true)
      switchExcercise('next')
      if(timerVolume){
        playBeep(true)
      }
      if(workoutObjStore.currentRound+1<=roundObjStore.roundNum){
        workoutObjStore.setCurrentRound(workoutObjStore.currentRound+1)
        if(roundObjStore.restNum>0){
          setSwitchRound(true)
        }
      }else{
        setTimerTiming(false)
        alert("done")
        setDone(true)

      }
    }
    const hours1 = Math.floor(workoutObjStore[workoutObjStore.currentRound].currentTime / (60*60))
    const minutes1 =Math.floor((workoutObjStore[workoutObjStore.currentRound].currentTime / 60)-(hours1*60))
    setHours(hours1);
    setMinutes(minutes1);
    setSecs(workoutObjStore[workoutObjStore.currentRound].currentTime-(hours1*60*60)-(minutes1*60));          
  },[workoutObjStore[workoutObjStore.currentRound].currentTime])

  useEffect(()=>{
    if(switchRound&&currentRestInbetweenRounds<0){
      setSwitchRound(false)
      setCurrentRestInbetweenRounds(roundObjStore.restNum)
      setResting(false)
      if(timerVolume){
        playBeep(false)
      }
    }
  },[currentRestInbetweenRounds])

  useEffect(()=>{
    if(switchRound&&!resting){
      setSwitchRound(false)
    }
  },[resting])
  
  const timeChange=async()=>{
      // console.log(workoutObjStore[workoutObjStore.currentRound].currentTime)
      if(workoutObjStore[workoutObjStore.currentRound].currentSetTime<=0){
        setResting(resting => !resting)
        switchExcercise('next')    
        if(timerVolume){
          await playBeep(true)
        }
      }
    }
  useEffect(()=>{
    timeChange()
  },[workoutObjStore[workoutObjStore.currentRound].currentSetTime])
  
  useEffect(()=>{
    // console.log(workoutObjStore[workoutObjStore.currentRound].currentTime)
    if(workoutObjStore[workoutObjStore.currentRound].currentRestTime<=0){
      setResting(resting => !resting)
      workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentRestTime',workoutObjStore[workoutObjStore.currentRound].roundRestTime)
        
      if(timerVolume){
        playBeep(false)
      }
    }
  },[workoutObjStore[workoutObjStore.currentRound].currentRestTime])


  function switchExcercise(dir){
      workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentRestTime',workoutObjStore[workoutObjStore.currentRound].roundRestTime)
      workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentSetTime',workoutObjStore[workoutObjStore.currentRound].roundSetTime)
    if(workoutObjStore[workoutObjStore.currentRound].type=='randomness'){

      const targetName = workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget];
      
      const targetObj1 = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
      const targetObj2 = workoutObjStore[workoutObjStore.currentRound][targetName]

      if(targetObj2[targetObj1.type][targetObj1[targetObj1.type]]!=undefined&&targetObj2[targetObj1.type][targetObj1[targetObj1.type]].leftRight){
      console.log(targetObj2[targetObj1.type][targetObj1[targetObj1.type]].leftRight,targetObj2[targetObj1.type][targetObj1[targetObj1.type]].x)
        const typeLength1 = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj1.type].length
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj1.type, targetObj1[targetObj1.type]+1>typeLength1-1?typeLength1-1:targetObj1[targetObj1.type]+1)
        return
      }

      if(workoutObjStore[workoutObjStore.currentRound].full){
        if(workoutObjStore[workoutObjStore.currentRound].currentTarget==2){
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTarget',0)
        }else {
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTarget', workoutObjStore[workoutObjStore.currentRound].currentTarget+1)
        }
        // console.log(workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget])
      }

      const targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
      // console.log(targetName)
      // console.log(targetObj.type)
      const typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length

      workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj.type, targetObj[targetObj.type]+1>typeLength-1?typeLength-1:targetObj[targetObj.type]+1)
      // targetObj.type = targetObj.type+1>=2?2:targetObj.type+1;
      if(targetName=='booty'){
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>2?1:targetObj.type+1))
      }else{
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>3?1:targetObj.type+1))
      }

    } else {
      console.log('switchExcercise circut')

      const targetName = workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget];
      const targetObj1 = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
      const targetObj2 = workoutObjStore[workoutObjStore.currentRound][targetName]

      if(targetObj2[targetObj1.type][targetObj1[targetObj1.type]]!=undefined&&targetObj2[targetObj1.type][targetObj1[targetObj1.type]].leftRight){
      console.log('leftRight Right')
      console.log(targetObj2[targetObj1.type][targetObj1[targetObj1.type]].leftRight,targetObj2[targetObj1.type][targetObj1[targetObj1.type]].x)
        const typeLength1 = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj1.type].length
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj1.type, targetObj1[targetObj1.type]+1>typeLength1-1?typeLength1-1:targetObj1[targetObj1.type]+1)
        return
      }else if(targetObj2[targetObj1.type][targetObj1[targetObj1.type]]!=undefined&&targetObj2[targetObj1.type][targetObj1[targetObj1.type]].x.includes('Left')){
      console.log('leftRight Left')
         const typeLength1 = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj1.type].length
         // console.log(targetName+'Curr',targetObj1.type,targetObj1[targetObj1.type]-2>0?targetObj1[targetObj1.type]-2:0)
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj1.type, targetObj1[targetObj1.type]-1>0?targetObj1[targetObj1.type]-1:0)
         // console.log(targetObj2[targetObj1.type][targetObj1[targetObj1.type]].x)
        // return
      }
      // workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','2',roundRepeat)
      // workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','3',roundRepeat)
      if(workoutObjStore[workoutObjStore.currentRound].full){
        if(workoutObjStore[workoutObjStore.currentRound].currentTarget+1,workoutObjStore[workoutObjStore.currentRound].currentRepeat[workoutObjStore[workoutObjStore.currentRound].currentRepeat.type]-1>=0){

      workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat',workoutObjStore[workoutObjStore.currentRound].currentTarget+1,workoutObjStore[workoutObjStore.currentRound].currentRepeat[workoutObjStore[workoutObjStore.currentRound].currentRepeat.type]-1)
        if(workoutObjStore[workoutObjStore.currentRound].currentTarget==2){
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',1)
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTarget',0)
        }else {
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',workoutObjStore[workoutObjStore.currentRound].currentTarget+2)
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, 'currentTarget', workoutObjStore[workoutObjStore.currentRound].currentTarget+1)
        }
        // console.log(workoutObjStore[workoutObjStore.currentRound].targets[workoutObjStore[workoutObjStore.currentRound].currentTarget])
        }else {
          // workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat',{'1':0,'2':0,'3':0,'type':1})

        }
      } else {
          console.log('test else')

        const targetObj = workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']
        // console.log(targetName)
        // console.log(targetObj.type)
        const typeLength = workoutObjStore[workoutObjStore.currentRound][targetName][targetObj.type].length
        if(workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']['type'],workoutObjStore[workoutObjStore.currentRound].currentRepeat[workoutObjStore[workoutObjStore.currentRound].currentRepeat.type]-1>=0){
          console.log('test 1')
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat',workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']['type'],workoutObjStore[workoutObjStore.currentRound].currentRepeat[workoutObjStore[workoutObjStore.currentRound].currentRepeat.type]-1)
        
        // if(targetObj2[targetObj1.type][targetObj1[targetObj1.type]]!=undefined&&!targetObj2[targetObj1.type][targetObj1[targetObj1.type]].x.includes('Left')){
        //   console.log('test 2')
        //   workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr',targetObj.type, targetObj[targetObj.type]+1>typeLength-1?typeLength-1:targetObj[targetObj.type]+1)
        // }

        // targetObj.type = targetObj.type+1>=2?2:targetObj.type+1;
        if(targetName=='booty'){
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>2?1:targetObj.type+1))
        }else{
          console.log('test 3')
          workoutObjStore.setWorkoutObj(workoutObjStore.currentRound, targetName+'Curr','type',(targetObj.type+1>3?1:targetObj.type+1))
        }
        workoutObjStore.setWorkoutObj(workoutObjStore.currentRound,'currentRepeat','type',workoutObjStore[workoutObjStore.currentRound][targetName+'Curr']['type'])
        }
      }
    
    }
  }

  // useEffect(()=>{
  //   // console.log(timerTiming)
  // },[timerTiming])

  const handleIncrement = async (dir)=>{


    if(dir=='round'){
      if(workoutObjStore.currentRound+1<=roundObjStore.roundNum){
        workoutObjStore.setCurrentRound(workoutObjStore.currentRound+1)
        if(roundObjStore.restNum>0){
          setSwitchRound(true)
        }
      }else{
        setTimerTiming(false)
        alert("done")
        setDone(true)
      }
    }else if(dir=='circut'){
      console.log('handle increment circut')
      switchExcercise()
    }else{
      // console.log()
      setTimerTiming(timerTiming => !timerTiming)
    }
    setTest(!test)
    console.log('increment')
  }

  const {width} = Dimensions.get('window');

  
  return (
    <View style={styles.container}>
      <View style={{ width: width, maxWidth:500, }}>

      <View style={styles.container1}>
        <View style={styles.outside}>
          <TouchableOpacity onPress={()=>{setTimerVolume(timerVolume=>!timerVolume)}}>
        <View style={styles.itemContainer3}>
          {timerVolume?
            <FontAwesome
                name="volume-up"
                size={25}
                color={palette.white}
                style={{ marginLeft: 20 }}
              />
            :
            <FontAwesome
                name="volume-off"
                size={25}
                color={palette.white}
                style={{ marginLeft: 20 }}
              />
          }
        </View>
          </TouchableOpacity>
          
        </View>
        <View style={styles.outside}>
          <Text
            style={resting? styles.text1:styles.text2}
          >
          {switchRound?
            currentRestInbetweenRounds
            :
            resting?
              workoutObjStore[workoutObjStore.currentRound].currentRestTime
              :
              workoutObjStore[workoutObjStore.currentRound].currentSetTime
          }
          </Text>
        </View>
        <View style={styles.outside}>
          <TouchableOpacity onLongPress={()=>{handleIncrement('circut')}}>
        <View style={styles.itemContainer2}>
          <Text
            style={styles.text3}
            >
            {
              workoutObjStore[workoutObjStore.currentRound].type=='randomness'?
              hours>0? 
                minutes>0?
                  secs<10?
                    hours+":"+minutes+":"+"0"+secs
                    :
                    hours+":"+minutes+":"+secs
                  : ''
                :
                minutes>0?
                  secs<10?
                    minutes+":"+"0"+secs
                    :
                    minutes+":"+secs
                    :
                    secs<10?
                      "0"+secs
                      :
                      secs
              :
              // "fuck"
              workoutObjStore[workoutObjStore.currentRound].currentRepeat[workoutObjStore[workoutObjStore.currentRound].currentRepeat['type']]
              }
          </Text>
            
        </View>
          </TouchableOpacity>
        </View>

        <View style={styles.outside}>
          <TouchableOpacity onLongPress={()=>{handleIncrement('round')}}>
        <View style={styles.itemContainer3}>
          <Text>
          {
            workoutObjStore.currentRound
          }
          </Text>
        </View>
          </TouchableOpacity>
          
        </View>

        <View style={styles.outside}>
          <TouchableOpacity onPress={()=>{handleIncrement('play')}}>
        <View style={styles.itemContainer3}>
          {timerTiming?
            <Ionicons
              name="pause"
              size={25}
              color={palette.white}
              style={{ marginRight: 20 }}
            />
            :
            <FontAwesome
              name="play"
              size={25}
              color={palette.white}
              style={{ marginRight: 20 }}
            />
          }
        </View>
          </TouchableOpacity>
          
        </View>

      </View>

      <View style={styles.separator} >
      </View>

{/*      <View style={{alignSelf: 'stretch',}}>
        {screen1!=0?
          <RoundObj roundNum={roundNum} round={round} setRound={setRound} />
          :<View />
        }
      </View>*/}
      {/*<View >
        {screen1!=0?
          <View style={styles.separator} ></View>
          :<View />
        }
      </View>*/}


    

    </View>
    </View>
  );
}

export default observer(Timer)


const styles = StyleSheet.create({
  container: {
    // flex: 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth:500,
    // backgroundColor:palette.black,
    color: palette.white
  },
  container1: {
    paddingHorizontal: 10,
    paddingTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  text1: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
    color:'#FFFF33',
  },
  text2: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
    color:'#00FF00',
  },
  text3: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
    color:'#FF2400',
  },
  outside:{
    flex: 1,
    alignSelf:'stretch',
    justifyContent: 'center',
  },
  separator: {
    height: 1,
    marginVertical: 20,
    marginHorizontal: 20,
    color: palette.white,
    borderBottomColor: palette.white,
    borderBottomWidth: 1,
    flex:1,
    // alignSelf:'stretch'
  },
  itemContainer1: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: palette.white,
  },
  itemContainer2: {
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemContainer3: {
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.white,
  },

});
