import { StyleSheet, Button } from 'react-native';

import Rounds from '../components/Rounds';
import Profile from '../components/Profile';
// import StartWorkoutButton from '../components/StartWorkoutButton';
import { Text, View } from '../components/Themed';
import { palette } from '../constants/Palette';
import { RootTabScreenProps } from '../types';
import { useState, useEffect, useRef } from "react"
import {firebase} from '../components/firebase';
import { useStores } from "../models"




export default function ProfileScreen({ navigation }) {

  const { roundObjStore, workoutObjStore } = useStores()
  const [screen,setScreen] = useState(0)
  // const [roundObj, setRoundObj] = useState({})
  

  return (
    <View style={styles.container}>
      {/*<Text style={styles.title}>Directions</Text>*/}
      {/*<Text style={styles.title}>Saved Workouts</Text>*/}
      <View style={{flex:1}}>
        <Profile navigation={navigation} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: palette.black
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
