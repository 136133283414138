import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { useState, useEffect, useRef } from "react"
import ScrollPicker from 'react-native-wheel-scrollview-picker';
import SegmentedControl from '@react-native-segmented-control/segmented-control';



import Colors from '../constants/Colors';
import {palette} from '../constants/Palette';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';

export default function MultiPicker({ first, firstState, second, secondState, third,thirdState, handleTouch }) {

  return (
    <View style={styles.container}>
      <View style={styles.itemContainer1}>
        {/*<View style={firstState || secondState || second == false ? {flex:1,}:{flex:1,borderRightColor: palette.lightGrey, borderRightWidth: 1,}}>*/}
        <View style={firstState || secondState || second == false ? {flex:1,}:{flex:1,}}>
          <TouchableOpacity onPress={()=>{handleTouch(first)}}>
            <View style={firstState ? {
              borderRadius: 5,
              padding: 4,
              backgroundColor: palette.lightGrey,
              fontWeight: 'bold',
              alignItems: 'center',
              justifyContent: 'center',
            }:{
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'normal',
              // borderRadius: 5,
              padding: 4,
              backgroundColor: '#28282B'
            }} >
              <Text style={firstState?{ fontWeight: 'bold',textAlign: 'center', }:{ fontWeight: 'normal',textAlign: 'center', }}> {first} </Text>
            </View>
          </TouchableOpacity>
        </View>
        {/*<View style={second != false ? thirdState || secondState || third == false ? {flex:1,}:{flex:1,borderRightColor: palette.lightGrey, borderRightWidth: 1,}:{flex:0}}>*/}
        <View style={second != false ? thirdState || secondState || third == false ? {flex:1,}:{flex:1,}:{flex:0}}>
          {second != false?
            <TouchableOpacity onPress={()=>{handleTouch(second)}}>
              <View style={secondState ? {
                
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 5,
                padding: 4,
                backgroundColor: palette.lightGrey,
                fontWeight: 'bold',
              }:{
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'normal',
                // borderRadius: 5,
                padding: 4,
                backgroundColor: '#28282B'
              }} >
                <Text style={secondState?{ fontWeight: 'bold',textAlign: 'center', }:{ fontWeight: 'normal',textAlign: 'center', }}> {second} </Text>
              </View>
            </TouchableOpacity>
            :
            <View></View>
          }
        </View>
        <View style={third != false?{flex:1}:{flex:0}}>
          {third != false?
              <TouchableOpacity onPress={()=>{handleTouch(third)}}>
                <View style={thirdState ? {
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 5,
                  padding: 4,
                  backgroundColor: palette.lightGrey,
                }:{
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'normal',
                  // borderRadius: 5,
                  padding: 4,
                  backgroundColor: '#28282B'
                }} >
                  <Text style={thirdState ?{ fontWeight: 'bold',textAlign: 'center', }:{ fontWeight: 'normal',textAlign: 'center', }}> {third} </Text>
                </View>
              </TouchableOpacity>
            :
            <View></View>
          }
        </View>

      </View>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf:'stretch',
    // flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
    paddingVertical: 3,
    // paddingHorizontal: 10,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  itemContainer1: {
    flexDirection:'row',
    alignSelf:'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#28282B',
    // paddingHorizontal: 4,
    backgroundColor: '#28282B',
  },
  false1: {
    borderRadius: 3,
    paddingHorizontal: 4,
    backgroundColor: palette.black
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    // flex:1,
    marginBottom: 20,
    marginHorizontal: 10,
    alignItems: 'center',

  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  numbers: {
    // flex:1,
    alignSelf:"stretch",
    // flex: 1,
    // flexDirection:'row',
    marginTop: 5,
    // borderRadius:1,
    // borderWidth:1,
    // borderColor:palette.white,
  },
});
